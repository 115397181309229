/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AudioStore, IAppState, useDispatch, useSelector } from "@bms/common";
import { ActionTypes } from "@bms/common/src/store/audio/consts";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { AudioContext } from "./AudioContext";
import { BaseAudioPlayer } from "./types";

interface IAudioProviderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
}

export const AudioProvider = ({ children }: IAudioProviderProps) => {
  const dispatch = useDispatch();
  const [player, setPlayer] = useState<BaseAudioPlayer | undefined>();
  const [playing, setPlaying] = useState(false);
  const [volume, __setVolume] = useState(100);
  const [ended, setEnded] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const media = useSelector(AudioStore.Selectors.audioMediaSelector);
  const streamType = useSelector(AudioStore.Selectors.audioStreamTypeSelector);
  const coverSrc = useMemo(() => {
    let src = "/audio-fallback.png";
    if (media?.Images) {
      src = media?.Images[0]?.Url || "/audio-fallback.png";
    }
    return src;
  }, [media]);
  const mediaPlayInfo = useSelector((state: IAppState) =>
    media ? state.media.mediaPlayInfo[media?.Id]?.Data : undefined,
  );

  const isFromAutoPlay = useSelector(AudioStore.Selectors.isAudioFromAutoPlay);
  const isLoading = useSelector(AudioStore.Selectors.isAudioLoadingSelector);

  const [playbackRate, __setPlaybackRate] = useState(
    player?.getInternalPlayer()?.playbackRate || 1,
  );

  const playAudio = useCallback(() => {
    player?.getInternalPlayer()?.play();
  }, [player]);

  const pauseAudio = useCallback(() => {
    player?.getInternalPlayer()?.pause();
  }, [player]);

  const setPlaybackRate = useCallback(
    (rate: number) => {
      if (player && player.getInternalPlayer()) {
        __setPlaybackRate(rate);
        player.getInternalPlayer().playbackRate = rate;
      }
    },
    [player],
  );

  const setVolume = useCallback(__setVolume, [__setVolume]);

  const seekTo = useCallback(
    (seconds: number) => {
      player?.seekTo(seconds, "seconds");
    },
    [player],
  );

  const onPlay = useCallback(() => {
    setPlaying(true);
  }, []);

  const onPause = useCallback(() => {
    setPlaying(false);
  }, []);

  const onEnded = () => {
    setEnded(true);
  };

  const onDuration = useCallback((dur: number) => {
    setDuration(dur);
  }, []);

  const onTimeUpdate = useCallback(
    ({
      playedSeconds,
    }: {
      played: number;
      playedSeconds: number;
      loaded: number;
      loadedSeconds: number;
    }) => {
      if (isFromAutoPlay && playing) {
        dispatch({
          type: ActionTypes.SET_IS_FROM_AUTOPLAY,
          payload: false,
        });
      }
      setCurrentTime(playedSeconds);
    },
    [media, isFromAutoPlay, playing],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const eventCallbacksMap: { [key: string]: (e?: any) => void } = useMemo(
    () => ({
      onPlay,
      onPause,
      onEnded,
      onDuration,
      onProgress: onTimeUpdate,
    }),
    [onDuration, onPause, onPlay, onTimeUpdate],
  );

  useEffect(() => {
    if (player && player.getInternalPlayer()) {
      player.getInternalPlayer().volume = volume > 1 ? volume / 100 : volume;
    }
  }, [volume, player]);

  return (
    <AudioContext.Provider
      value={{
        player,
        playing,
        ended,
        currentTime,
        duration,
        playbackRate,
        volume,
        media,
        mediaPlayInfo,
        streamType,
        setPlaybackRate,
        playAudio,
        pauseAudio,
        seekTo,
        setVolume,
        setPlayer,
        coverSrc,
        handlers: eventCallbacksMap,
        isLoading,
        isFromAutoPlay,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};

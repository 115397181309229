/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum AssetContentType {
  MP4 = "video/mp4",
  HLS = "application/x-mpegURL",
  MPD = "application/dash+xml",
  HSS = "application/vnd.ms-sstr+xml",
  PDF = "application/pdf",
}

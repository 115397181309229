/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";

import { HTTP_METHOD } from "../../../../constants";
import { Identifier } from "../../../../models";
import {
  IJwpEpisodeListModel,
  IJwpEpisodeSeriesListModel,
  IJwpMediaModel,
  IJwpPlaylistModel,
  IJwpSeriesModel,
} from "../models";

import { AxiosSubscriber } from "./AxiosSubscriber";

export class MediaService {
  get url(): string {
    return "/v2";
  }

  public getPlaylist = (
    playlistId: Identifier,
  ): Observable<IJwpPlaylistModel> => {
    return new Observable(
      (observer: Observer<IJwpPlaylistModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/playlists/${playlistId}`,
        }),
    );
  };

  public getWatchlist = (
    playlistId: Identifier,
    mediaIds: string[],
  ): Observable<IJwpPlaylistModel> => {
    const params = {
      media_ids: mediaIds.join(),
    };

    return new Observable(
      (observer: Observer<IJwpPlaylistModel>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params,
          },
          method: HTTP_METHOD.GET,
          url: `apps/watchlists/${playlistId}`,
        }),
    );
  };

  public searchMedia = (
    playlistId: Identifier,
    fullTextSearch = "",
  ): Observable<IJwpPlaylistModel> => {
    return new Observable(
      (observer: Observer<IJwpPlaylistModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/playlists/${playlistId}?search=${encodeURI(
            fullTextSearch,
          )}`,
        }),
    );
  };

  public getMedia = (mediaId: Identifier): Observable<IJwpMediaModel> => {
    return new Observable(
      (observer: Observer<IJwpMediaModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/media/${mediaId}`,
        }),
    );
  };

  public getSimilarMedia = (
    recommendationsPlaylist: string,
    mediaId: Identifier,
  ): Observable<IJwpPlaylistModel> => {
    return new Observable(
      (observer: Observer<IJwpPlaylistModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/playlists/${recommendationsPlaylist}?related_media_id=${mediaId}`,
        }),
    );
  };

  public getSeries = (mediaId: Identifier): Observable<IJwpSeriesModel> => {
    return new Observable(
      (observer: Observer<IJwpSeriesModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `apps/series/${mediaId}`,
        }),
    );
  };

  public getEpisodes = (
    mediaId: Identifier,
  ): Observable<IJwpEpisodeListModel> => {
    return new Observable(
      (observer: Observer<IJwpEpisodeListModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `apps/series/${mediaId}/episodes`,
        }),
    );
  };

  public getEpisodeSeries = (
    mediaId: Identifier,
  ): Observable<IJwpEpisodeSeriesListModel> => {
    return new Observable(
      (observer: Observer<IJwpEpisodeSeriesListModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `apps/series?media_ids=${mediaId}`,
        }),
    );
  };
}

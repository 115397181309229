/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import {
  ApolloClient,
  ApolloQueryResult,
  DocumentNode,
  InMemoryCache,
  OperationVariables,
} from "@apollo/client";
import { Observable, Observer } from "rxjs";

import { HTTP_METHOD } from "../../../../constants";
import { AxiosSubscriber } from "../../../../services/Common";
import {
  IIdpConfigurationResponseModel,
  ISignInWithCredentialsModel,
  ISignInWithCredentialsResponseModel,
  ITokenResponseModel,
} from "../models";

const catalogService =
  process.env.REACT_APP_AXINOM_CATALOG_SERVICE_API_URL || "";
const userAuthService =
  process.env.REACT_APP_AXINOM_PROXY_USER_AUTH_API_URL || "";

const axinomApplicationId = process.env.REACT_APP_AXINOM_APPLICATION_ID || "";
const axinomEnvironmentId = process.env.REACT_APP_AXINOM_ENVIRONMENT_ID || "";
const axinomTenantId = process.env.REACT_APP_AXINOM_TENANT_ID || "";

export class AxinomService {
  private _client: any;
  private _apiUrl = catalogService;

  private initialize = async (): Promise<void> => {
    if (this._client) {
      return;
    }

    this._client = new ApolloClient({
      uri: this._apiUrl,
      cache: new InMemoryCache(),
    });
  };

  public search = async <T>(
    query: DocumentNode,
    variables?: OperationVariables,
  ): Promise<ApolloQueryResult<T>> => {
    await this.initialize();

    return await this._client
      .query({
        query: query,
        variables: variables || {},
      })
      .then((result: ApolloQueryResult<T>) => {
        return result;
      })
      .catch((error: any) => {
        throw { ...error, Message: error.message };
      });
  };

  public getAxinomIdpConfig = (): Observable<IIdpConfigurationResponseModel> =>
    new Observable((observer: Observer<IIdpConfigurationResponseModel>) => {
      return new AxiosSubscriber(observer, {
        method: HTTP_METHOD.GET,
        url: `${userAuthService}/${axinomTenantId}/${axinomEnvironmentId}/${axinomApplicationId}/get-user-auth-idp-config`,
      });
    });

  public axinomSignIn = (
    data: ISignInWithCredentialsModel,
  ): Observable<ISignInWithCredentialsResponseModel> =>
    new Observable(
      (observer: Observer<ISignInWithCredentialsResponseModel>) => {
        return new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.POST,
          url: `${userAuthService}/${axinomTenantId}/${axinomEnvironmentId}/${axinomApplicationId}/sign-in-with-credentials`,
        });
      },
    );

  public axinomToken = (): Observable<ITokenResponseModel> =>
    new Observable((observer: Observer<ITokenResponseModel>) => {
      return new AxiosSubscriber(observer, {
        method: HTTP_METHOD.GET,
        url: `${userAuthService}/${axinomTenantId}/${axinomEnvironmentId}/${axinomApplicationId}/token`,
      });
    });

  public axinomSignOut = (): Observable<void> =>
    new Observable((observer: Observer<void>) => {
      return new AxiosSubscriber(observer, {
        method: HTTP_METHOD.GET,
        url: `${userAuthService}/${axinomTenantId}/${axinomEnvironmentId}/${axinomApplicationId}/sign-out`,
      });
    });
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const GET_MEDIA_LIST = "GET_MEDIA_LIST";
export const GET_MEDIA_LIST_SUCCESS = "GET_MEDIA_LIST_SUCCESS";
export const GET_MEDIA_LIST_FAILURE = "GET_MEDIA_LIST_FAILURE";
export const GET_MEDIA_LIST_FROM_CACHE = "GET_MEDIA_LIST_FROM_CACHE";
export const GET_MEDIA_LIST_FROM_CACHE_SUCCESS =
  "GET_MEDIA_LIST_FROM_CACHE_SUCCESS";
export const GET_MEDIA_LIST_FROM_CACHE_FAILURE =
  "GET_MEDIA_LIST_FROM_CACHE_FAILURE";
export const GET_MEDIA_LIST_FOR_EPG = "GET_MEDIA_LIST_FOR_EPG";
export const GET_MEDIA_LIST_FOR_EPG_SUCCESS = "GET_MEDIA_LIST_FOR_EPG_SUCCESS";
export const GET_MEDIA_LIST_FOR_EPG_FAILURE = "GET_MEDIA_LIST_FOR_EPG_FAILURE";
export const GET_MEDIA = "GET_MEDIA";
export const GET_MEDIA_SUCCESS = "GET_MEDIA_SUCCESS";
export const GET_MEDIA_FAILURE = "GET_MEDIA_FAILURE";
export const GET_MEDIA_PLAY_INFO = "GET_MEDIA_PLAY_INFO";
export const GET_MEDIA_PLAY_INFO_SUCCESS = "GET_MEDIA_PLAY_INFO_SUCCESS";
export const GET_MEDIA_PLAY_INFO_FAILURE = "GET_MEDIA_PLAY_INFO_FAILURE";
export const SEARCH_MEDIA = "SEARCH_MEDIA";
export const SEARCH_MEDIA_SUCCESS = "SEARCH_MEDIA_SUCCESS";
export const SEARCH_MEDIA_FAILURE = "SEARCH_MEDIA_FAILURE";
export const SEARCH_MEDIA_IN_MEDIA = "SEARCH_MEDIA_IN_MEDIA";
export const SEARCH_MEDIA_IN_MEDIA_SUCCESS = "SEARCH_MEDIA_IN_MEDIA_SUCCESS";
export const SEARCH_MEDIA_IN_MEDIA_FAILURE = "SEARCH_MEDIA_IN_MEDIA_FAILURE";
export const GET_MEDIA_CATEGORIES = "GET_MEDIA_CATEGORIES";
export const GET_MEDIA_CATEGORIES_SUCCESS = "GET_MEDIA_CATEGORIES_SUCCESS";
export const GET_MEDIA_CATEGORIES_FAILURE = "GET_MEDIA_CATEGORIES_FAILURE";
export const SELECT_MEDIA_PURCHASE_OFFERS = "SELECT_MEDIA_PURCHASE_OFFERS";
export const SELECT_MEDIA_PURCHASE_OFFERS_SUCCESS =
  "SELECT_MEDIA_PURCHASE_OFFERS_SUCCESS";
export const SELECT_MEDIA_PURCHASE_OFFERS_FAILURE =
  "SELECT_MEDIA_PURCHASE_OFFERS_FAILURE";
export const CLEAR_MEDIA = "CLEAR_MEDIA";
export const MEDIA_LIST_SEARCH_KEY = "MEDIA_LIST_SEARCH_KEY";
export const SET_CDM = "SET_CDM";

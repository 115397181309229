/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { RefObject, useEffect } from "react";

export const useOutsideClick = (
  ref: RefObject<Node | undefined>,
  callback: () => void,
) => {
  useEffect(() => {
    const refEl = ref?.current;
    const handleClick = (e: Event) => {
      if (refEl && !refEl.contains(e.target as Node)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [callback, ref]);
};

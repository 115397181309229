/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useCallback, useEffect, useRef, useState } from "react";

import { StorageHelper } from "../../helpers/storageHelper";
import { IMediaModel, IUserAssetPropertiesModel } from "../../models";

interface IProgresses {
  progress: number;
  progressInSeconds: number;
}

export const useMediaProgress = (media?: IMediaModel) => {
  const isMounted = useRef(false);
  const [progresses, setProgresses] = useState({
    progress: -1,
    progressInSeconds: -1,
  });

  const getProgress = useCallback(
    (userAssetProperties: IUserAssetPropertiesModel[]) => {
      if (media?.Id && media?.Duration && userAssetProperties) {
        const mediaProperties = userAssetProperties.find(
          (row) => row.AssetId === media?.Id,
        );

        if (mediaProperties?.Timestamp) {
          const { Hour, Minute, Second } = mediaProperties.Timestamp;
          const progressInMiliseconds =
            ((Hour ?? 0) * 3600 + (Minute ?? 0) * 60 + (Second ?? 0)) * 1000;
          const progressInSeconds =
            (Hour ?? 0) * 3600 + (Minute ?? 0) * 60 + (Second ?? 0);

          const progress =
            (progressInMiliseconds /
              (Math.floor(media.Duration / 1000) * 1000)) *
            100;
          return {
            progress,
            progressInSeconds,
          };
        } else if (mediaProperties?.ProgressPercent) {
          const progressInSeconds =
            (media.Duration * mediaProperties?.ProgressPercent) / 100;
          return {
            progress: mediaProperties?.ProgressPercent,
            progressInSeconds,
          };
        }
      }

      return {
        progress: -1,
        progressInSeconds: -1,
      };
    },
    [media],
  );

  const calcProgress = useCallback(async (): Promise<IProgresses> => {
    if (media?.Id && media?.Duration) {
      const userAssetProperties = await StorageHelper.getUserAssetsProperties();
      const progress = getProgress(userAssetProperties);

      return Promise.resolve(progress);
    }

    return Promise.resolve({
      progress: -1,
      progressInSeconds: -1,
    });
  }, [media]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const subscription = StorageHelper.userAssetProperties$.subscribe(
      (value: IUserAssetPropertiesModel[]) => {
        if (isMounted.current) {
          const progresses = getProgress(value);
          setProgresses(progresses);
        }
      },
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [media]);

  useEffect(() => {
    calcProgress()
      .then((progresses) => {
        if (isMounted.current) {
          setProgresses(progresses);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [calcProgress, media]);

  return progresses;
};

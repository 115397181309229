/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum RecordStatus {
  NoChange = "NO_CHANGE",
  Inserted = "INSERTED",
  Updated = "UPDATED",
  Deleted = "DELETED",
}

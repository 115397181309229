/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export class StringHelper {
  /*
   * Function used to generate an uniq random string
   */
  static generateRandomString = () =>
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);

  static toString(value: any) {
    if (value === null || value === undefined) {
      return "";
    }

    return `${value}`;
  }

  static truncate(text: string | undefined, length: number) {
    if (!text) {
      return text;
    }

    return text.length > length ? text.substring(0, length) + "..." : text;
  }

  static compare(value1: any, value2: any): boolean {
    const string1 = String(value1);
    const string2 = String(value2);

    return string1 === string2;
  }
}

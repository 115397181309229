/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { BrowserTracing } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import React from "react";

const isSentryAvailable = !!process.env.REACT_APP_SENTRY_DSN;

if (isSentryAvailable) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    release: "bms/web@" + process.env.REACT_APP_VERSION,
    debug: false,
  });
}

interface ISentryWrapperProps {
  Root: React.FunctionComponent<any>;
  App: React.FunctionComponent<any>;
}

export const withSentry = ({ Root, App }: ISentryWrapperProps) => {
  if (!isSentryAvailable) {
    return () => (
      <App>
        <Root />
      </App>
    );
  }

  const AppSentryWrapper = Sentry.withProfiler(App);

  return () => (
    <AppSentryWrapper>
      <Sentry.ErrorBoundary
        fallback={() => <div>An error has occurred</div>}
        showDialog
      >
        <Root />
      </Sentry.ErrorBoundary>
    </AppSentryWrapper>
  );
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { HTTP_RESPONSE_TYPE } from "../../../../constants";
import { MediaImageType, MediaStreamType, SourceType } from "../../../../enums";
import { TimeHelper } from "../../../../helpers";
import {
  IAuthResponseModel,
  IErrorModel as ICommonErrorModel,
  IMediaListModel,
  IMediaModel,
  IMediaPlayInfoModel,
} from "../../../../models";
import { VideoType } from "../enums";
import {
  IAxinomAssetModel,
  ICollectionItemsListModel,
  IMovieInfoModel,
  ITokenResponseModel,
} from "../models";

const imageService = process.env.REACT_APP_AXINOM_IMAGE_SERVICE_API_URL || "";

export class ModelsMapperHelper {
  static toSignInModel(entity: ITokenResponseModel): IAuthResponseModel {
    const result: IAuthResponseModel = {
      AuthorizationToken: {
        Token: entity.user?.token.accessToken,
        TokenExpires: entity.user?.token.expiresAt?.toString(),
      },
      User: {
        Email: entity.user?.email,
        FullName: entity.user?.name,
        Id: entity.user.profileId,
      },
    };

    return result;
  }

  static toMediaListModel(entity: ICollectionItemsListModel): IMediaListModel {
    const result: IMediaListModel = {
      SourceType: SourceType.Standard,
      Entities:
        entity?.nodes?.map((el) => {
          const entityType = el.movie || el.episode || el.season || el.tvshow;

          const isMainVideoPlayable = entityType?.videos?.edges?.find(
            (el) => el.node?.type === VideoType.Main,
          );

          const coverImage = entityType?.images?.nodes?.find(
            (el) =>
              (el.width && el.height && el.width < el.height) ||
              el.type === "COVER",
          );

          const frameImage = entityType?.images?.nodes?.find(
            (el) =>
              (el.width && el.height && el.width > el.height) ||
              el.type === "COVER",
          );

          const startDate = TimeHelper.getDate(
            entityType?.licenses?.edges?.[0].node?.startTime || "",
          );
          const endDate = TimeHelper.getDate(
            entityType?.licenses?.edges?.[0].node?.endTime || "",
          );

          return {
            AvailableFrom: startDate,
            AvailableTo: endDate,
            StartDateTime: startDate,
            EndDateTime: endDate,
            IsPlayable: !!isMainVideoPlayable,
            IsFree: true,
            Id: entityType?.id || "",
            Title: entityType?.title,
            ShortDescription: entityType?.synopsis,
            Images: [
              {
                Url: `${imageService}${frameImage?.path}`,
                ImageTypeCode: MediaImageType.Frame,
              },
              {
                Url: `${imageService}${coverImage?.path}`,
                ImageTypeCode: MediaImageType.Cover,
              },
            ],
          };
        }) || [],
      TotalCount: entity.totalCount || 0,
      CacheDataValidTo: TimeHelper.getCurrentDateTime().toString(),
    };

    return result;
  }

  static toMediaModel(entity: IAxinomAssetModel): IMediaModel {
    const entityType =
      entity.movie || entity.episode || entity.season || entity.tvshow;

    const isTrialPlayable = entityType?.videos?.edges?.find(
      (el) => el.node?.type === VideoType.Trailer,
    );

    const isMainVideoPlayable = entityType?.videos?.edges?.find(
      (el) => el.node?.type === VideoType.Main,
    );

    const highlightsImage = entityType?.images?.nodes?.find(
      (el) =>
        (el.width && el.height && el.width > el.height) || el.type === "COVER",
    );

    const startDate = TimeHelper.getDate(
      entityType?.licenses?.edges?.[0].node?.startTime || "",
    );
    const endDate = TimeHelper.getDate(
      entityType?.licenses?.edges?.[0].node?.endTime || "",
    );

    const result: IMediaModel = {
      AvailableFrom: startDate,
      AvailableTo: endDate,
      StartDateTime: startDate,
      EndDateTime: endDate,
      IsFree: true,
      IsTrialPlayable: !!isTrialPlayable,
      IsPlayable: !!isMainVideoPlayable,
      Id: entityType?.id || "",
      Title: entityType?.title,
      Description: entityType?.description,
      LongDescription: entityType?.description,
      ShortDescription: entityType?.synopsis,
      Images: [
        {
          Url: `${imageService}${highlightsImage?.path}`,
          ImageTypeCode: MediaImageType.Highlights,
        },
      ],
    };

    return result;
  }

  static toMediaPlayInfoModel(
    entity: IMovieInfoModel,
    streamType: MediaStreamType | undefined,
  ): IMediaPlayInfoModel {
    const mainVideo = entity.movie?.videos?.edges?.find(
      (el) => el.node?.type === VideoType.Main,
    );
    const trialVideo = entity.movie?.videos?.edges?.find(
      (el) => el.node?.type === VideoType.Trailer,
    );

    let result: IMediaPlayInfoModel;

    if (streamType === MediaStreamType.Trial) {
      result = {
        ContentUrl: trialVideo?.node?.dashManifest,
        MediaId: entity.movie.id,
        StreamId: trialVideo?.node?.id,
        StreamType: MediaStreamType.Trial,
      };
    } else {
      result = {
        ContentUrl: mainVideo?.node?.dashManifest,
        MediaId: entity.movie.id,
        StreamId: mainVideo?.node?.id,
        StreamType: MediaStreamType.Main,
      };
    }

    return result;
  }

  static toErrorModel(error: any): ICommonErrorModel {
    return {
      Message: error?.message,
      ResultType: HTTP_RESPONSE_TYPE.ERROR,
    };
  }
}

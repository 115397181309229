/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IMediaModel, TimeHelper, useRTL } from "@bms/common";
import cx from "classnames";
import React from "react";

import "./MediaAdditionalInfo.scss";

interface IMediaAdditionalInfoProps {
  media: IMediaModel;
  className?: string;
}

export const MediaAdditionalInfo = ({
  media,
  className,
}: IMediaAdditionalInfoProps) => {
  const isRTL = useRTL();

  const mediaAdditionalInformations: string[] = [];

  if (media.Year) {
    mediaAdditionalInformations.push(`${media.Year}`);
  }

  if (media.MediaAgeRestrictionValueMin) {
    mediaAdditionalInformations.push(`${media.MediaAgeRestrictionValueMin}+`);
  }

  if (media.Duration) {
    mediaAdditionalInformations.push(
      `${TimeHelper.formatDuration(media.Duration)}`,
    );
  }

  if (media.Categories && media.Categories.length > 0) {
    mediaAdditionalInformations.push(
      `${media.Categories.map((category) => category.CategoryName).join(", ")}`,
    );
  }

  const mediaAdditionalInfo = (
    isRTL ? mediaAdditionalInformations.reverse() : mediaAdditionalInformations
  ).join(" | ");

  if (!mediaAdditionalInfo) {
    return null;
  }

  return (
    <div className={cx("MediaAdditionalInfo", className)}>
      {mediaAdditionalInfo}
    </div>
  );
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

export const initAutoReconnectTimeouts = [
  1000, 5000, 15000, 15000, 15000, 15000, 15000, 15000, 30000, 30000,
];

export const initConnectionReconnectTimeout = 15000;

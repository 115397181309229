/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { gql } from "@apollo/client";

const licensesQueury = `licenses {
  edges {
    node {
      startTime
      endTime
    }
  }
}`;
const videoShortQuery = `videos {
  edges {
    node {
      id
      type
    }
  }
}`;
const imagesQuery = `images {
  nodes {
    path
    height
    type
    width
  }
}`;
const videoPlayInfoQuery = `
id
audioLanguages
captionLanguages
dashManifest
hlsManifest
isProtected
lengthInSeconds
outputFormat
subtitleLanguages
title
type`;
const assetDefaultQuery = `
id
description
title
synopsis`;

export const SEARCH_COLLECTION_QUERY = gql`
  query Collection($id: String!) {
    collection(id: $id) {
      id
      title
      items(first: 12) {
        nodes {
          id
          movie {
            id
            title
            synopsis
            ${imagesQuery}
            ${videoShortQuery}
            ${licensesQueury}
          }
          tvshow {
            id
            title
            synopsis
            ${imagesQuery}
            ${videoShortQuery}
            ${licensesQueury}
          }
          season {
            id
            synopsis
            ${imagesQuery}
            ${videoShortQuery}
            ${licensesQueury}
          }
          episode {
            id
            title
            synopsis
            ${imagesQuery}
            ${videoShortQuery}
            ${licensesQueury}
          }
        }
        totalCount
      }
    }
  }
`;

export const SEARCH_MEDIA_QUERY = gql`
  query GetMedia(
    $movieId: String!
    $episodeId: String!
    $seasonId: String!
    $tvshowId: String!
  ) {
    movie(id: $movieId) {
      ${assetDefaultQuery}
      ${imagesQuery}
      ${videoShortQuery}
      ${licensesQueury}
    }
    episode(id: $episodeId) {
      ${assetDefaultQuery}
      ${imagesQuery}
      ${videoShortQuery}
      ${licensesQueury}
    }
    season(id: $seasonId) {
      id
      description
      synopsis
      ${imagesQuery}
      ${videoShortQuery}
      ${licensesQueury}
    }
    tvshow(id: $tvshowId) {
      ${assetDefaultQuery}
      ${imagesQuery}
      ${videoShortQuery}
      ${licensesQueury}
    }
  }
`;

export const GET_MEDIA_PLAY_INFO_QUERY = gql`
  query GetMediaPlayInfo(
    $movieId: String!
    $episodeId: String!
    $seasonId: String!
    $tvshowId: String!
  ) {
    movie(id: $movieId) {
      id
      videos {
        edges {
          node {
            ${videoPlayInfoQuery}
            movieId
          }
        }
      }
    }
    episode(id: $episodeId) {
      id
      videos {
        edges {
          node {
            ${videoPlayInfoQuery}
            episodeId
          }
        }
      }
    }
    season(id: $seasonId) {
      id
      videos {
        edges {
          node {
            ${videoPlayInfoQuery}
            seasonId
          }
        }
      }
    }
    tvshow(id: $tvshowId) {
      id
      videos {
        edges {
          node {
            ${videoPlayInfoQuery}
            tvshowId
          }
        }
      }
    }
  }
`;

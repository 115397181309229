/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import ChevronDown from "../../resources/icons/chevron-down.svg";

import "./PageHeader.scss";

export interface IPageHeaderProps {
  title?: string;
}

export const PageHeader: React.FC<IPageHeaderProps> = (props) => {
  const { title } = props;
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="PageHeader">
      <button
        className="PageHeader__backIcon"
        onClick={() => history.goBack()}
        aria-label={t("COMMON__BUTTON_GO_BACK")}
        title={t("COMMON__BUTTON_GO_BACK")}
      >
        <ChevronDown />
      </button>
      <span className="PageHeader__title">{title}</span>
    </div>
  );
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum StorageProvider {
  Internal = 0,
  AWS = 1,
  Azure = 2,
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum Orientation {
  Default = "DEFAULT",
  Vertical = "VERTICAL",
  Horizontal = "HORIZONTAL",
  Grid = "GRID",
}

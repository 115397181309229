/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AssetType, IMediaModel, RouteHelper, ThemeContext } from "@bms/common";
import Color from "color";
import dayjs from "dayjs";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { MDUp, MediaAdditionalInfo } from "components";
import { PlayInlineButton } from "components/PlayInlineButton";

import "./MediaInfo.scss";

export interface MediaInfoProps {
  media: IMediaModel;
  hideDescription?: boolean;
}

export const MediaInfo = (props: MediaInfoProps) => {
  const { media, hideDescription = false } = props;

  const isPackage = media.MediaTypeCode === AssetType.Package;
  const title = media.Title;
  let description = "";
  const { t } = useTranslation();
  const { themeProvider } = useContext(ThemeContext);

  if (media) {
    switch (media.MediaTypeCode) {
      case AssetType.Video:
      case AssetType.Series:
      case AssetType.Season:
      case AssetType.Episode:
        description = media.ShortDescription || "";
        break;
      case AssetType.Live:
      case AssetType.Channel:
      case AssetType.Program:
        if (media.AvailableFrom) {
          const availableFrom = dayjs(media.StartDateTime);
          description = `${availableFrom.format(
            "dddd",
          )} | ${availableFrom.format("D MMMM")} | ${availableFrom.format(
            "HH:mm",
          )}`;
          if (media.Duration) {
            description += ` - ${availableFrom
              .add(media.Duration, "second")
              .format("HH:mm")}`;
          }
        } else {
          description = media.ShortDescription || "";
        }
        break;
      default:
        description = media.ShortDescription || "";
    }
  }

  return (
    <div
      className="MediaInfo"
      style={{
        background: `${Color(
          themeProvider.getColor("AppDetailsBackgroundColor"),
        )
          .alpha(0.8)
          .string()}`,
      }}
    >
      <div className="MediaInfo__content">
        <div className="MediaInfo__title-container">
          <div
            className="MediaInfo__title"
            onClick={() => RouteHelper.goToDetails(media)}
          >
            {title}
          </div>
        </div>

        <div className="MediaInfo__data">
          {!isPackage && <MediaAdditionalInfo media={media} />}

          {!hideDescription && description && (
            <MDUp>
              <div
                className="MediaInfo__description"
                onClick={() => RouteHelper.goToDetails(media)}
                dangerouslySetInnerHTML={{ __html: description || "" }}
              ></div>
            </MDUp>
          )}

          <div
            className="MediaInfo__details"
            onClick={() => RouteHelper.goToDetails(media)}
          >
            {t("COMMON__SEE_DETAILS", "See details")}
          </div>
        </div>
      </div>

      {!isPackage && (
        <div className="MediaInfo__actions">
          <PlayInlineButton media={media} />
        </div>
      )}
    </div>
  );
};

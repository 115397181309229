/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Identifier, IMediaOptionsModel } from "../../models";
import { useMediaByIdSelector } from "../../selectors";
import { MediaStore } from "../../store";

interface useMediaParams {
  mediaId: Identifier | undefined;
  disableAutoFetch?: boolean;
  options?: Omit<IMediaOptionsModel, "MediaId">;
}

export const useMedia = (params: useMediaParams) => {
  const { mediaId = -1, options, disableAutoFetch = false } = params;

  const { IsLoading, Error, Media } = useMediaByIdSelector(mediaId);

  const dispatch = useDispatch();

  const mediaObjectExist = Media && Object.keys(Media).length > 1;

  const fetch = useCallback(
    () =>
      dispatch(
        MediaStore.Actions.getMedia({
          IncludeCategories: true,
          IncludeImages: true,
          IncludePeople: true,
          IncludeSimilarMedia: false,
          MediaId: mediaId,
          ...options,
        }),
      ),
    [mediaId],
  );

  useEffect(() => {
    !mediaObjectExist && !disableAutoFetch && fetch();
  }, [disableAutoFetch, fetch]);

  return {
    media: mediaObjectExist ? Media : undefined,
    error: Error,
    isLoading: IsLoading,
    isError: Boolean(Error),
    fetch,
  };
};

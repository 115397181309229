/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { IConfigurationModel } from "models";

export class DownloadAssetsService {
  // eslint-disable-next-line unused-imports/no-unused-vars
  static downloadConfigAssets = (configuration: IConfigurationModel) => {};
}

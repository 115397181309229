/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum AssetPlaybackStatus {
  Starting = "STARTING",
  Streaming = "STREAMING",
  Paused = "PAUSED",
  Stopped = "STOPPED",
  Ended = "ENDED",
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum InfoDetailsScreenCode {
  PRIVACYPOLICY = "PRIVACY_POLICY",
  MYCONSENTS = "TERMS_OF_USE",
  COOKIES = "COOKIE_CONSENT",
  READMORE = "READ_MORE",
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useDispatch } from "react-redux";

import { MediaType } from "../../enums";
import { IMediaOptionsModel } from "../../models";
import { MediaStore } from "../../store";

export const useGetMedia = () => {
  const dispatch = useDispatch();

  const getMedia = (options: IMediaOptionsModel) =>
    dispatch(
      MediaStore.Actions.getMedia({
        IncludeCategories: true,
        IncludeImages: true,
        IncludePeople: true,
        IncludeSimilarMedia: true,
        AssetType: MediaType.Series,
        ...options,
      }),
    );

  return getMedia;
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum CellType {
  Default = "DEFAULT",
  Highlights = "HIGHLIGHTS",
  Cover = "COVER",
  Frame = "FRAME",
  Promo = "PROMO",
  Button = "BUTTON",
  Square = "SQUARE",
  SquareWithText = "SQUARE_WITH_TEXT",
  Event = "EVENT",
  Round = "ROUND",
  HighlightsWidescreen = "HIGHLIGHTS_WIDESCREEN",
  HeroLandscape = "HERO_LANDSCAPE",
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { push } from "connected-react-router";

import { AppConfig } from "../../../../app";
import { HTTP_ERROR, ROUTES } from "../../../../constants";
import {
  AuthorizationHelper,
  DeviceHelper,
  StorageHelper,
} from "../../../../helpers";
import {
  IErrorModel,
  ITokenModel,
  IUserInfoModel,
  toErrorModel,
} from "../../../../models";
import { AuthService, StorageKey, StorageManager } from "../../../../services";
import { AuthStore, dispatch } from "../../../../store";

let isRefreshing = false;
let failedQueue: {
  resolve: (value?: unknown) => void;
  reject: (reason?: any) => void;
}[] = [];

const authService: AuthService = new AuthService();

const processQueue = (error?: IErrorModel, token?: string) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });

  failedQueue = [];
};

export const HttpRequestFulfilledInterceptor = (
  config: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
  return StorageManager.getValue(StorageKey.session).then((session) => {
    if (
      session &&
      session.Token &&
      !config.headers.hasOwnProperty("Authorization")
    ) {
      config.headers["Authorization"] = `Bearer ${session.Token}`;
    }

    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json; charset=UTF-8";
    }

    if (AppConfig.TenantOrigin) {
      config.headers["X-TenantOrigin"] = AppConfig.TenantOrigin;
    }

    return config;
  });
};

export const HttpRequestRejectedInterceptor = (error: IErrorModel): any => {
  return Promise.reject(error);
};

export const HttpResponseFulfilledInterceptor = (
  response: AxiosResponse,
): AxiosResponse => response;

export const HttpResponseRejectedInterceptor = async (
  errorResponse: any,
): Promise<unknown> => {
  const { config, response } = errorResponse;
  const originalRequest = config;
  const deviceInfo = await DeviceHelper.getDeviceInfo();

  if (
    response?.status === HTTP_ERROR.AUTHENTICATION_FAILED &&
    response?.config?.url === "/Authorization/SignIn"
  ) {
    return Promise.reject(errorResponse);
  }

  if (
    (!response || response?.status === HTTP_ERROR.AUTHENTICATION_FAILED) &&
    !originalRequest._retry
  ) {
    // Check if refresh token request failed
    if (response?.config?.url === "/Authorization/RefreshToken") {
      isRefreshing = false;
      // Clear session data as it is invalid
      await StorageManager.deleteValue(StorageKey.session);
      // Remove user data from store
      dispatch(AuthStore.Actions.refreshTokenFailure());
      // Try to login as Anonymous
      const loginResponse = await authService
        .signIn({ Device: deviceInfo })
        .toPromise();

      if (loginResponse.AuthorizationToken) {
        await StorageHelper.setUser(loginResponse.User);
        await StorageManager.setValue(
          StorageKey.session,
          loginResponse.AuthorizationToken,
        );
        if (loginResponse.Version) {
          await StorageManager.setValue(
            StorageKey.backendVersion,
            loginResponse.Version,
          );
        }

        dispatch(push(ROUTES.LOGIN));

        processQueue(undefined, loginResponse.AuthorizationToken.Token);

        return Promise.reject(new Error("Unable to refresh token"));
      }

      dispatch(push(ROUTES.BASE));

      return Promise.reject(new Error("Unable to refresh token"));
    }

    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers["Authorization"] = "Bearer " + token;

          return axios(originalRequest);
        })
        .catch((err: unknown) => {
          return Promise.reject(toErrorModel(err));
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;

    const session = await StorageManager.getValue(StorageKey.session);

    try {
      const isAnonymous = await AuthorizationHelper.isAnonymous();
      let newSession: ITokenModel | undefined = undefined;
      const refreshToken = session?.RefreshToken;

      if (!refreshToken || isAnonymous) {
        // Try to login as Anonymous
        const loginResponse = await authService
          .signIn({ Device: deviceInfo })
          .toPromise();

        if (loginResponse.AuthorizationToken) {
          await StorageHelper.setUser(loginResponse.User);
          await StorageManager.setValue(
            StorageKey.session,
            loginResponse.AuthorizationToken,
          );

          if (loginResponse.Version) {
            await StorageManager.setValue(
              StorageKey.backendVersion,
              loginResponse.Version,
            );
          }

          dispatch(
            AuthStore.Actions.signInAnonymousSuccess(
              loginResponse.User,
              loginResponse.AuthorizationToken,
            ),
          );

          newSession = loginResponse.AuthorizationToken;
        }
      } else {
        // Try to refresh token
        const refreshTokenResponse = await authService
          .refreshToken(refreshToken, deviceInfo)
          .toPromise();

        if (refreshTokenResponse.AuthorizationToken) {
          await StorageManager.setValue(
            StorageKey.session,
            refreshTokenResponse.AuthorizationToken,
          );
          newSession = refreshTokenResponse.AuthorizationToken;

          const newUser = refreshTokenResponse.User as IUserInfoModel;

          // refresh user products if have changed since the last update
          if (newUser) {
            await StorageHelper.setUser(newUser);
            dispatch(
              AuthStore.Actions.refreshTokenSuccess(newSession, newUser),
            );
          }
        } else {
          // Try to login as Anonymous
          const loginResponse = await authService
            .signIn({ Device: deviceInfo })
            .toPromise();

          if (loginResponse.AuthorizationToken) {
            await StorageHelper.setUser(loginResponse.User);
            await StorageManager.setValue(
              StorageKey.session,
              loginResponse.AuthorizationToken,
            );

            newSession = loginResponse.AuthorizationToken;
          }
        }
      }

      if (!newSession) {
        dispatch(push(ROUTES.LOGIN));

        return Promise.reject(new Error("Unable to refresh token"));
      }

      originalRequest.headers["Authorization"] = "Bearer " + newSession.Token;
      processQueue(undefined, newSession.Token);

      return Promise.resolve(axios(originalRequest));
    } catch (err: unknown) {
      const error = toErrorModel(err);
      processQueue(error, undefined);
      return Promise.reject(error);
    } finally {
      isRefreshing = false;
    }
  }

  if (!response || response?.status === HTTP_ERROR.SERVICE_UNAVAIABLE) {
    dispatch(push(ROUTES.MAINTENANCE_SCREEN));
    return Promise.reject(errorResponse);
  }

  return Promise.reject(errorResponse);
};

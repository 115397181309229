/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ThemeContext } from "@bms/common";
import cx from "classnames";
import React, { Suspense, useContext, useMemo } from "react";

import { AudioContext } from "context";

import { LoaderSpinner } from "../LoaderSpinner";
import { useIsMobile } from "../Responsive";

import "./AudioPlayerController.scss";

const AudioInfo = React.lazy(() =>
  import(/* webpackChunkName: "audio-info" */ "./AudioInfo").then((module) => ({
    default: module.AudioInfo,
  })),
);

const AudioPlayerProgressBar = React.lazy(() =>
  import(
    /* webpackChunkName: "audio-player-progress-bar" */ "./AudioPlayerProgressBar"
  ).then((module) => ({
    default: module.AudioPlayerProgressBar,
  })),
);

const AudioPlayerPlaybackControls = React.lazy(() =>
  import(
    /* webpackChunkName: "audio-player-playback-controls" */ "./AudioPlayerPlaybackControls"
  ).then((module) => ({
    default: module.AudioPlayerPlaybackControls,
  })),
);

const AudioPlayerAdditionalControls = React.lazy(() =>
  import(
    /* webpackChunkName: "audio-player-additional-controls" */ "./AudioPlayerAdditionalControls"
  ).then((module) => ({
    default: module.AudioPlayerAdditionalControls,
  })),
);

interface IAudioPlayerControllerProps {
  mode?: "standalone" | "mounted";
  src?: string;
}

export const AudioPlayerController = ({
  mode = "mounted",
}: IAudioPlayerControllerProps) => {
  const { themeProvider } = useContext(ThemeContext);
  const isMobile = useIsMobile();
  const borderTopColor = useMemo(
    () => themeProvider.getColor("AppSecondaryTextColor"),
    [themeProvider],
  );

  const { media, isLoading } = useContext(AudioContext);

  if (!media) {
    return null;
  }

  if (isMobile) {
    return (
      <div
        className={cx(
          "AudioPlayerController",
          `AudioPlayerController--${mode}`,
          "AudioPlayerController--mobile",
        )}
        style={{
          borderTopColor,
          background: themeProvider.getColor("AppBackgroundColor"),
        }}
      >
        <Suspense fallback={null}>
          <AudioPlayerProgressBar />
          <div className="AudioPlayerController__controllers">
            <AudioInfo isMobile={isMobile} />
            <AudioPlayerPlaybackControls isMobile={isMobile} />
            <AudioPlayerAdditionalControls isMobile={isMobile} />
          </div>
        </Suspense>
      </div>
    );
  }

  return (
    <div
      className={cx("AudioPlayerController", `AudioPlayerController--${mode}`)}
      style={{
        borderTopColor,
        background: themeProvider.getColor("AppBackgroundColor"),
      }}
    >
      {isLoading ? (
        <div className="AudioPlayerController--loader">
          <LoaderSpinner />
        </div>
      ) : (
        <Suspense fallback={null}>
          <AudioInfo />
          <AudioPlayerPlaybackControls />
          <AudioPlayerAdditionalControls />
        </Suspense>
      )}
    </div>
  );
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import RcCheckbox from "rc-checkbox";

import "./Checkbox.scss";

export const Checkbox = RcCheckbox;

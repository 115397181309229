/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import dayjs, { UnitType } from "dayjs";
import calendar from "dayjs/plugin/calendar";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import i18next from "i18next";

import { AppConfig } from "../app";
import { CalendarTime } from "../constants/calendarTimePluginLocale";
import { IMediaTimestampModel } from "../models";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(calendar);

let currentLanguage = "";
export class TimeHelper {
  static setLanguage = async (language: string) => {
    if (currentLanguage === language) {
      return;
    }

    currentLanguage = language;

    if (locales[language]) {
      await locales[language]();
    }

    dayjs.locale(language);
  };

  static getCurrentDate() {
    return dayjs();
  }

  static getCurrentDateTime(): Date {
    return dayjs().toDate();
  }

  static getCurrentDateKey(format = "YYYY-MM-DD") {
    return dayjs().format(format);
  }

  static getDateKey(value: Date | number | string) {
    return dayjs(value).format("YYYY-MM-DD");
  }

  static getDate(value: Date | number | string): Date {
    const date = dayjs(value);

    return new Date(Date.UTC(date.year(), date.month(), date.date()));
  }

  static getDateTime(value: Date | number | string): Date {
    const date = dayjs(value);

    return date.toDate();
  }

  static getTimestamp(value: Date | number | string): number {
    const date = dayjs(value);

    return date.unix();
  }

  static getDateWithOffset(
    value: Date | number | string,
    offset: number,
    offsetUnit: dayjs.ManipulateType,
  ): Date {
    const date = dayjs(value).add(offset, offsetUnit);

    return date.toDate();
  }

  static getTimeToCurrent(value: Date | number | string): string {
    return dayjs().to(value);
  }

  static format(
    value: Date | number | string,
    stringWithTokens: string,
  ): string {
    return dayjs(value).format(stringWithTokens);
  }

  static diff(
    value1: Date | number | string,
    value2: Date | number | string,
    unit: dayjs.QUnitType = "day",
  ): number {
    const date1 = dayjs(value1);
    const date2 = dayjs(value2);

    return date1.diff(date2, unit);
  }

  public static isAfter(date: string, unit?: UnitType) {
    return dayjs().isAfter(date, unit);
  }

  static isMatchNotStared(
    value: Date | number | string | null | undefined,
  ): boolean {
    if (!value) {
      return false;
    }

    return dayjs(value).isAfter(dayjs());
  }

  static isBefore(
    date: Date | number | string,
    compareTo: Date | number | string,
    unit: dayjs.OpUnitType = "millisecond",
  ): boolean {
    const date1 = dayjs(date);
    const date2 = dayjs(compareTo);

    return date1.isBefore(date2, unit);
  }

  static isBeforeCurrent(
    value: Date | number | string | null | undefined,
  ): boolean {
    if (value === null || value === undefined) {
      return false;
    }

    const date1 = dayjs(value);
    const date2 = dayjs();

    return date1.isBefore(date2);
  }

  static isAfterCurrent(
    value: Date | number | string | null | undefined,
  ): boolean {
    if (value === null || value === undefined) {
      return false;
    }

    const date1 = dayjs(value);
    const date2 = dayjs();

    return date2.isAfter(date1);
  }

  static compare(
    value1: Date | number | string,
    value2: Date | number | string,
  ): number {
    const date1 = dayjs(value1);
    const date2 = dayjs(value2);

    return date1.isBefore(date2) ? -1 : date1.isSame(date2) ? 0 : 1;
  }

  static toISOString(value: Date | number | string) {
    return dayjs(value).toJSON();
  }

  static isBetween(
    compareDate: Date | number | string,
    startDate?: Date | number | string,
    endDate?: Date | number | string,
  ): boolean {
    const compare = dayjs(compareDate);
    const start = startDate ? dayjs(startDate) : undefined;
    const end = endDate ? dayjs(endDate) : undefined;

    if (start && end) {
      return start.isBefore(compare) && end.isAfter(compare);
    } else {
      return (start?.isBefore(compare) || end?.isAfter(compare)) ?? false;
    }
  }

  static isCurrentBetween(
    value1: Date | number | string | undefined,
    value2: Date | number | string | undefined,
  ): boolean {
    const currentDateTime = dayjs();

    if (!value1) {
      return false;
    }

    const date1 = dayjs(value1);

    if (!value2) {
      return date1.isBefore(currentDateTime);
    }

    const date2 = dayjs(value2);

    return date1.isBefore(currentDateTime) && date2.isAfter(currentDateTime);
  }

  static pad(value: number, size: number) {
    return ("000" + value).slice(size * -1);
  }

  static formatDuration(miliseconds: number | undefined, accuracy = 3) {
    let durationText = "";

    if (!miliseconds) {
      return durationText;
    }

    const hours = miliseconds / (60 * 60 * 1000);
    const rhours = Math.floor(miliseconds / (60 * 60 * 1000));
    const minutes = (hours - rhours) * 60;
    const rminutes = minutes && Math.floor(minutes);
    const seconds = (minutes - rminutes) * 60;
    const rseconds = seconds && Math.floor(seconds);

    if (rhours) {
      durationText += `${rhours}h `;
    }

    if (rminutes) {
      durationText += `${rminutes}min `;
    }

    if (rminutes < 1) {
      return "1min";
    }

    if (accuracy >= 3 && rseconds) {
      durationText += `${rseconds}s`;
    }

    return durationText;
  }

  static formatDurationMilliseconds(
    duration: number | undefined,
    format: "HH:mm" | "HH:mm:ss" | "(HH):mm:ss" | "HH:mm:ss,ms" = "HH:mm",
  ): string | undefined {
    let result: string | undefined = undefined;

    if (duration) {
      const seconds = Math.floor((duration / 1000) % 60);
      const minutes = Math.floor((duration / (1000 * 60)) % 60);
      const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
      const milliseconds = +parseFloat(`${duration}`).toFixed(3).slice(-3);

      switch (format) {
        case "HH:mm":
          result = `${this.pad(hours, 2)}:${this.pad(minutes, 2)}`;
          break;
        case "HH:mm:ss":
          result = `${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(
            seconds,
            2,
          )}`;
          break;
        case "(HH):mm:ss":
          if (hours === 0) {
            result = `${this.pad(minutes, 2)}:${this.pad(seconds, 2)}`;
          } else {
            result = `${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(
              seconds,
              2,
            )}`;
          }
          break;
        case "HH:mm:ss,ms":
          result = `${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(
            seconds,
            2,
          )},${this.pad(milliseconds, 3)}`;
          break;
      }
    }

    return result;
  }

  static formatFromTo(duration: number | undefined) {
    let durationText = "";

    if (duration) {
      const hours = duration / (60 * 60 * 1000);
      const rhours = Math.floor(duration / (60 * 60 * 1000));
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.round(minutes);

      if (rhours) {
        durationText += `${rhours}h `;
      }

      if (rminutes) {
        durationText += `${rminutes}min`;
      }
    }

    return durationText;
  }

  static formatDurationSeconds(
    duration: number | undefined,
    format: "HH:mm" | "HH:mm:ss" | "(HH):mm:ss" | "HH:mm:ss,ms" = "HH:mm",
  ): string | undefined {
    let result: string | undefined = undefined;

    if (duration !== undefined) {
      const hours = Math.floor(duration / 60 / 60);
      const minutes = Math.floor(duration / 60) % 60;
      const seconds = Math.floor(duration - hours * 60 * 60 - minutes * 60);
      const milliseconds = +parseFloat(`${duration}`).toFixed(3).slice(-3);
      switch (format) {
        case "HH:mm":
          result = `${this.pad(hours, 2)}:${this.pad(minutes, 2)}`;
          break;
        case "HH:mm:ss":
          result = `${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(
            seconds,
            2,
          )}`;
          break;
        case "(HH):mm:ss":
          if (hours === 0) {
            result = `${this.pad(minutes, 2)}:${this.pad(seconds, 2)}`;
          } else {
            result = `${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(
              seconds,
              2,
            )}`;
          }
          break;
        case "HH:mm:ss,ms":
          result = `${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(
            seconds,
            2,
          )},${this.pad(milliseconds, 3)}`;
          break;
      }
    }

    return result;
  }

  static formatAvailabilityDate(
    availableFrom: Date,
    availableTo?: Date,
    shortened?: boolean,
  ) {
    const from = dayjs(availableFrom);
    const template = shortened
      ? "ddd | DD MMM | HH:mm"
      : "dddd | DD MMM | HH:mm";
    let formattedDate = from.format(template);
    if (availableTo) {
      const to = dayjs(availableTo);
      formattedDate += to.format(" - HH:mm");
    }
    return formattedDate;
  }

  public static formatRelativeDateInRange(
    beforeDatePrefix: string,
    afterDatePrefix: string,
    startDate?: Date,
    endDate?: Date,
  ) {
    const currentDatetime = dayjs();
    if (startDate && currentDatetime.isBefore(dayjs(startDate))) {
      return `${beforeDatePrefix} ${currentDatetime.to(startDate)}`;
    } else if (endDate && currentDatetime.isAfter(dayjs(endDate))) {
      return `${afterDatePrefix} ${currentDatetime.to(endDate)}`;
    }
  }

  static formatCurrentTime(seconds: number) {
    const miliseconds = seconds > 0 ? seconds * 1000 : 0;
    const result = dayjs
      .utc(miliseconds)
      .format(seconds < 60 * 60 ? "m:ss" : "H:mm:ss");
    return result;
  }

  static getDuration(duration?: number, startTime?: Date, endTime?: Date) {
    if (startTime && endTime) {
      const start = dayjs(startTime);
      const end = dayjs(endTime);
      const dur = dayjs.duration(end.diff(start));

      return dur.asSeconds();
    }

    return duration ?? 0;
  }

  static getCurrentDuration(startTime?: Date) {
    if (!startTime) {
      return 0;
    }

    const start = dayjs(startTime);
    const current = dayjs();

    if (current.isBefore(start)) {
      return 0;
    }

    const dur = dayjs.duration(current.diff(start));

    return dur.asSeconds();
  }

  static getDateWithTranslation = (
    dateString: string | undefined,
  ): string | undefined => {
    if (dateString && dateString.includes("#")) {
      const dateMark = dateString.split("#");
      const transKey = (key: string) => `${i18next.t(key)} ${dateMark[1]}`;

      switch (dateMark[0]) {
        case "Today":
          return transKey(`COMMON__TODAY`);
        case "Yesterday":
          return transKey("COMMON__YESTERDAY");
        case "Tomorrow":
          return transKey("COMMON__TOMORROW");
      }
    }
    return dateString;
  };

  /**
   * Foramt date, by using Calendar Time plugin
   * https://day.js.org/docs/en/display/calendar-time
   *
   * @param date Date to format.
   * @return Formated date, e.g. `Today, at 9:30 AM`, `Dzisiaj, 9:30`
   */
  static formatCalendarRelativeDate(date?: Date, epg?: boolean) {
    if (date) {
      if (AppConfig.AppLocalTimezone) {
        return this.getDateWithTranslation(
          dayjs(date)
            .tz(AppConfig.AppLocalTimezone)
            .calendar(
              undefined,
              epg ? CalendarTime.pl_PL_EPG : CalendarTime.pl_PL,
            ),
        );
      }
      return this.getDateWithTranslation(
        dayjs(date).calendar(
          undefined,
          epg ? CalendarTime.pl_PL_EPG : CalendarTime.pl_PL,
        ),
      );
    }
  }

  static formatTimestampToSeconds(timestamp: IMediaTimestampModel | undefined) {
    return (
      timestamp &&
      (timestamp.Hour ?? 0) * 3600 +
        (timestamp.Minute ?? 0) * 60 +
        (timestamp.Second ?? 0)
    );
  }

  static createDate(value: Date | number | string, utc = false) {
    return utc ? dayjs(value).tz("utc") : dayjs(value);
  }

  static getStartOfToday(): Date {
    return dayjs().startOf("day").toDate();
  }

  static getStartOfDay(value: Date | number | string): Date {
    return dayjs(value).startOf("day").toDate();
  }

  static replaceTime(
    base: Date | number | string,
    time: Date | number | string,
  ): Date {
    const timeDate = dayjs(time);

    return dayjs(base)
      .hour(timeDate.hour())
      .minute(timeDate.minute())
      .second(timeDate.second())
      .millisecond(timeDate.millisecond())
      .toDate();
  }
}

const locales: { [key: string]: () => void } = {
  af: () => import("dayjs/locale/af"),
  am: () => import("dayjs/locale/am"),
  ar: () => import("dayjs/locale/ar"),
  "ar-dz": () => import("dayjs/locale/ar-dz"),
  "ar-iq": () => import("dayjs/locale/ar-iq"),
  "ar-kw": () => import("dayjs/locale/ar-kw"),
  "ar-ly": () => import("dayjs/locale/ar-ly"),
  "ar-ma": () => import("dayjs/locale/ar-ma"),
  "ar-sa": () => import("dayjs/locale/ar-sa"),
  "ar-tn": () => import("dayjs/locale/ar-tn"),
  az: () => import("dayjs/locale/az"),
  be: () => import("dayjs/locale/be"),
  bg: () => import("dayjs/locale/bg"),
  bi: () => import("dayjs/locale/bi"),
  bm: () => import("dayjs/locale/bm"),
  bn: () => import("dayjs/locale/bn"),
  "bn-bd": () => import("dayjs/locale/bn-bd"),
  bo: () => import("dayjs/locale/bo"),
  br: () => import("dayjs/locale/br"),
  bs: () => import("dayjs/locale/bs"),
  ca: () => import("dayjs/locale/ca"),
  cs: () => import("dayjs/locale/cs"),
  cv: () => import("dayjs/locale/cv"),
  cy: () => import("dayjs/locale/cy"),
  da: () => import("dayjs/locale/da"),
  de: () => import("dayjs/locale/de"),
  "de-at": () => import("dayjs/locale/de-at"),
  "de-ch": () => import("dayjs/locale/de-ch"),
  dv: () => import("dayjs/locale/dv"),
  el: () => import("dayjs/locale/el"),
  en: () => import("dayjs/locale/en"),
  "en-au": () => import("dayjs/locale/en-au"),
  "en-ca": () => import("dayjs/locale/en-ca"),
  "en-gb": () => import("dayjs/locale/en-gb"),
  "en-ie": () => import("dayjs/locale/en-ie"),
  "en-il": () => import("dayjs/locale/en-il"),
  "en-in": () => import("dayjs/locale/en-in"),
  "en-nz": () => import("dayjs/locale/en-nz"),
  "en-sg": () => import("dayjs/locale/en-sg"),
  "en-tt": () => import("dayjs/locale/en-tt"),
  eo: () => import("dayjs/locale/eo"),
  es: () => import("dayjs/locale/es"),
  "es-do": () => import("dayjs/locale/es-do"),
  "es-mx": () => import("dayjs/locale/es-mx"),
  "es-pr": () => import("dayjs/locale/es-pr"),
  "es-us": () => import("dayjs/locale/es-us"),
  et: () => import("dayjs/locale/et"),
  eu: () => import("dayjs/locale/eu"),
  fa: () => import("dayjs/locale/fa"),
  fi: () => import("dayjs/locale/fi"),
  fo: () => import("dayjs/locale/fo"),
  fr: () => import("dayjs/locale/fr"),
  "fr-ca": () => import("dayjs/locale/fr-ca"),
  "fr-ch": () => import("dayjs/locale/fr-ch"),
  fy: () => import("dayjs/locale/fy"),
  ga: () => import("dayjs/locale/ga"),
  gd: () => import("dayjs/locale/gd"),
  gl: () => import("dayjs/locale/gl"),
  "gom-latn": () => import("dayjs/locale/gom-latn"),
  gu: () => import("dayjs/locale/gu"),
  he: () => import("dayjs/locale/he"),
  hi: () => import("dayjs/locale/hi"),
  hr: () => import("dayjs/locale/hr"),
  ht: () => import("dayjs/locale/ht"),
  hu: () => import("dayjs/locale/hu"),
  "hy-am": () => import("dayjs/locale/hy-am"),
  id: () => import("dayjs/locale/id"),
  is: () => import("dayjs/locale/is"),
  it: () => import("dayjs/locale/it"),
  "it-ch": () => import("dayjs/locale/it-ch"),
  ja: () => import("dayjs/locale/ja"),
  jv: () => import("dayjs/locale/jv"),
  ka: () => import("dayjs/locale/ka"),
  kk: () => import("dayjs/locale/kk"),
  km: () => import("dayjs/locale/km"),
  kn: () => import("dayjs/locale/kn"),
  ko: () => import("dayjs/locale/ko"),
  ku: () => import("dayjs/locale/ku"),
  ky: () => import("dayjs/locale/ky"),
  lb: () => import("dayjs/locale/lb"),
  lo: () => import("dayjs/locale/lo"),
  lt: () => import("dayjs/locale/lt"),
  lv: () => import("dayjs/locale/lv"),
  me: () => import("dayjs/locale/me"),
  mi: () => import("dayjs/locale/mi"),
  mk: () => import("dayjs/locale/mk"),
  ml: () => import("dayjs/locale/ml"),
  mn: () => import("dayjs/locale/mn"),
  mr: () => import("dayjs/locale/mr"),
  ms: () => import("dayjs/locale/ms"),
  "ms-my": () => import("dayjs/locale/ms-my"),
  mt: () => import("dayjs/locale/mt"),
  my: () => import("dayjs/locale/my"),
  nb: () => import("dayjs/locale/nb"),
  ne: () => import("dayjs/locale/ne"),
  nl: () => import("dayjs/locale/nl"),
  "nl-be": () => import("dayjs/locale/nl-be"),
  nn: () => import("dayjs/locale/nn"),
  "oc-lnc": () => import("dayjs/locale/oc-lnc"),
  "pa-in": () => import("dayjs/locale/pa-in"),
  pl: () => import("dayjs/locale/pl"),
  pt: () => import("dayjs/locale/pt"),
  "pt-br": () => import("dayjs/locale/pt-br"),
  rn: () => import("dayjs/locale/rn"),
  ro: () => import("dayjs/locale/ro"),
  ru: () => import("dayjs/locale/ru"),
  rw: () => import("dayjs/locale/rw"),
  sd: () => import("dayjs/locale/sd"),
  se: () => import("dayjs/locale/se"),
  si: () => import("dayjs/locale/si"),
  sk: () => import("dayjs/locale/sk"),
  sl: () => import("dayjs/locale/sl"),
  sq: () => import("dayjs/locale/sq"),
  sr: () => import("dayjs/locale/sr"),
  "sr-cyrl": () => import("dayjs/locale/sr-cyrl"),
  ss: () => import("dayjs/locale/ss"),
  sv: () => import("dayjs/locale/sv"),
  "sv-fi": () => import("dayjs/locale/sv-fi"),
  sw: () => import("dayjs/locale/sw"),
  ta: () => import("dayjs/locale/ta"),
  te: () => import("dayjs/locale/te"),
  tet: () => import("dayjs/locale/tet"),
  tg: () => import("dayjs/locale/tg"),
  th: () => import("dayjs/locale/th"),
  tk: () => import("dayjs/locale/tk"),
  "tl-ph": () => import("dayjs/locale/tl-ph"),
  tlh: () => import("dayjs/locale/tlh"),
  tr: () => import("dayjs/locale/tr"),
  tzl: () => import("dayjs/locale/tzl"),
  tzm: () => import("dayjs/locale/tzm"),
  "tzm-latn": () => import("dayjs/locale/tzm-latn"),
  "ug-cn": () => import("dayjs/locale/ug-cn"),
  uk: () => import("dayjs/locale/uk"),
  ur: () => import("dayjs/locale/ur"),
  uz: () => import("dayjs/locale/uz"),
  "uz-latn": () => import("dayjs/locale/uz-latn"),
  vi: () => import("dayjs/locale/vi"),
  "x-pseudo": () => import("dayjs/locale/x-pseudo"),
  yo: () => import("dayjs/locale/yo"),
  zh: () => import("dayjs/locale/zh"),
  "zh-cn": () => import("dayjs/locale/zh-cn"),
  "zh-hk": () => import("dayjs/locale/zh-hk"),
  "zh-tw": () => import("dayjs/locale/zh-tw"),
};

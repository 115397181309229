/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IAppState,
  InfoDetailsScreenCode,
  IUserConsentModel,
  ThemeContext,
  UserStore,
} from "@bms/common";
import Color from "color";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { AnalyticsContext } from "context/analytics";

import "./CookieConsentBar.scss";

export const CookieConsentBar = () => {
  const { isCookiesAccepted, enableAnalytics } = useContext(AnalyticsContext);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { themeProvider } = useContext(ThemeContext);

  const userConsents = useSelector<IAppState, IUserConsentModel[] | undefined>(
    (state) => state.user.consents.Data,
  );

  const cookieConsent = useMemo<IUserConsentModel | undefined>(
    () =>
      userConsents &&
      userConsents.find(
        (consent) => consent.ConsentCode === InfoDetailsScreenCode.COOKIES,
      ),
    [userConsents],
  );

  const updateConsent = useCallback(
    (data: IUserConsentModel) => {
      dispatch(UserStore.Actions.updateUserConsent(data));
    },
    [dispatch],
  );

  const acceptCookie = useCallback(
    (isAccepted: boolean) => {
      if (cookieConsent) {
        const updatedCookieConsent: IUserConsentModel = {
          ...cookieConsent,
          Accepted: isAccepted,
        };
        updateConsent(updatedCookieConsent);
      }
      isAccepted && enableAnalytics?.();
    },
    [cookieConsent, enableAnalytics, updateConsent],
  );

  const cookiesTranslationTitle = useMemo(() => {
    const cookieTitle = t("COOKIES_TITLE");
    return cookieTitle.replaceAll(" ", "");
  }, [t]);

  const cookieGeneralCustomStyle = useMemo(
    () => ({
      color: themeProvider.getColor("AppPrimaryTextColor"),
      boxShadow: "0px 0px 5px 0.5px black ",
      zIndex: 1009,
    }),
    [themeProvider],
  );
  const buttonCustomStyle = useMemo(
    () => ({
      borderRadius: "27px",
      padding: "1rem 2rem",
    }),
    [],
  );

  useEffect(() => {
    dispatch(UserStore.Actions.getUserConsents());
  }, []);

  if (isCookiesAccepted) {
    return null;
  }

  return (
    <div className="Cookies">
      {cookieConsent && (
        <CookieConsent
          enableDeclineButton={false}
          onDecline={() => acceptCookie(false)}
          onAccept={() => acceptCookie(true)}
          cookieName={cookiesTranslationTitle || "cookies"}
          expires={360}
          location="bottom"
          declineButtonText={t("COOKIES_BUTTON__DECLINE", "Decline")}
          buttonText={t("COOKIES_BUTTON__ACCEPT", "Accept")}
          buttonStyle={{
            ...cookieGeneralCustomStyle,
            ...buttonCustomStyle,
            marginRight: "2rem",
            backgroundColor: themeProvider.getColor("AppPrimaryColor"),
          }}
          declineButtonStyle={{
            ...cookieGeneralCustomStyle,
            ...buttonCustomStyle,
            marginLeft: "2rem",
            backgroundColor: Color(themeProvider.getColor("AppBackgroundColor"))
              .alpha(0.55)
              .string(),
          }}
          style={{
            ...cookieGeneralCustomStyle,
            background: Color(themeProvider.getColor("AppSecondaryColor"))
              .alpha(0.95)
              .string(),
          }}
          contentStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {t(
            "COOKIES_CONSENT",
            "This website uses cookies to enhance the user experience.",
          )}{" "}
          {cookieConsent && cookieConsent.ConsentContentUrl && (
            <Link
              target="_blank"
              to={{ pathname: cookieConsent.ConsentContentUrl }}
            >
              <span className="link underline">
                {t("COOKIES_TITLE", "Cookies")}
              </span>
            </Link>
          )}
        </CookieConsent>
      )}
    </div>
  );
};

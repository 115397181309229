/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { isMobile, isTablet, osName } from "react-device-detect";

import { IUserDeviceModel } from "../models";
import { StorageKey, StorageManager } from "../services";

import { GuidHelper } from "./guid.helper";
import { PlatformHelper } from "./platformHelper";

export class DeviceHelper {
  static getDeviceInfo = async (): Promise<IUserDeviceModel> => {
    const info: IUserDeviceModel = {
      PlatformCode: PlatformHelper.Platform,
    };

    let osid = await StorageManager.getValue(StorageKey.osid);

    if (!osid) {
      osid = GuidHelper.newGuid();
      await StorageManager.setValue(StorageKey.osid, osid);
    }

    info.Name = `${osName} (${osid})`;

    return info;
  };

  static isMobileOrTablet = () => {
    return isMobile || isTablet;
  };
}

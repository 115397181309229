/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { withTranslation } from "react-i18next";

import { MyListButton as MyListButtonDefinition } from "./MyListButton";

export const MyListButton = withTranslation()(MyListButtonDefinition);

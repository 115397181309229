/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

export class DownloadHelper {
  static getBasePath = () => {
    return "";
  };

  static getPlatformSpecificUrl = (url: string | undefined) => {
    return url ?? "";
  };
}

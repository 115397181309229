/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { CellType, SourceType } from "../../../../../common/src/enums";

import { MottoSourceName } from "./helpers/listQueryParamsMapper";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const motoConfigurationMock: any = {
  Id: 4,
  Guid: "bb32c90c-7646-4ce4-bdc6-33b7933cf247",
  VersionNumber: 2814,
  Description: "Main configuration",
  Branding: {
    Id: 13,
    AppBackgroundColor: "#000000",
    AppCellsBackgroundColor: "#4a4a4a",
    AppDescription:
      "Better Media Suite Development 1 - OTT White Label Platform",
    AppDetailsBackgroundColor: "#000000",
    AppFocusBackgroundColor: "#ffffff",
    AppFocusTextColor: "#000000",
    AppFontFamily: "Abrade, sans-serif",
    AppFontSize: "16",
    AppLogoExtendedUrl:
      "https://cdn.dev1.bettermedia.tv/Tenant_1/ApplicationConfiguration/bb32c90c76464ce4bdc633b7933cf247/Resources/Images/a29c0734544a4a1a9c95ff57750ad347",
    AppLogoUrl:
      "https://cdn.dev1.bettermedia.tv/Tenant_1/ApplicationConfiguration/bb32c90c76464ce4bdc633b7933cf247/Resources/Images/369b0e9f3c784ad4a002f8e794d250b6",
    AppModalsBackgroundColor: "#000000",
    AppPrimaryColor: "#0263d4",
    AppPrimaryTextColor: "#ffffff",
    AppSecondaryColor: "#0263d4",
    AppSecondaryTextColor: "#ffffff",
    AppTertiaryBackgroundColor: "#4a4a4a",
    AppTertiaryTextColor: "#d0d0d0",
    AppTitle: "BMS DEV 1",
    FooterBackgroundColor: "#000000",
    FooterLinkColor: "#ffffff",
    FooterLinkHoverColor: "#000000",
    HeaderBackgroundColor: "#000000",
    HeaderLinkColor: "#ffffff",
    HeaderLinkHoverColor: "#4a90e2",
    MobileHeaderLogoUrl:
      "https://cdn.dev1.bettermedia.tv/Tenant_1/ApplicationConfiguration/bb32c90c76464ce4bdc633b7933cf247/Resources/Images/8cf7dc0dc2ec4effa02ce7260be80015",
    WebFaviconUrl:
      "https://cdn.dev1.bettermedia.tv/Tenant_1/ApplicationConfiguration/bb32c90c76464ce4bdc633b7933cf247/Resources/Images/61ea102239694fb08dcab4e03dd6bd3f",
  },
  Screens: {
    APPLICATION_MENU: {
      Id: 14,
      Name: "Application Menu - SmartTV & AndroidTV",
      ScreenTypeCode: "APPLICATION_MENU",
      Components: [
        {
          Id: 1440,
          ComponentTypeCode: "APPLICATION_MENU_ITEM",
          Name: "Application Menu Item",
          IsVisible: true,
          Action: {
            Id: 1441,
            ComponentTypeCode: "ACTION",
            ActionType: "OPEN_SCREEN",
            ScreenTypeCode: "SEARCH",
          },
          IconUrl:
            "https://cdn.dev1.bettermedia.tv/Tenant_1/ApplicationConfiguration/bb32c90c76464ce4bdc633b7933cf247/Resources/Images/Search.svg",
          Title: "Search",
          TitleTranslationKey: "MENU_SEARCH",
        },
        {
          Id: 46,
          ComponentTypeCode: "APPLICATION_MENU_ITEM",
          Name: "Application Menu Item",
          IsVisible: true,
          Action: {
            Id: 47,
            ComponentTypeCode: "ACTION",
            ActionType: "OPEN_SCREEN",
            ScreenTypeCode: "HOME",
          },
          IconUrl:
            "https://cdn.dev1.bettermedia.tv/Tenant_1/ApplicationConfiguration/bb32c90c76464ce4bdc633b7933cf247/Resources/Images/Home.svg",
          Title: "Home",
        },
        {
          Id: 1435,
          ComponentTypeCode: "APPLICATION_MENU_ITEM",
          Name: "Application Menu Item",
          IsVisible: true,
          Action: {
            Id: 1436,
            ComponentTypeCode: "ACTION",
            ActionType: "OPEN_SCREEN",
            ScreenTypeCode: "LOGIN",
          },
          IconUrl:
            "https://cdn.dev1.bettermedia.tv/Tenant_1/ApplicationConfiguration/bb32c90c76464ce4bdc633b7933cf247/Resources/Images/Login.svg",
          Title: "Log in",
          TitleTranslationKey: "COMMON__SIGN_IN",
        },
        {
          Id: 1437,
          ComponentTypeCode: "APPLICATION_MENU_ITEM",
          Name: "Application Menu Item",
          IsVisible: true,
          Action: {
            Id: 1438,
            ComponentTypeCode: "ACTION",
            ActionType: "OPEN_SCREEN",
            ScreenTypeCode: "SETTINGS",
          },
          IconUrl:
            "https://cdn.dev1.bettermedia.tv/Tenant_1/ApplicationConfiguration/bb32c90c76464ce4bdc633b7933cf247/Resources/Images/Settings.svg",
          Title: "Settings",
          TitleTranslationKey: "SETTINGS_TITLE",
        },
      ],
    },
    HOME: {
      Id: 31,
      Name: "Home SmartTV + AndroidTV",
      ScreenTypeCode: "HOME",
      Components: [
        {
          Id: 1260,
          ComponentTypeCode: "LIST",
          Name: "List",
          IsVisible: true,
          CellType: CellType.Highlights,
          SourceId: 1,
          SourceName: MottoSourceName.BANNER_HOMEPAGE,
          SourceType: SourceType.Standard,
          Title: "",
          VisibleItemsCount: 6,
        },
        {
          Id: 152,
          ComponentTypeCode: "LIST",
          Name: "List",
          IsVisible: true,
          CellType: CellType.Event,
          Orientation: "DEFAULT",
          SourceId: 2,
          SourceName: MottoSourceName.EVENT_UPCOMING,
          SourceType: SourceType.Standard,
          Title: "Live & Upcoming on SAFF+",
          VisibleItemsCount: 5,
        },
        {
          Id: 1566,
          ComponentTypeCode: "LIST",
          Name: "List",
          IsVisible: true,
          CellType: CellType.Round,
          SourceId: 3,
          SourceName: MottoSourceName.CLUBS,
          SourceType: SourceType.Standard,
          Title: "Clubs",
          VisibleItemsCount: 5,
        },
        {
          Id: 150,
          ComponentTypeCode: "LIST",
          Name: "List",
          IsVisible: true,
          CellType: CellType.Event,
          Orientation: "DEFAULT",
          SourceId: 4,
          SourceName: MottoSourceName.EVENT_RECENT,
          SourceType: SourceType.Standard,
          Title: "Recent matches",
          VisibleItemsCount: 5,
        },
        {
          Id: 1256,
          ComponentTypeCode: "LIST",
          Name: "List",
          IsVisible: true,
          CellType: CellType.Event,
          SourceId: 5,
          SourceName: MottoSourceName.EVENT_RECENT_INTERNATIONAL,
          SourceType: SourceType.Standard,
          Title: "Recent international matches",
          VisibleItemsCount: 5,
        },
        {
          Id: 296,
          ComponentTypeCode: "LIST",
          Name: "List",
          IsVisible: true,
          CellType: CellType.Highlights,
          SourceId: 6,
          SourceName: MottoSourceName.BANNER_HOMEPAGE_MIDDLE,
          SourceType: SourceType.Standard,
          Title: "",
          VisibleItemsCount: 5,
        },
        {
          Id: 1470,
          ComponentTypeCode: "LIST",
          Name: "List",
          IsVisible: true,
          CellType: CellType.Cover,
          SourceId: 7,
          SourceName: MottoSourceName.COMPETITIONS,
          SourceType: SourceType.Standard,
          Title: "Competitions",
          VisibleItemsCount: 5,
        },
      ],
    },
    SETTINGS_MENU: {
      Id: 216,
      Name: "Settings Menu",
      ScreenTypeCode: "SETTINGS_MENU",
      Components: [
        {
          Id: 1811,
          ComponentTypeCode: "SETTINGS_MENU_ITEM",
          Name: "Settings Menu Item",
          IsVisible: true,
          Action: {
            Id: 1812,
            ComponentTypeCode: "ACTION",
            ActionType: "OPEN_SCREEN",
            ScreenTypeCode: "PROFILE",
          },
          IconUrl: "https://www.svgrepo.com/show/437116/person-circle.svg",
          Title: "Profile",
          TitleTranslationKey: "COMMON__PROFILE",
        },
        {
          Id: 1813,
          ComponentTypeCode: "SETTINGS_MENU_ITEM",
          Name: "Settings Menu Item",
          IsVisible: true,
          Action: {
            Id: 1814,
            ComponentTypeCode: "ACTION",
            ActionType: "OPEN_SCREEN",
            ScreenTypeCode: "HELP",
          },
          IconUrl:
            "https://www.svgrepo.com/show/32854/question-mark-in-a-circle-outline.svg",
          Title: "Help",
          TitleTranslationKey: "COMMON__HELP",
        },
        {
          Id: 1815,
          ComponentTypeCode: "SETTINGS_MENU_ITEM",
          Name: "Settings Menu Item",
          IsVisible: true,
          Action: {
            Id: 1816,
            ComponentTypeCode: "ACTION",
            ActionType: "OPEN_SCREEN",
            ScreenTypeCode: "ABOUT",
          },
          IconUrl: "https://www.svgrepo.com/show/59321/information-icon.svg",
          Title: "About",
          TitleTranslationKey: "COMMON__ABOUT",
        },
        {
          Id: 1817,
          ComponentTypeCode: "SETTINGS_MENU_ITEM",
          Name: "Settings Menu Item",
          IsVisible: true,
          Action: {
            Id: 1818,
            ComponentTypeCode: "ACTION",
            ActionType: "OPEN_SCREEN",
            ScreenTypeCode: "USER_ORDERS",
          },
          IconUrl:
            "https://www.svgrepo.com/download/20675/open-cardboard-box.svg",
          Title: "Orders",
          TitleTranslationKey: "COMMON__MY_ORDERS",
        },
        {
          Id: 1958,
          ComponentTypeCode: "SETTINGS_MENU_ITEM",
          Name: "Settings Menu Item",
          IsVisible: true,
          Action: {
            Id: 1959,
            ComponentTypeCode: "ACTION",
            ActionType: "OPEN_SCREEN",
            ScreenTypeCode: "CONSENTS",
          },
          IconUrl: "https://www.svgrepo.com/show/55708/open-hand.svg",
          Title: "Consents",
          TitleTranslationKey: "COMMON__TERMS_AND_CONDITIONS",
        },
        {
          Id: 1960,
          ComponentTypeCode: "SETTINGS_MENU_ITEM",
          Name: "Settings Menu Item",
          IsVisible: true,
          Action: {
            Id: 1961,
            ComponentTypeCode: "ACTION",
            ActionType: "OPEN_SCREEN",
            ScreenTypeCode: "PREFERENCES",
          },
          IconUrl: "https://www.svgrepo.com/show/144951/flag.svg",
          Title: "Preferences",
          TitleTranslationKey: "COMMON__LANGUAGE",
        },
      ],
    },
    HELP: {
      Id: 243,
      Name: "Help",
      ScreenTypeCode: "HELP",
      Components: [
        {
          Id: 2037,
          ComponentTypeCode: "TEXT_WIDGET",
          Name: "Text Widget",
          IsVisible: true,
          Layout: {
            PositionX: 0,
            PositionY: 0,
            Width: 12,
            Height: 6,
            PlatformCode: "ANY",
          },
          Title: "Help Center",
        },
        {
          Id: 2038,
          ComponentTypeCode: "TEXT_WIDGET",
          Name: "Text Widget",
          IsVisible: true,
          Layout: {
            PositionX: 0,
            PositionY: 6,
            Width: 12,
            Height: 6,
            PlatformCode: "ANY",
          },
          Content:
            '<ul><li>By deleting your account, you will permanently lose access to your account activity, preferences and more.</li><li>Once your account is permanently deleted, you&#x27;ll need to sign up for a new account if you want to use Como again.</li></ul><br/><h2>On desktop computer:</h2><ol><li>Head to <a href="https://tv.comofootball.com" target="_blank">tv.comofootball.com</a> in your web browser.</li><li>Hover over the menu on the top-right of the window, then select the <b>Profile</b> option.</li><li>Scroll down the <b>Profile</b> page and click <b>Delete my account</b>.</li><li>Confirm you want to delete your account by clicking <b>Delete account</b> button.</li><li>On the delete account page enter your password and click <b>Delete</b> button.</li></ol><br/><h2>On a mobile device:</h2><ol><li>Open the Como app on your mobile device.</li><li>On the top of the screen, tap the <b>Profile</b> button, which looks like a silhouette of a person.</li><li>Select <b>Profile</b> option.</li><li>Scroll down and tap <b>Delete my account</b>.</li><li>Confirm you want to delete your account by tapping <b>Delete my account</b> button.</li><li>On the delete account page enter your password and tap <b>Delete</b> button.</li></ol><br/>',
          Title: "How to delete your account",
        },
      ],
    },
    SEARCH: {
      Id: 247,
      Name: "Search",
      ScreenTypeCode: "SEARCH",
      CellType: "FRAME",
      Components: [],
    },
  },
  System: {
    CurrencyCode: "PLN",
    RegistrationEnabled: true,
    Media: {
      ProgressSyncInterval: 30000,
      FreeAssetOnlyForLoggedInUsers: false,
    },
    CryptoCoin: {
      Enabled: false,
    },
    Payment: {
      Enabled: true,
      Mappings: {
        ONETIME: {
          Providers: ["STRIPE"],
        },
        SUBSCRIPTION: {
          Providers: ["STRIPE"],
        },
      },
      Providers: {
        STRIPE: {
          PublicApiKey:
            "pk_test_51IlDipKJbSEYW1uRHRDSAPowi2g8mPejhJ7cgj3reqlg7dIlZx28CHrIwaVf8mJZFIZHfyCqQ7DFk4IeN7UHvaTI00lFZuW6Gk",
        },
        P24: {},
      },
    },
  },
  Languages: [
    {
      Id: 1,
      Code: "en",
      Name: "English",
      RTL: false,
    },
    {
      Id: 12,
      Code: "ar",
      Name: "أرابسكي",
      RTL: true,
    },
  ],
  Translations: {
    en: {
      " PLAYER_SETTINGS__MENU_SOUNDTRACK_OPTION_NO_SOUNDTRACK": "No soundtrack",
      ACCOUNT_CREATED_TEXT: "Your account has been created!",
      Agust: "Agust",
      ALREADY_PURCHASED: "Already purchased",
      Apirl: "Apirl",
      APP_ABOUT:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      APP_CLOSE_DIALOG__MESSAGE: "Do you want to close the application?",
      APP_CLOSE_DIALOG__TITLE: "Close application?",
      APP_GLOBAL_ERROR_DIALOG__TITLE: "Error",
      APP_OFFLINE_LONG_MESSAGE:
        "No internet connection available. Connect to the internet and try again.",
      APP_OFFLINE_SHORT_MESSAGE: "You're in an offline mode",
      APPLICATION_FOOTER_ABOUT: "About Better Media Suite",
      APPLICATION_HEADER__SEARCH_PLACEHOLDER: "Search",
      APPLICATION_MENU_CATALOG: "Catalog",
      APPLICATION_MENU_HOME: "Home",
      APPLICATION_MENU_MOVIES: "Movies",
      APPLICATION_MENU_MY_EPISODES: "PAYMENT_PROCESSING_",
      APPLICATION_MENU_PODCASTS: "Podcasts",
      APPLICATION_MENU_SERIES: "Series",
      APRIL: "April",
      ARTICLE__PAID_DESC:
        "To view {title} go to the website to purchase access to the article",
      ARTICLE__PAID_TITLE: "Paid content",
      ARTICLE__PURCHASE_INFO: "You can read this article after buying access",
      ASSET_ACCESS_TYPE_HIDDEN: "Hidden",
      ASSET_ACCESS_TYPE_IN_REVIEW: "In review",
      ASSET_ACCESS_TYPE_PUBLIC: "Public",
      ASSET_COMMENT_REPLY: "Reply to your comment",
      ASSET_CONCURRENT_PLAYBACK_LIMIT:
        "You are currently watching the same content on too many devices in parallel. Select another channel to watch on this device.",
      ASSET_CONTENT_NOT_FOUND: "Asset content not found",
      ASSET_CONTENT_STATUS_CANCELED: "Video transcoding canceled",
      ASSET_CONTENT_STATUS_ERROR: "Video transcoding error",
      ASSET_CONTENT_STATUS_PROCESSING: "Video is processing",
      ASSET_CONTENT_STATUS_QUEUED: "Video is queued",
      ASSET_CONTENT_STATUS_READY: "Video is ready",
      ASSET_CONTENT_STREAM_TYPE_MAIN: "Main",
      ASSET_CONTENT_STREAM_TYPE_SOURCE: "Source",
      ASSET_CONTENT_STREAM_TYPE_TRIAL: "Trial",
      ASSET_CONTENT_TRANSCODING_ERROR: "Error transcoding media content",
      ASSET_CONTENT_TYPE_AAC: "AAC",
      ASSET_CONTENT_TYPE_AUDIO_AAC: "AAC",
      ASSET_CONTENT_TYPE_AUDIO_M4A: "M4A",
      ASSET_CONTENT_TYPE_AUDIO_MP3: "MP3",
      ASSET_CONTENT_TYPE_AUDIO_OGG: "OGG",
      ASSET_CONTENT_TYPE_AUDIO_WAV: "WAV",
      ASSET_CONTENT_TYPE_DASH: "MPD",
      ASSET_CONTENT_TYPE_HLS: "HLS",
      ASSET_CONTENT_TYPE_HSS: "HSS",
      ASSET_CONTENT_TYPE_M4A: "M4A",
      ASSET_CONTENT_TYPE_MP3: "MP3",
      ASSET_CONTENT_TYPE_OGG: "OGG",
      ASSET_CONTENT_TYPE_TEXT_HTML: "HTML",
      ASSET_CONTENT_TYPE_VIDEO_MOV: "MOV",
      ASSET_CONTENT_TYPE_VIDEO_MP2T: "MPEG transport stream",
      ASSET_CONTENT_TYPE_VIDEO_MP4: "MP4",
      ASSET_COPY_LINK_TO_CLIPBOARD: "Link copied to the clipboard!",
      ASSET_EVENT_COMMENT_REPLY: "Reply to your comment",
      ASSET_EVENT_INVITATION: "You have new asset event invitation",
      ASSET_EVENT_JOIN_STATUS_JOINED: "Already joined",
      ASSET_EVENT_JOIN_STATUS_NONE: "",
      ASSET_EVENT_JOIN_STATUS_PENDING: "Pending invitation",
      ASSET_EVENT_NOT_FOUND: "Asset event not found",
      ASSET_EVENT_NOT_PURCHASED: "Asset event not purchased",
      ASSET_EVENT_PROCESSED: "Video has been processed",
      ASSET_EVENT_PURCHASE_NOTIFICATION: "Payment confirmation",
      ASSET_EVENT_REACTIONS_COUNT_EXCEEDED: "You can only use 10 reactions",
      ASSET_EVENT_REMIND: "Event reminder",
      ASSET_EVENT_STARTING: "Event is starting ",
      ASSET_EVENT_TYPE_MOVIE_NIGHT: "Watch Party",
      ASSET_EVENT_TYPE_SCHEDULED_LIVE: "Scheduled Live",
      ASSET_EVENT_TYPE_VIDEO_PREMIERE: "Video Premiere",
      ASSET_EVENT_UPLOADED: "New invitation for ",
      ASSET_FREE_WITH_PRICES_NOT_ALLOWED:
        "Free content cannot have defined prices",
      ASSET_HAS_NO_CONTENT: "Asset has no content defined",
      ASSET_IMAGE_TYPE_BACKGROUND: "Background",
      ASSET_IMAGE_TYPE_CONTENT: "Content",
      ASSET_IMAGE_TYPE_COVER: "Cover",
      ASSET_IMAGE_TYPE_FRAME: "Frame",
      ASSET_IMAGE_TYPE_HIGHLIGHTS: "Highlights",
      ASSET_IMAGE_TYPE_LANDSCAPE: "Hero Landscape",
      ASSET_IMAGE_TYPE_POSTER: "Poster",
      ASSET_IMAGE_TYPE_ROUND: "Round",
      ASSET_IMAGE_TYPE_SQUARE: "Square",
      ASSET_IS_GEOBLOCKED: "Asset is not available in your region.",
      ASSET_NOT_FOUND: "Asset not found",
      ASSET_NOT_PURCHASED: "Asset not purchased",
      ASSET_PROCESSED: "Video has been processed",
      ASSET_PURCHASE_NOTIFICATION: "Payment confirmation",
      ASSET_PURCHASE_PERIOD_TYPES_ONE_TIME: "One time buy",
      ASSET_PURCHASE_PERIOD_TYPES_RENT_30_DAYS: "Rent for 30 days",
      ASSET_PURCHASE_PERIOD_TYPES_RENT_48H: "Rent for 48h",
      ASSET_PURCHASE_PERIOD_TYPES_SUBSCRIPTION: "Subscription",
      ASSET_REACTIONS_COUNT_EXCEEDED: "You can only use 10 reactions",
      ASSET_STATUS_APPROVED: "Approved",
      ASSET_STATUS_DRAFT: "Draft",
      ASSET_STATUS_PUBLISHED: "Published",
      ASSET_STATUS_REJECTED: "Rejected",
      ASSET_TYPE_ADVERTISEMENT: "Advertisement",
      ASSET_TYPE_ALBUM: "Album",
      ASSET_TYPE_ALBUM_ELEMENT: "Album element",
      ASSET_TYPE_ARTICLE: "Article",
      ASSET_TYPE_CHANNEL: "Channel",
      ASSET_TYPE_EPISODE: "Episode",
      ASSET_TYPE_EVENT: "Event",
      ASSET_TYPE_INTRO: "Intro",
      ASSET_TYPE_LIVE: "Live",
      ASSET_TYPE_PACKAGE: "Package",
      ASSET_TYPE_PODCAST: "Podcast",
      ASSET_TYPE_PROGRAM: "Program",
      ASSET_TYPE_SEASON: "Season",
      ASSET_TYPE_SERIES: "Series",
      ASSET_TYPE_VIDEO_PREMIERE: "Video Premiere",
      ASSET_TYPE_VOD: "VOD",
      ASSET_UPLOADED: "New video uploaded",
      ASSETQUALITY_4K: "4K",
      ASSETQUALITY_FULL_HD: "Full HD",
      ASSETQUALITY_HD: "HD",
      ASSETQUALITY_UNKNOWN: "Unknown",
      AUDIO_PLAYER__FASTER_PLAYBACK_SPEED: "faster",
      AUDIO_PLAYER__STANDARD_PLAYBACK_SPEED: "Standard",
      AUGUST: "August",
      AVAILABLE_IN_PACKAGE: "Available in a package",
      AVAILABLE_TO_DATE_VALIDATION_MESSAGE:
        "Availble to cannot be older than availible from",
      BASE_SCREEN__NO_DATA_FOUND: "No data found",
      BASE_SCREEN__SUGGEST_LOGIN: "Log in to view your lists",
      BILLING__TITLE: "Billing",
      BOTTOM_BAR__MORE: "More",
      BUTTON_LOGIN_VIA_EMAIL: "Login with email",
      CANNOT_DELETE_EXTERNAL: "Could not delete external element",
      CANNOT_DELETE_RECORD: "Could not delete element",
      CANNOT_INSERT_RECORD: "Could not insert element",
      CANNOT_PURCHASE_THIS_SUBSCRIPTION: "Cannot purchase this subscription",
      CANNOT_RESOLVE_CONNECTION_ID: "Cannot resolve connection id",
      CANNOT_UPDATE_RECORD: "Could not update element",
      CATEGORIES: "",
      CHANGE_EMAIL_CONFIRMATION: "Confirm your want to change email address to",
      CLOSE__APP: "Close Application",
      COMMON__ABOUT: "About",
      COMMON__ALL: "All",
      COMMON__ALL_EPISODES: "All episodes",
      COMMON__BILLING: "Billing",
      COMMON__BUTTON_ADD_TO_MY_LIST: "Add to my list",
      COMMON__BUTTON_BUY_ASSET: "Order now",
      COMMON__BUTTON_CANCEL: "Cancel",
      COMMON__BUTTON_CONFIRM: "Confirm",
      COMMON__BUTTON_COPY_LINK: "Copy link",
      COMMON__BUTTON_DELETE: "Delete",
      COMMON__BUTTON_DELETE_ACCOUNT: "Delete my account",
      COMMON__BUTTON_DOWNLOAD: "Download",
      COMMON__BUTTON_DOWNLOADED: "Downloaded",
      COMMON__BUTTON_DOWNLOADING: "Downloading",
      COMMON__BUTTON_EDIT_PROFILE: "Edit profile",
      COMMON__BUTTON_FINISH: "Finish",
      COMMON__BUTTON_GEOBLOCKED: "Unavailable",
      COMMON__BUTTON_GO_BACK: "Go back",
      COMMON__BUTTON_LISTEN_TRAILER: "Listen Trailer",
      COMMON__BUTTON_MORE: "Show more",
      COMMON__BUTTON_MORE_INFO: "More info",
      COMMON__BUTTON_PAUSE: "Pause",
      COMMON__BUTTON_PLAY: "Play now",
      COMMON__BUTTON_PLAY_AUDIO: "Play",
      COMMON__BUTTON_PREVIOUS: "Previous",
      COMMON__BUTTON_PREVIOUS_NEXT: "Next",
      COMMON__BUTTON_QUEUE: "In queue",
      COMMON__BUTTON_REGISTER: "Register",
      COMMON__BUTTON_REMOVE_FROM_MY_LIST: "Remove from my list",
      COMMON__BUTTON_REWIND_BACKWARD: "Rewind backward",
      COMMON__BUTTON_REWIND_FORWARD: "Rewind forward",
      COMMON__BUTTON_SHARE: "Share",
      COMMON__BUTTON_SPEED: "Speed",
      COMMON__BUTTON_VOLUME: "Volume",
      COMMON__BUTTON_WATCH: "Watch now",
      COMMON__BUTTON_WATCH_TRAILER: "Watch trailer",
      COMMON__CAMERA: "Camera",
      COMMON__CANCEL: "Cancel",
      COMMON__CATALOG: "Catalog",
      COMMON__DEFAULT: "Default",
      COMMON__DOWNLOADED: "Downloaded",
      COMMON__EMAIL_PLACEHOLDER: "Enter Your E-Mail",
      COMMON__EPISODES: "Episodes",
      COMMON__HELP: "Help",
      COMMON__HOME: "Home",
      COMMON__ICON: "Icon",
      COMMON__INTERNET_CONNECTION_ERROR_MESSAGE:
        "No internet connection, please reconnect.",
      COMMON__INTERNET_CONNECTION_ERROR_TITLE: "No connection.",
      COMMON__LANGUAGE: "Language",
      COMMON__LIBRARY: "Library",
      COMMON__LOGOUT: "Logout",
      COMMON__MORE_FROM: "More From",
      COMMON__MORE_LIKE_THIS: "More like this",
      COMMON__MY_LIST: "My list",
      COMMON__MY_ORDERS: "My orders",
      COMMON__MY_ORDERS_AND_BILLING: "Orders",
      COMMON__NO: "No",
      COMMON__NO_INFORMATION: "No information",
      COMMON__OK: "OK",
      COMMON__OPENS_NEW_TAB: "Opens new tab",
      COMMON__OR: "or",
      "COMMON__PASSWORD_PLACEHOLDER ": "Enter password",
      COMMON__PLAYER_CLOSE: "Close player",
      COMMON__PLAYER_PROGRESSBAR: "Progress bar",
      COMMON__PREFERENCES: "My preferences",
      COMMON__PROFILE: "Profile",
      COMMON__RATE: "Rate",
      COMMON__READ_MORE: "Read more",
      COMMON__SEASON: "Season",
      COMMON__SEE_DETAILS: "See details",
      COMMON__SEE_MORE: "See more",
      COMMON__SHOW_LESS: "Show less",
      COMMON__SIGN_IN: "Sign In",
      COMMON__TERMS_AND_CONDITIONS: "Terms & Conditions",
      common__till_the_end: "Till the end",
      COMMON__TODAY: "Today",
      COMMON__TOMORROW: "Tomorrow",
      COMMON__UNPLAYED: "Unplayed",
      COMMON__WATCH_NEXT: "Watch Next",
      COMMON__WATCH_NEXT_EPISODE: "Watch next episode",
      COMMON__WATCHED: "Watched",
      COMMON__YES: "Yes",
      COMMON__YESTERDAY: "Yesterday",
      COMMON_BUTTON_GEOBLOCKED: "Unavailable",
      COMMON_EXPIRED: "Expired",
      COMMON_OR: "or",
      CONFIRM_ACCOUNT_PASSWORD__PASSWORD_LABEL: "Password",
      CONFIRM_ACCOUNT_PASSWORD__RE_PASSWORD_LABEL: "Re-enter password",
      CONFIRM_ACCOUNT_PASSWORD__SUBMIT: "Activate",
      CONFIRM_ACCOUNT_PASSWORD__SUBTITLE:
        "Please enter your requested data below to proceed with confirmation your account.",
      CONFIRM_ACCOUNT_PASSWORD__TITLE: "Account confirmation",
      CONFIRM_ACCOUNT_PASSWORD__TOKEN_EXPIRED: "The token has expired",
      CONFIRM_ACCOUNT_PLEASE_TEXT:
        "Please confirm your account by clicking the button below.",
      CONFIRM_CANCEL_DOWNLOADING: "Do you want to cancel dowloading?",
      CONFIRM_CANCEL_NO: "No",
      CONFIRM_DELETE_DOWNLOADED_RESOURCE:
        "Do you want to remove selected resource?",
      CONFIRM_DIALOG__CANCEL: "Cancel",
      CONFIRM_DIALOG__CONFIRM: "Confirm",
      CONFIRM_DIALOG__CONFIRM_MESSAGE: "Do you want to continue?",
      CONFIRM_EMAIL__HINT: "Create your Better Media Suite account",
      CONFIRM_EMAIL__INVALID_TOKEN: "The token is not valid",
      CONFIRM_EMAIL__SUBMIT: "Activate",
      CONFIRM_EMAIL__SUBTITLE:
        "Please click the button below to activate your account.",
      CONFIRM_EMAIL__TITLE: "Account confirmation",
      CONFIRM_EMAIL__TOKEN_EXPIRED: "The token has expired",
      CONFIRMATION: "Confirmation",
      CONFIRMATION_CODE: "Confirmation code:",
      CONFIRMATION_LINK: "Confirm",
      CONNECTION_ERROR_INFO_BUTTON: "Try again",
      CONNECTION_ERROR_INFO_TITLE:
        "There was a problem with loading the content.",
      CONTACT_US_LABEL: "Contact us: email@address.com",
      CONTACTS_FORM__EMAIL_LABEL: "Your current E-mail",
      CONTACTS_FORM__NAME_LABEL: "Name",
      CONTACTS_FORM__PHONE_LABEL: "Phone",
      CONTACTS_FORM__PHONE_PLACEHOLDER: "Enter a phone number with +",
      CONTACTS_FORM__PHOTO_LOADING_ERROR: "Error loading photo",
      CONTACTS_FORM__SELECT_AVATAR: "Select Avatar",
      CONTACTS_FORM__SUBMIT: "Save changes",
      CONTENT_FILE_IS_MISSING: "Content file is missing",
      CONTINUE_LISTENING: "Would you like to continue listening?",
      CONTINUE_WATCHING_MESSAGE: "Would you like to continue watching?",
      CONTINUE_WATCHING_TITLE: "Continue watching",
      COOKIES_BUTTON__ACCEPT: "Accept",
      COOKIES_BUTTON__DECLINE: "Decline",
      COOKIES_CONSENT:
        "This website uses cookies to enhance the user experience.",
      COOKIES_TITLE: "Cookies",
      COULD_NOT_GET_TERMS:
        "Could not get Terms right now. Please try again later.",
      CREATOR_NAME: "eng creator name",
      CREATOR_ROLE_LIST_ATTENDEE: "Attendee",
      CREATOR_ROLE_LIST_CREATOR: "Creator",
      CREATOR_ROLE_LIST_MODERATOR: "Moderator",
      CRYPTO_CURRENCY_CODE: "CC",
      CRYPTO_CURRENCY_NAME: "Crypto coin",
      CURRENCY: "Currency",
      CURRENCY_PLACEHOLDER: "Select currency",
      DATABASE_CHECK_CONSTRAINT_VIOLATION:
        "The item contains incorrect data and will not be saved",
      DATABASE_CONCURRENCY_ERROR: "Record not found or changed by another user",
      DATABASE_FOREIGN_KEY_CONSTRAINT_VIOLATION:
        "This element cannot be removed as it is used",
      DATABASE_PRIMARY_KEY_VIOLATION: "Such element already exists",
      DATABASE_SECURITY_RULE_VIOLATION:
        "Changes cannot be made to an item due to lack of privileges",
      DATABASE_UNIQUE_CONSTRAINT_VIOLATION: "Such element already exists",
      DECEMBER: "December",
      DELETE_ACCOUNT__BUTTON_CANCEL: "Keep account",
      DELETE_ACCOUNT__BUTTON_CONFIRM: "Delete account",
      DELETE_ACCOUNT__INFO:
        "Please enter your password to proceed with deleting your account.",
      DELETE_ACCOUNT_SOCIALS__INFO:
        "We sent a verification code to {{email}}. Enter it here to delete your account.",
      DELETE_ACCOUNT__QUESTION:
        "You are about to delete your account. This will delete all your data permanently. Are you sure?",
      DELETE_ACCOUNT__TITLE: "Delete account",
      DELETE_ACCOUNT__WARNING: "This will delete all your data permanently.",
      DELETE_ACCOUNT_MESSAGE:
        "Are you sure you want to delete your account? This cannot be undone.",
      DELETE_ACCOUNT_SUCCESS__BUTTON_CREATE_ACCOUNT: "Create new account",
      DELETE_ACCOUNT_SUCCESS__BUTTON_HOME: "Go to the Home Page",
      DELETE_ACCOUNT_SUCCESS__INFO:
        "Your account has been deleted. We’re sorry to see you go. If you want to join us again, you can always create a new account.",
      DELETE_ACCOUNT_SUCCESS__TITLE: "Your account is deleted",
      DELETE_MY_ACCOUNT: "Delete my account",
      DETAILS__ALL_EPISODES: "All episodes",
      DETAILS__DOWNLOADED: "Downloaded",
      DETAILS__FILTER: "Filter",
      DETAILS__MY_LIST: "My list",
      DETAILS__PLAY_NOW: "Play now",
      DNS: "DNS",
      DOWNLOAD_AGAIN: "Do you want download again?",
      DOWNLOADED: "Downloaded",
      DOWNLOADED_FAILED: "Failed",
      DOWNLOADED_IN_PROGRESS: "In a progress",
      DOWNLOADED_IN_QUEUE: "In a queue",
      DOWNLOADED_NO_CONTENT:
        "There is no content. You have nothing downloaded to play in the offline mode.",
      DOWNLOADED_NO_CONTENT_ANONYMOUS: "Log in to use offline mode",
      DOWNLOADED_NO_CONTENT_OFFLINE:
        "There is no content. You have nothing downloaded to play in the offline mode.",
      DOWNLOADED_OFFLINE_MODE: "You’re in an offline mode.",
      DOWNLOADS_DOWNLOADED_SECTION: "Downloaded",
      DOWNLOADS_FAILED_ALERT_MESSAGE:
        "Download failed due to the lack of internet or not enough space on your device.",
      DOWNLOADS_FAILED_SECTION: "Failed",
      DOWNLOADS_INPROGRESS_SECTION: "In progress",
      DOWNLOADS_QUEUE_SECTION: "In a queue",
      DOWNLOADS_STATUS_TOAST_CANCELLED: "Download has been cancelled",
      DOWNLOADS_STATUS_TOAST_SUCCESS: "Download completed successfully",
      EDIT__EVENT_ADD_CATEGORIES: "Choose categories",
      EDIT__EVENT_AGE_RESTRICTION: "Select the correct age classification",
      EDIT__EVENT_AVAILABLE_FROM: "Available from date and time:",
      EDIT__EVENT_AVAILABLE_TO: "Available to date and time:",
      EDIT__EVENT_DATE_END: "Video broadcast end date and time:",
      EDIT__EVENT_DATE_START: "Video broadcast start date and time:",
      EDIT__EVENT_DESCRIPTION: "Description",
      EDIT__EVENT_TITLE: "Event Title",
      EDIT_BUTTON: "Edit",
      EDIT_PROFILE__PHONE_NUMBER: "Phone Number",
      EMAIL_BODY_CLICK_TO_ACTIVATE:
        "Please click on the button below to activate your account on",
      EMAIL_BODY_HELLO: "Hello",
      EMAIL_BODY_IGNORE_THIS_MESSAGE: "please ignore this message",
      EMAIL_BODY_NO_ACTION_PERFORMED:
        "If you didn’t try to execute any action on",
      EMAIL_BODY_PURCHASE_CONFIRMATION_TITLE:
        "Thank you for your order. Your payment has been accepted.",
      EMAIL_BODY_PURCHASE_INFO:
        "All current and previous purchases on the website can be found in the orders section.",
      EMAIL_BODY_PURCHASE_ORDER_DATE: "Date of order",
      EMAIL_BODY_PURCHASE_ORDER_NO: "Order no",
      EMAIL_CONFIRMATION: "Confirm your e-mail address in application.",
      EMAIL_SUBJECT_ACCOUNT_CREATED_CONFIRMATION:
        "Account created confirmation message",
      EMAIL_SUBJECT_ACCOUNT_EXISTS: "Account with email address exists",
      EMAIL_SUBJECT_CHANGE_EMAIL_CONFIRMATION:
        "Change email confirmation message",
      EMAIL_SUBJECT_EMAIL_CONFIRMATION: "Email confirmation message",
      EMAIL_SUBJECT_PURCHASE_CONFIRMATION: "Order confirmation",
      EMAIL_SUBJECT_RESET_PASSWORD_CONFIRMATION:
        "Reset password confirmation message",
      EMAIL_VALIDATION_MESSAGE: "E-mail is invalid.",
      EMAIL_WARNING_EMAIL_EXISTS:
        "Somebody tried to register on this e-mail address. If you cannot log in, try to use 'Forgot password'.",
      EPG__LOADING_DATA: "Loading data…",
      EPG__NO_DATA_AVAILABLE: "No data available",
      EPG__TITLE: "TV Program",
      EPISODES_NOT_FOUND: "Episodes not found",
      ERROR_PAYMENT_NOT_AVAILABLE_OR_YOU_STARTED_PAYMENT_ALREADY:
        "Payment not available or you started payment already",
      EVENT__FORM_UPLOAD_VIDEO: "Upload video",
      EVENT_IS_FINISHED: "Event is finished",
      EVENT_IS_ONGOING: "Event is ongoing",
      EVENT_SCREEN__ADD_EVENT: "Add event",
      EVENT_SCREEN__FILTER_MY_EVENTS: "My events",
      FEBRUARY: "February",
      FOOTER__ALL_RIGHTS_RESERVED:
        "<0>© Better Media Suite<2></2>All rights reserved.</0>",
      FOOTER__COMPANY_NAME: "© Better Media Suite. All rights reserved.",
      FORBIDDEN: "You have no permission to execute this action",
      FORGOT_PASSWORD__CANCEL: "Cancel",
      FORGOT_PASSWORD__SUBMIT: "Send reset link",
      FORGOT_PASSWORD__SUBTITLE:
        "Please enter your requested data below to proceed with recovering/resetting your password.",
      FORGOT_PASSWORD__TITLE: "Forgot password",
      FORGOT_PASSWORD_SUCCESS__CONFIRM: "OK",
      FORGOT_PASSWORD_SUCCESS__HINT:
        "Can't find the message in your inbox? Check folder spam.",
      FORGOT_PASSWORD_SUCCESS__SUBTITLE:
        "If you are an existing user, we will immediately send you an e-mail with a link to reset your password.",
      FORGOT_PASSWORD_SUCCESS__TITLE: "Check your e-mail box",
      FRIDAY: "Friday",
      GENDER_FEMALE: "Female",
      GENDER_MALE: "Male",
      GENDER_OTHER: "Other",
      GEOBLOCKING__BLOCKED_ASSET:
        "It seems this particular asset is not available in your region.",
      GEOBLOCKING__POPUP_CANCEL: "Cancel",
      GEOBLOCKING__POPUP_TITLE: "Limited access",
      GEOBLOCKING__TRAILER_UNAVAILABLE: "Trailer unavailable",
      HELP_EMAIL: "HELP_EMAIL",
      INCORRECT_FILE_TYPE: "Incorrect file type",
      INFORMATION_MODAL_PAYMENT: "Open in browser",
      INTRO_PROMO_LIST_TITLE: "New on Better Media Suite",
      INVALID_CRYPTO_CURRENCY_USAGE: "Incorrect use of crypto currency",
      INVALID_EMAIL_NOT_UNIQUE: "User with this e-mail already exists",
      INVALID_FILE_TYPE: "Invalid file type",
      INVALID_FULL_NAME_NOT_UNIQUE: "User with this name already exists",
      INVALID_PASSWORD: "Password is incorrect",
      INVALID_REFRESH_TOKEN: "Invalid refresh token",
      INVALID_TOKEN: "Token is invalid",
      INVALID_USER_OR_PASSWORD: "User or password is incorrect",
      JANUARY: "January",
      JULY: "July",
      JUNE: "June",
      KM12: "INNE COS",
      LANDING_PAGE__DESCRIPTION:
        "Own your own Entertainment. <0>Invest in Weev.</0>",
      LANDING_PAGE__PROVIDE_EMAIL_BELOW:
        "Register now for 5 free credits at launch.",
      LANDING_PAGE__REGISTER: "Register",
      LANDING_PAGE__TITLE: "Discover, Explore & Experience.",
      LANGUAGE_ARABIC: "Arabski",
      LANGUAGE_CHANGED_SUCCESSFULLY: "Language changed successfully!",
      LANGUAGE_CODE_UNKNOWN: "Unknown language code",
      LANGUAGE_ENGLISH: "English",
      LANGUAGE_FRENCH: "French",
      LANGUAGE_GERMAN: "German",
      LANGUAGE_POLISH: "Polish",
      LANGUAGE_SPANISH: "Spanish",
      LINK__BUTTON: "Link",
      LINK__ERROR_MESSAGE:
        "The code you have entered is incorrect. Please try again.",
      LINK__ERROR_SHORT_MESSAGE:
        "The code you have entered is too short. Please fill up all inputs.",
      LINK__SUCCESS_MESSAGE: "Your account has been linked",
      DELETE_CODE__BUTTON: "Delete",
      DELETE_CODE__ERROR_MESSAGE:
        "The code you have entered is incorrect. Please try again.",
      DELETE_CODE__ERROR_SHORT_MESSAGE:
        "The code you have entered is too short. Please fill up all inputs.",
      DELETE_CODE__SUCCESS_MESSAGE: "Your account has been deleted",
      LIST__EPISODE_INITIAL: "E",
      LIST__EPISODE_NUMBER: "Episode {{episodeNumer}}",
      LIST__SEASON_EPISODE_NUMBER: "S{{seasonNumber}}:E{{episodeNumber}}",
      LIST__SEE_ALL: "See all",
      LIST_FOR_YOU: "For You",
      LIST_MY_FAVOURITES_TITLE: "My favourites",
      LIST_PROMO: "Pomo",
      LIST_RECENTLY_WATCHED: "Recently Watched",
      LISTEN__CONTINUE_LISTENING: "Continue listening",
      LISTEN__START_FROM_BEGINNING: "Start from the beginning",
      LIVE__BROADCAST_ENDED: "Broadcast ended",
      LIVE__BROADCAST_HAPPENING_NOW: "Happening now",
      LIVE__BROADCAST_HAS_ENDED: "Broadcast has ended",
      LIVE__BROADCAST_NOT_STARTED: "Broadcast has not started yet",
      LIVE__BROADCAST_WILL_START: "Broadcast will start",
      LOCAL_STORAGE_IS_NOT_ENABLED: "Local storage is not enabled",
      LOGIN__CREATE_ACCOUNT: "Create Your Better Media Suite account.",
      LOGIN__CREATE_ACCOUNT_CREATE_BUTTON: "Create",
      LOGIN__EMAIL_LABEL: "E-mail",
      LOGIN__FORGOT_PASSWORD: "Forgot your password?",
      LOGIN__HIDE_PASSWORD: "Hide the password",
      LOGIN__HINT: "Are you new here?",
      LOGIN__HINT_DESCRIPTION: " your Better Media Suite account.",
      LOGIN__INVALID_DATA_ERROR_MESSAGE: "Invalid email or password",
      LOGIN__PASSWORD_LABEL: "Password",
      LOGIN__SHOW_PASSWORD: "Show the password",
      LOGIN__SIGN_IN__INFO_LABEL:
        "You can also scan the QR code with your mobile camera. Check https://demo.betterdemo.eu for more info.",
      LOGIN__SIGN_IN__PROCEED_LABEL: "How to proceed to log in:",
      LOGIN__SIGN_UP_LABEL: "Don’t have an account yet? ",
      LOGIN__SIGN_UP_LINK: "Sign up",
      LOGIN__SUBMIT: "Sign In",
      LOGIN__TERMS_AND_CONDITIONS:
        "By continuing, you agree to Better Media Suite Conditions of Use and Privacy Notice.",
      LOGIN__TITLE: "Sign In",
      LOGIN_CODE_GO_TO_LINK: "Go to betterdemo.eu/link to log in",
      LOGIN_CODE_INFO: "Go to dev1.bettermedia.tv/link to login",
      LOGIN_CODE_LINK_YOUR_ACCOUNT: "Link your Better Media Suite account",
      LOGIN_GO_TO_LINK: "Go to %s to log in",
      LOGIN_HINT: "Use the web or mobile application to register",
      LOGIN_INFO: "Go to {{LINK}}/link to login",
      LOGIN_INFO_LINK: "dev1.betterdemo.eu",
      LOGIN_LABEL: "Login",
      LOGIN_REQUIRED_ACTION: "Log in",
      LOGIN_REQUIRED_CLOSE: "Close",
      LOGIN_REQUIRED_MESSAGE: "You need to log in to perform this action.",
      LOGOUT__QUESTION: "You are going to logout. Are you sure?",
      MAINTENANCE_SCREEN__BACK_SOON: "We'll be back soon",
      MAINTENANCE_SCREEN__MAINTENANCE:
        "Sorry, we're currently down for maintenance",
      MARCH: "March",
      MAY: "May",
      MEDIA__CAST: "Cast",
      MEDIA__CAST_MEMBERS: "Cast members",
      MEDIA__DIRECTOR: "Director",
      MEDIA__DIRECTORS: "Directors",
      MEDIA__EDITOR: "Editor: {{editorsList}}",
      MEDIA__PRESENTER: "Presenter: {{presentersList}}",
      MEDIA__PRODUCER: "Producer: {{producersList}}",
      MEDIA__SHARE_MESSAGE: 'Seen "{{title}}" on {{appName}} yet?',
      MEDIA__WRITER: "Writer",
      MEDIA__WRITERS: "Writers",
      MEDIA_CATEGORY_DRAWER__ALL: "All Genres & Collections",
      MEDIA_CATEGORY_DRAWER__ALL_GENRES: "All genres",
      MEDIA_CATEGORY_DRAWER__ALL_GENRES_COLLECTIONS: "All Genres & Collections",
      MEDIA_CATEGORY_DRAWER__HEADER: "Genres",
      MEDIA_CREATOR: "",
      MEDIA_CREATOR__CATCHUP: "CATCHUP",
      MEDIA_CREATOR__CATCHUP__ADD_ASSET_PRICE: "Add asset price",
      MEDIA_CREATOR__CATCHUP__ADD_SUBSCRIPTIONS: "Choose subscription",
      MEDIA_CREATOR__CATCHUP__FREE_EVENT: "",
      MEDIA_CREATOR__COMMON_BUTTON_NEXT: "",
      MEDIA_CREATOR__COMMON_BUTTON_PREVIOUS: "",
      MEDIA_CREATOR__CREATE_EVENT__AGE_RESTRICTION_LABEL: "Age restriction",
      MEDIA_CREATOR__CREATE_EVENT__AGE_RESTRICTION_PLACEHOLDER: "",
      MEDIA_CREATOR__CREATE_EVENT__CREATE_BUTTON: "Create",
      MEDIA_CREATOR__CREATE_EVENT__GEOALLOW: "GeoAllow",
      MEDIA_CREATOR__CREATE_EVENT__GEOBLOCK: "GeoBlocked",
      MEDIA_CREATOR__CREATE_EVENT__MODAL_TITLE: "Create new event",
      MEDIA_CREATOR__CREATE_EVENT__SELECT_EVENT_TYPE: "Event type",
      MEDIA_CREATOR__CREATE_EVENT__SELECT_EVENT_TYPE_PLACEHOLDER:
        "Select event type",
      MEDIA_CREATOR__CREATE_EVENT__TITLE_LABEL: "Event title",
      MEDIA_CREATOR__CREATE_EVENT__TITLE_PLACEHOLDER: "Title",
      MEDIA_CREATOR__CREATE_EVENT__UPLOAD_FILE_LABEL: "Upload file",
      MEDIA_CREATOR__CREATORS: "CREATORS",
      MEDIA_CREATOR__CREATORS__ADD_NEXT: "Add next",
      MEDIA_CREATOR__CREATORS__PROFIT_SHARING: "Profit sharing (%)",
      MEDIA_CREATOR__CREATORS__USER: "User",
      MEDIA_CREATOR__CREATORS__USERS: "Users",
      MEDIA_CREATOR__IMAGES: "Images",
      MEDIA_CREATOR__IMAGES__BANNER_IMAGE: "Banner image",
      MEDIA_CREATOR__IMAGES__COVER_PHOTO: "Cover photo",
      MEDIA_CREATOR__IMAGES__DESCRIPTION:
        "Select the media you want to add to the event",
      MEDIA_CREATOR__IMAGES__POSTER_PROMO: "Poster promo",
      MEDIA_CREATOR__INTRO: "INTRO",
      MEDIA_CREATOR__INTRO__DESCRIPTION: "Add intro to the event",
      MEDIA_CREATOR__PAYMENTS: "PAYMENTS",
      MEDIA_CREATOR__PAYMENTS__ADD_ASSET_PRICE: "",
      MEDIA_CREATOR__PAYMENTS__ADD_NEXT_ASSET_PRICE: "Add next asset price",
      MEDIA_CREATOR__PAYMENTS__ADD_SUBSCRIPTIONS: "",
      MEDIA_CREATOR__PAYMENTS__SUBSCRIPTIONS: "",
      MEDIA_CREATOR__SAVE_CATCHUP: "Save Catchup?",
      MEDIA_CREATOR__START_DATE_AFTER_END_DATE:
        "Start date cannot be after end date!",
      MEDIA_CREATOR__SUMMARY: "SUMMARY",
      MEDIA_CREATOR__SUMMARY__CATEGORIES: "Categories",
      MEDIA_CREATOR__SUMMARY__CREATOR: "Creator",
      MEDIA_CREATOR__SUMMARY__DESCRIPTION: "",
      MEDIA_CREATOR__SUMMARY__INPUT: "Input",
      MEDIA_CREATOR__SUMMARY__IS_PREMIUM: "Free event:",
      MEDIA_CREATOR__SUMMARY__OUTPUT: "Output",
      MEDIA_CREATOR__SUMMARY__START_DATE: "Start date:",
      MEDIA_CREATOR__SUMMARY__START_TIME: "Start time:",
      MEDIA_CREATOR__SUMMARY__STREAM_KEY: "Stream key: ",
      MEDIA_CREATOR__SUMMARY__TITLE: "Media Title",
      MEDIA_CREATOR__SUMMARY__URL: "Url: ",
      MEDIA_CREATOR__VIDEO_DETAILS: "VIDEO DETAILS",
      MEDIA_CREATOR__VIDEO_DETAILS__AVAILABLE_TO_ENDS_ON_END_DATE_INFO:
        "Event availability ends at the end date",
      MEDIA_CREATOR__VIDEO_DETAILS__CHOOSE_CATEGORIES: "",
      MEDIA_CREATOR__VIDEO_DETAILS__DESCRIPTION_PLACEHOLDER:
        "Ex: topics, schedule ect.",
      MEDIA_CREATOR__VIDEO_DETAILS__TITLE_PLACEHOLDER: "New event title",
      MEDIA_CREATOR__VIDEO_DETAILS__VIDEO_PROCESSING: "Processing..",
      MEDIA_CREATOR__VIDEO_DETAILS__VIDEO_UPLOADING: "Uploading...",
      MEDIA_CREATOR_HEADER: "MEDIA CREATOR",
      MEDIA_IS_PREMIUM: "Free event?",
      MEDIA_LIST__EMPTY: "This media list hasn't any item",
      MEDIA_NOT_FOUND: "Media not found",
      MEDIA_OFFER_NOT_FOUND: "Media offer not found",
      MEDIA_OFFER_NOT_VALID: "Media offer is not valid for selected media",
      MENU_HOME: "Home",
      MENU_SEARCH: "Search",
      MENU_TRANSMISSIONS: "Broadcasts",
      MESSAGE__INCORRECT_OLD_PASSWORD: "Incorrect old password",
      MESSAGE__INVALID_CARD_DATA: "Invalid card data",
      MESSAGE__SAVE_ERROR: "Error during save",
      MESSAGE__SAVE_SUCCESS: "Successfully saved",
      MISSING_USER_OR_PASSWORD: "E-mail lub hasło jest nieprawidłowe",
      MOBILE__DESKTOP_INFO: "Our app is available on PC only.",
      MOBILE__DOWNLOAD_INFO: "Download our app to your mobile device.",
      MODEL_AVAILABLE_FROM: "Available from",
      MODEL_AVAILABLE_TO: "Available to",
      MODEL_CURRENCY: "Currency",
      MODEL_DATE: "Date",
      MODEL_DESCRIPTION: "Description",
      MODEL_GUID: "Guid",
      MODEL_PAYMENT_KEY: "Payment key",
      MODEL_PAYMENT_METHOD: "Payment method",
      MODEL_PAYMENT_STATUS: "Payment status",
      MODEL_PAYMENT_TYPE: "Payment type",
      MODEL_PRICE: "Price",
      MODEL_STATUS: "Status",
      MODEL_TITLE: "Title",
      MODEL_TYPE_NAME: "Type",
      MODEL_VALIDATION_ERROR: "Model validation error",
      MONDAY: "Monday",
      MONTHPICKER_MONTHS:
        '[{January}, {February},{March},"April","May","June","July","August","September","October","November","December"]',
      MONTHPICKER_NOVEMBER: "November",
      MY_CONSENT:
        'ENG Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.  The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.',
      MY_CONSENTS_BUTTON__ACCEPTED: "Accepted",
      MY_CONSENTS_BUTTON__TO_ACCEPT: "Accept",
      MY_CONSENTS_SETTINGS_SCREEN__TITLE: "My consents",
      MY_ORDERS__BUTTON_BUY_AGAIN: "Buy again",
      MY_ORDERS__BUTTON_CANCEL: "Cancel",
      MY_ORDERS__BUTTON_CHANGE_CARD: "Change card",
      MY_ORDERS__BUTTON_REACTIVATE: "Reactivate",
      MY_ORDERS__BUTTON_SEE_ALL: "See all",
      MY_ORDERS__BUTTON_SHOW_LESS: "Show less",
      MY_ORDERS__CANCEL: "Cancel",
      MY_ORDERS__CHANGE_PAYMENT: "Change your card",
      MY_ORDERS__COLUMN_DATE: "Date",
      MY_ORDERS__COLUMN_PAYMENT_TYPE: "Payment type",
      MY_ORDERS__COLUMN_STATUS: "Status",
      MY_ORDERS__COLUMN_TITLE: "Title",
      MY_ORDERS__CONFIRM_CANCEL: "Do you want to cancel selected subscription?",
      MY_ORDERS__CONFIRM_PAYMENT_CHANGE:
        "Do you want to change card for selected subscription?",
      MY_ORDERS__CONFIRM_REACTIVATION:
        "Are you sure you want to reactivate selected subscription?",
      MY_ORDERS__LABEL_AVAILABLE_FROM: "Available from",
      MY_ORDERS__LABEL_AVAILABLE_UNTIL: "Available until",
      MY_ORDERS__LABEL_CURRENCY: "Currency",
      MY_ORDERS__LABEL_DATE: "Date",
      MY_ORDERS__LABEL_MEDIA_TYPE: "Media type",
      MY_ORDERS__LABEL_PAYMENT_ID: "Payment ID",
      MY_ORDERS__LABEL_PAYMENT_PROVIDER: "Payment provider",
      MY_ORDERS__LABEL_PRICE: "Price",
      MY_ORDERS__LABEL_STATUS: "Status",
      MY_ORDERS__NO_ELEMENTS: "No orders",
      MY_ORDERS__REACTIVATE: "Reactivate",
      MY_ORDERS__SECTION_BILLINGS: "Billings",
      MY_ORDERS__SECTION_DETAILS: "Details",
      MY_ORDERS__STATUS_ACTIVE: "Active",
      MY_ORDERS__STATUS_INACTIVE: "Inactive",
      MY_ORDERS__TAB_ALL: "All",
      MY_ORDERS__TITLE: "My Orders",
      MY_ORDERS__TYPE_ONETIME: "One time",
      MY_ORDERS__TYPE_RENTAL: "Rent for 48h",
      MY_ORDERS__TYPE_SUBSCRIPTION: "Subscription",
      NAME_LENGTH_VALIDATION_MESSAGE:
        "Enter your name (minimum 3 characters, maximum 150 characters)",
      NAME_PATTERN_VALIDATION_MESSAGE:
        "Name cannot end with a special character",
      NO_DATA_TO_PROCEED: "No data to proceed the operation",
      NO_MORE_CONTENT: "No more content to show",
      NO_PERMISSION_DELETE_DATA: "You are not permitted to delete data",
      NO_PERMISSION_READ_DATA: "You are not permitted to read data",
      NO_PERMISSION_WRITE_DATA: "You are not permitted to write data",
      NO_PERMISSIONS: "No permission",
      NOTIFICATION_PRIORITY_HIGH: "High",
      NOTIFICATION_PRIORITY_NORMAL: "Normal",
      NOTIFICATION_PROVIDER_EMAIL: "E-mail message",
      NOTIFICATION_PROVIDER_FIREBASE: "Firebase",
      NOTIFICATION_PROVIDER_INSTANT: "Instant",
      NOTIFICATION_PROVIDER_SIGNALR: "SignalR",
      NOTIFICATION_PROVIDER_SMS: "SMS message",
      NOTIFICATION_RECIPIENT_TYPE_ALL: "To all",
      NOTIFICATION_RECIPIENT_TYPE_BUSINESS_UNITS_COUNTRIES:
        "To business units and countries",
      NOTIFICATION_RECIPIENT_TYPE_SELECTED_USERS: "Selected users",
      NOTIFICATION_STATUS_DRAFT: "Draft",
      NOTIFICATION_STATUS_ERROR: "Error",
      NOTIFICATION_STATUS_IN_PROGRESS: "In progress",
      NOTIFICATION_STATUS_SCHEDULED: "Scheduled",
      NOTIFICATION_STATUS_SENT: "Sent",
      NOTIFICATION_TYPE_SYSTEM: "System",
      NOTIFICATION_TYPE_USER: "User's",
      NOVEMBER: "November",
      OBJECT_NOT_FOUND: "Object not found",
      OCTOBER: "October",
      OFFLINE_MODE__NO_INTERNET_AND_CONFIG_INFO:
        "No internet connection available. Please log in first time with an online mode. Connect to the Internet and try again.",
      OFFLINE_MODE__NO_INTERNET_CONNECTION_INFO:
        "No internet connection available",
      ONE_TIME: "One time buy",
      ONETIME: "OneTime",
      ONLY_OWNER_CAN_CHANGE_OBJECT: "Only owner can change this data",
      ONLY_OWNER_CAN_CHANGE_PROGRESS_STATUS:
        "Only owner can change progress status",
      ONLY_OWNER_CAN_VIEW_OBJECT: "Only owner can view this data",
      ONLY_VIDEO_PREMIERE_CAN_BE_CONTROLLED:
        "Only video premiere can be controlled",
      PACKAGE_DETAILS__AVAILABLE_IN: "Available in",
      PACKAGE_DETAILS__BUTTON_BUY: "Buy",
      PACKAGE_DETAILS__BUTTON_RENT: "Rent",
      PARENT_OBJECT_NOT_FOUND: "Parent object not found",
      PASSWORD_CHANGED: "Password changed successfully",
      PASSWORD_EXPIRED: "Password expired",
      PASSWORD_FORM__CONFIRM_NEW_PASSWORD_LABEL: "Repeat new password",
      PASSWORD_FORM__CONFIRM_NEW_PASSWORD_PLACEHOLDER: "At least 8 characters",
      PASSWORD_FORM__CURRENT_PASSWORD_LABEL: "Old password",
      PASSWORD_FORM__CURRENT_PASSWORD_PLACEHOLDER:
        "Enter your current password",
      PASSWORD_FORM__NEW_PASSWORD_LABEL: "New password",
      PASSWORD_FORM__NEW_PASSWORD_PLACEHOLDER: "At least 8 characters",
      PASSWORD_FORM__SENT: "Sent",
      PASSWORD_FORM__SUBMIT: "Change password",
      PASSWORD_LENGTH_VALIDATION_MESSAGE: "At least 8 characters.",
      PASSWORD_MATCH_VALIDATION_MESSAGE:
        "Password and confirm password do not match.",
      PASSWORD_NOT_CHANGED: "Failed to change password",
      PASSWORD_PATTERN_VALIDATION_MESSAGE:
        "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character.",
      PASSWORD_VALIDATION_ERROR_CONTAINS_EMAIL:
        "Valid password doesn't contain e-mail address.",
      PASSWORD_VALIDATION_ERROR_CONTAINS_PHONE_NUMBER:
        "Valid password doesn't contain phone number.",
      PASSWORD_VALIDATION_ERROR_CONTAINS_USERNAME:
        "Valid password doesn't contain username.",
      PASSWORD_VALIDATION_ERROR_MAX_LENGTH:
        "Valid password has max {VALUE} characters.",
      PASSWORD_VALIDATION_ERROR_REQUIRE_LOWERCASE:
        "Valid password contains at least one lowercase letter.",
      PASSWORD_VALIDATION_ERROR_REQUIRE_NON_ALPHANUMERIC:
        "Valid password contains at least one non-alphanumeric character.",
      PASSWORD_VALIDATION_ERROR_REQUIRE_UPPERCASE:
        "Valid password contains at least one uppercase letter.",
      PASSWORD_VALIDATION_ERROR_REQUIRE_UPPERCASE_OR_LOWERCASE:
        "Valid password contains at least one letter.",
      PASSWORD_VALIDATION_ERROR_REQUIRED_LENGTH:
        "Valid password has minimum {VALUE} characters.",
      PASSWORD_VALIDATION_ERROR_REQUIRED_UNIQUE_CHARS:
        "Valid password has at least {VALUE} unique characters.",
      PASSWORD_VALIDATION_ERROR_WHITESPACE_CHARS:
        "Valid password doesn't contain whitespace characters.",
      PASSWORDS_DONT_MATCH: "Passwords are not the same",
      PAYMANTS_NO_DATA_IMPORTED:
        "No payment have been imported. Try again later.",
      PAYMENT__BUTTON_IN_PROGRESS: "In progress",
      PAYMENT__BUTTON_NEXT: "Next",
      PAYMENT__BUTTON_PAY: "Pay now",
      PAYMENT__BUY_ERROR_MESSAGE: "There was an error while buying media",
      PAYMENT__CANCEL_MESSAGE: "Payment has been canceled",
      PAYMENT__HEADER_TITLE: "Payment",
      PAYMENT__MORE_OFFERS:
        "or check <underline>more purchase options</underline>.",
      PAYMENT__MORE_OFFERS_CHECK: "or check",
      PAYMENT__MORE_OFFERS_MORE: "more purchase options",
      PAYMENT__NO_OFFERS: "It seems there is no way to buy this right now.",
      PAYMENT__NO_PRICE_IN_YOUR_CURRENCY:
        "It seems there is no price in your preferred currency.",
      PAYMENT__OFFERS_STEP: "Access type",
      PAYMENT__OFFERS_TITLE: "Your purchase options",
      PAYMENT__PAY_BUTTON: "Pay",
      PAYMENT__PAYMENT_STEP: "Payment",
      PAYMENT__PROVIDER_NOT_READY:
        "Payment provider does not ready or unavailable. Try again.",
      PAYMENT__SUCCESS_MESSAGE: "Payment has been completed successfully!",
      PAYMENT__SUMMARY_STEP: "Summary",
      PAYMENT_CANCEL__BUTTON_GO_TO_DETAILS: "See details",
      PAYMENT_CANCEL__BUTTON_REPAY: "Try again",
      PAYMENT_CANCEL__SUBTITLE:
        "Your payment has been canceled. Please repeat your payment.",
      PAYMENT_CANCEL__TITLE: "Payment canceled",
      PAYMENT_CHANGE_CANCEL__SUBTITLE:
        "User's payment method change for subscription has been cancelled",
      PAYMENT_CHANGE_CANCEL__TITLE: "Payment method change cancelled",
      PAYMENT_CHANGE_SUCCESS__SUBTITLE:
        "User's payment method change for subscription has been changed",
      PAYMENT_CHANGE_SUCCESS__TITLE: "Payment method change success",
      PAYMENT_CONFIRMATION_SCREEN__AFTER_PURCHASE_AMOUNT_COINS:
        "Amount of Weev Coins after purchase:",
      PAYMENT_CONFIRMATION_SCREEN__CANCEL_PURCHASE: "Cancel payment",
      PAYMENT_CONFIRMATION_SCREEN__CANCEL_PURCHASE_QUESTION:
        "Are you sure you want to cancel the purchase?",
      PAYMENT_CONFIRMATION_SCREEN__CONFIRM_PAYMENT: "Confirm payment",
      PAYMENT_CONFIRMATION_SCREEN__CURRENT_AMOUNT_COINS:
        "Current amount of Coins:",
      PAYMENT_CONFIRMATION_SCREEN__ITEM: "Item",
      PAYMENT_CONFIRMATION_SCREEN__ORDER_DETAILS: "Yout order details:",
      PAYMENT_CONFIRMATION_SCREEN__PAYMENT_TYPE: "Type",
      PAYMENT_CONFIRMATION_SCREEN__QUANTITY: "Qty (Weev Coins)",
      PAYMENT_DETAILS__TITLE: "Payment details",
      PAYMENT_NOT_NEEDED: "You don't need pay",
      PAYMENT_PROCESSING__BUTTON_GO_TO_DETAILS: "See details",
      PAYMENT_PROCESSING__BUTTON_REFRESH: "Check current status",
      PAYMENT_PROCESSING__SUBTITLE:
        "Your payment is processing. It may take up to 5 minutes until we get the final status. After that, you can repeat the payment.",
      PAYMENT_PROCESSING__TIMER_UNIT: "mins",
      PAYMENT_PROCESSING__TITLE: "Payment processing",
      PAYMENT_PROVIDER_NOT_AVAILABLE: "No payment provider available",
      PAYMENT_STARTED_OR_COMPLETED:
        "The previous transaction is being processed, please wait a few minutes",
      PAYMENT_STATUS_CANCELLED: "Cancelled",
      PAYMENT_STATUS_COMPLETED: "Completed",
      PAYMENT_STATUS_CREATED: "Created",
      PAYMENT_STATUS_FAILED: "Failed",
      PAYMENT_STATUS_GRANTED: "Granted",
      PAYMENT_STATUS_NOT_EXISTS: "Not exists",
      PAYMENT_STATUS_NOTIFICATION: "Payment status notification",
      PAYMENT_STATUS_PROCESSING: "Processing",
      PAYMENT_SUCCESS__BUTTON_GO_TO_DETAILS: "See details",
      PAYMENT_SUCCESS__BUTTON_PLAY: "Play now",
      PAYMENT_SUCCESS__MESSAGE: "Now you can play your purchased content.",
      PAYMENT_SUCCESS__SUBTITLE: "Thank you for your order.",
      PAYMENT_SUCCESS__TITLE: "Good job",
      PAYMENT_TYPE_GRAND: "Grand",
      PAYMENT_TYPE_ONETIME: "OneTime",
      PAYMENT_TYPE_SUBSCRIPTION: "Subscription",
      PAYMENTSCREEN__UPGRADE_COINS_BUTTON: "Upgrade my wallet",
      PAYMENTSCREEN__UPGRADE_COINS_DESCRIPTION:
        "You’re running out of the Weev Coins. Upgrade them to continue this transaction and to have possibilites to watch the favourite movies.",
      PAYMENTSCREEN__UPGRADE_COINS_TITLE: "Upgrade your Coins",
      PERSON_IN_ASSET_ROLE_CAST: "Cast",
      PERSON_IN_ASSET_ROLE_DIRECTOR: "Director",
      PERSON_IN_ASSET_ROLE_WRITER: "Writer",
      PHONE_COUNTRY_CODE_HINT: "Area code",
      PHONE_COUNTRY_CODE_INVALID: "Invalid code",
      PHONE_COUNTRY_CODE_VALIDATION_MESSAGE:
        "Country code with '+' sign is required (e.g. +48)",
      PHONE_LENGTH_TOO_SHORT_VALIDATION_MESSAGE: "Phone number is too short",
      PHONE_LENGTH_VALIDATION_MESSAGE: "Maximum 15 characters",
      PHONE_PATTERN_VALIDATION_MESSAGE: "Phone number is invalid.",
      PLAYER_AUTO_QUALITY_TRACK: "Auto",
      PLAYER_BUTTON_CONTINUE_WATCHING_FROM_START: "From the beginning",
      PLAYER_BUTTON_CONTINUE_WATCHING_RESUME: "Resume",
      PLAYER_BUTTON_ERROR_CLOSE_PLAYER: "Close player",
      PLAYER_BUTTON_ERROR_RETRY: "Retry",
      PLAYER_BUTTON_NEXT_EPISODE: "Next episode",
      PLAYER_BUTTON_SKIP_AD: "Skip ad",
      PLAYER_CONTINUE_WATCHING_HEADER: "Continue watching?",
      PLAYER_DEFAULT_PLAYBACK_RATE: "Normal",
      PLAYER_DISABLED_SUBTITLES_TRACK: "Disabled",
      PLAYER_ERROR_CODE: "Error code: {{code}}",
      PLAYER_ERROR_MEDIA_ERR_ABORTED: "The video download was cancelled.",
      PLAYER_ERROR_MEDIA_ERR_DECODE:
        "The video is bad or in a format that cannot be played on your browser.",
      PLAYER_ERROR_MEDIA_ERR_ENCRYPTED:
        "The video you are trying to watch is encrypted and we do not know how to decrypt it.",
      PLAYER_ERROR_MEDIA_ERR_NETWORK:
        "The video connection was lost, please confirm you are connected to the internet.",
      PLAYER_ERROR_MEDIA_ERR_SRC_NOT_SUPPORTED:
        "This video is either unavailable or not supported in this browser.",
      PLAYER_ERROR_MEDIA_ERR_UNKNOWN:
        "An unanticipated problem was encountered, check back soon and try again.",
      PLAYER_MULTIPLE_ADS_INDICATOR:
        "Ad {{position}} of {{totalAds}} | {{time}} | {{title}}",
      PLAYER_SEASONS: "List of episodes",
      PLAYER_SETTINGS__MENU_SOUNDTRACK_OPTION_NO_SOUNDTRACK: "No soundtrack",
      PLAYER_SETTINGS_AUDIO: "Language",
      PLAYER_SETTINGS_PLAYBACK_RATE: "Speed",
      PLAYER_SETTINGS_QUALITY: "Quality",
      PLAYER_SETTINGS_SUBTITLES: "Subtitles",
      PLAYER_SINGLE_AD_INDICATOR: "Advertisement {{time}} | {{title}}",
      PLAYER_TOOLTIP_AD: "Advertisement",
      PLAYER_TOOLTIP_CLOSE_PLAYER: "Close player",
      PLAYER_TOOLTIP_CURRENTLY_LIVE: "Currently live",
      PLAYER_TOOLTIP_ENTER_FULLSCREEN: "Full screen",
      PLAYER_TOOLTIP_GO_TO_LIVE: "Go live",
      PLAYER_TOOLTIP_LEAVE_FULLSCREEN: "Close full screen",
      PLAYER_TOOLTIP_NEXT_EPISODE: "Next episode",
      PLAYER_TOOLTIP_PAUSE: "Pause",
      PLAYER_TOOLTIP_PLAY: "Play",
      PLAYER_TOOLTIP_SEEK_BACK: "Seek back",
      PLAYER_TOOLTIP_SEEK_FORWARD: "Seek forward",
      PLAYER_TOOLTIP_SETTINGS: "Settings",
      PLOAD_FILE_DESCRIPTION__VIDEO_INTRO:
        "It’s recommended that you use a video with at least HD resolution and a size of 200 MB or less. Use MP4 or another file (to be specified).",
      PREMIUM_ASSET_HAS_NO_CURRENCY_DEFINED:
        "Premium asset has no currency defined",
      PREMIUM_ASSET_HAS_NO_PRICE_DEFINED: "Premium asset has no price defined",
      PREMIUM_EVENT_HAS_NO_CURRENCY_DEFINED:
        "Premium event has no currency defined",
      PREMIUM_EVENT_HAS_NO_PRICE_DEFINED: "Premium event has no price defined",
      PRICE: "Price",
      PRICE_FOR_PERIOD_ALREADY_DEFINED: "Price for period is already defined",
      PRICE_LESS_THAN_MINIMAL: "Price cannot be less than specified minimal",
      PRICES_FOR_FREE_CONTENT_NOT_ALLOWED:
        "Defining prices for free content is not allowed",
      PRIVACY_POLICY: "Privacy Policy",
      PRIVACY_POLICY_SETTINGS_SCREEN__TITLE: "Privacy policy",
      PROFILE_CHANNELS_YOU_LIKE: "Channels You Like",
      PROFILE_CONTENT_YOU_LIKE: "Content You Like",
      PROFILE_DATE_OF_BIRTH: "Date Of Birth",
      PROFILE_FAVORITE_CONTENTS: "Favorite Contents",
      PROFILE_FULL_NAME: "Full Name",
      PROFILE_PERSONAL_DATA: "Personal Data",
      PROFIT_SHARING_NOT_100: "Profits of creators must sum up to 100 percent!",
      PURCHASE_PERIOD_TIME_PLACEHOLDER: "Select period",
      PURCHASE_PERIOD_TYPE: "",
      QR_CODE_URL: "https://dev1.bettermedia.tv/link",
      RATING_CATEGORY_CONTENT: "Content",
      RATING_CATEGORY_PLAYER: "Player",
      RATING_FORM_CATEGORIES: "Where can we improve?",
      RATING_FORM_SUBMIT: "Submit",
      RATING_FORM_SUCCESS: "Thank you for your opinion!",
      RATING_FORM_SUGGESTION: "Any other suggestions?",
      RATING_FORM_SUGGESTION_PLACEHOLDER: "Enter your answer",
      RATING_STORE_ERROR: "Something went wrong with shop review.",
      RATING_TITLE: "Rate your experience with us!",
      RECORD_NOT_FOUND: "Record not found",
      REFRESH_CODE: "Refresh code",
      REGISTER__AND: "and",
      REGISTER__CONDITIONS_OF_US: "Conditions of Use",
      REGISTER__CONFIRM_AND_LOGIN: "Confirm registration",
      REGISTER__CONFIRM_TEXT: "Sign in to confirm registration",
      REGISTER__CONFIRMATION_ACTIVATED: "Your account has been activated!",
      REGISTER__CONFIRMATION_BACK_TO_HOME: "Back to home",
      REGISTER__CONFIRMATION_CHECK_INBOX: "Check your inbox",
      REGISTER__CONFIRMATION_CONFIRMATION_BUTTON: "Ok",
      REGISTER__CONFIRMATION_CONGRATULATIONS: "Congratulations!",
      REGISTER__CONFIRMATION_INFORMATION:
        "We have sent an email with an account  activation link to your email address.",
      REGISTER__CONFIRMATION_INFORMATION_CHEC:
        "Please click the activation link we sent to your email:",
      REGISTER__CONFIRMATION_LINK_EXPIRED:
        "it’s looks like, your link is expired. Please try again.",
      REGISTER__CONFIRMATION_MAIL_BOX: "Go to my mailbox",
      REGISTER__CONFIRMATION_SEND_LINK: "Send link again",
      REGISTER__CONFIRMATION_SEND_LINK_IN: "Send link again in",
      REGISTER__CONFIRMATION_SEND_NEW_LINK: "Send new link",
      REGISTER__CONFIRMATION_SPAM: "(Check the spam and trash folder too)",
      REGISTER__CONFIRMATION_SUBTITLE:
        "There is only one step left to create an account.",
      REGISTER__CONFIRMATION_TITLE: "Great!",
      REGISTER__CONFIRMATION_UNFORTUNATELY: "Unfortunately,",
      REGISTER__CONTINUING: "By continuing, you agree to The Beeter",
      REGISTER__EMAIL: "E-mail",
      REGISTER__EMAIL_LABEL: "E-mail",
      REGISTER__HINT:
        "<0>You already have an account? </0><1><0>Sign in</0></1>",
      REGISTER__NAME_LABEL: "Name",
      REGISTER__PASSWORD_CONFIRM_LABEL: "Confirm password",
      REGISTER__PASSWORD_LABEL: "Password",
      REGISTER__PRIVACY_NOTICE: "Privacy notice",
      REGISTER__RE_PASSWORD_LABEL: "Re-enter password",
      REGISTER__SIGN_IN:
        "<0>You already have an account? </0><1><0>Sign in</0></1>",
      REGISTER__SIGN_IN_LABEL: "You already have an account?",
      REGISTER__SIGN_IN_LINK: "Sign in",
      REGISTER__SUBMIT: "Create",
      REGISTER__SUBTITLE:
        "Register to enjoy free access to selected content and gain the opportunity to purchase access to paid packages.",
      REGISTER__TERMS_AND_CONDITIONS:
        "<0>By creating an account, you agree to Better Media Suite's </0><1></1><2><0>Conditions of Use</0></2><3> and </3><4><0>Privacy Notice</0></4>.",
      REGISTER__TITLE: "Create account",
      REGISTER__USERNAME: "Username",
      REGISTER_SUCCESS__CONFIRM: "OK",
      REGISTER_SUCCESS__HINT:
        "Can't find the message in your inbox? Check spam folder.",
      REGISTER_SUCCESS__MESSAGE:
        "We have sent an e-mail with an account activation link to your e-mail address.",
      REGISTER_SUCCESS__SUBTITLE:
        "There is only one step left to create an account.",
      REGISTER_SUCCESS__TITLE: "Great!",
      REGULATIONS_SETTINGS_SCREEN__TITLE: "Regulations",
      REMOVE_FAILED_RESOURCE: "Do you want remove failed resource from list?",
      RENT_30_DAYS: "Rent for 30 days",
      RENT_48H: "Rent for 48h",
      REQUIRED_VALIDATION_MESSAGE: "This field is required.",
      RESET_PASSWORD__PASSWORD_LABEL: "Password",
      RESET_PASSWORD__RE_PASSWORD_LABEL: "Re-enter password",
      RESET_PASSWORD__SUBMIT: "Reset",
      RESET_PASSWORD__SUBTITLE:
        "Please enter your requested data below to proceed with recovering/resetting your password.",
      RESET_PASSWORD__TITLE: "Reset password",
      RESET_PASSWORD__TOKEN_EXPIRED: "The token has expired",
      RESET_PASSWORD_PLEASE_TEXT:
        "Please confirm reset password in application.",
      RESOURCE_KEY_UNKNOWN: "Unknown resource key",
      RESOURCES_IMPORT_WARNING: "Resources import warning.",
      RESOURCES_IMPORT_WARNING_DETAILS: "Unknown languages",
      RESOURCES_IMPORT_WARNING_DETAILS_SUMMARY:
        "Resources with above errors were not imported.",
      RESOURCES_IMPORT_WARNING_DETAILS_UNKNOWN_APPLICATIONS:
        "Unknown applications: ",
      RESOURCES_IMPORT_WARNING_DETAILS_UNKNOWN_LANGUAGES: "Unknown languages: ",
      RESOURCES_IMPORT_WARNING_DETAILS_UNKNOWN_PLATFORMS: "Unknown platforms: ",
      ROLE: "Role",
      ROLE_PLACEHOLDER: "",
      SATURDAY: "Saturday",
      SCAN_QR_CODE: "Scan QR code",
      SEARCH__HEADER: "Search results",
      SEARCH__INPUT_PLACEHOLDER: "Title, Description",
      SEARCH__NO_RESULTS: "No search results",
      SEARCH__NO_RESULTS_MESSAGE:
        "Sorry, we didn't find any results matching this search.",
      SEARCH__RECOMMENDATION_LIST: "Recommendation list",
      SEASON: "Season",
      SEASONS: "Seasons",
      SELECTED_BUSINESS_UNIT_AND_COUNTRY_NOT_EXISTS:
        "Selected BusinessUnit and Country not exists",
      SEPTEMBER: "September",
      SETTINGS__CONSENT_CHECK_WEBSITE:
        "Check https://demo.betterdemo.eu for more info",
      SETTINGS__CONSENT_SCAN_QRCODE_LABEL:
        "You can also scan the QR code with your mobile camera ",
      SETTINGS__CONSENT_VISIT_WEBSITE: "Visit the website and read the consent",
      SETTINGS__DATA_PROCESSING_FOR_CONTACT_CONSENT:
        "Consent to <1><0>data processing for contact purposes.</0></1>",
      SETTINGS__DATA_PROCESSING_FOR_MARKETING_CONSENT:
        "Consent to <1><0>data processing for marketing purposes.</0></1>",
      SETTINGS__EDIT_PROFILE: "Edit Profile",
      SETTINGS__INFORMATION_CLAUSE_CONSENT:
        "I confirm that I have read the <1><0>information clause of Better Media Suite (Client Company).</0></1>",
      SETTINGS__TERMS_CONSENT: "Terms of Use",
      SETTINGS_LANGUAGES__TITLE: "Languages",
      SETTINGS_PREFERENCES__CURRENCY_PREFERENCE: "Preferred currency",
      SETTINGS_PREFERENCES__GET_CURRENCIES_FAILED:
        "Downloadint user currency prefenreces failed.",
      SETTINGS_PREFERENCES__GET_USER_SETTINGS_FAILED:
        "Downloading user settings failed.",
      SETTINGS_PREFERENCES__LANGUAGE: "Language",
      SETTINGS_PROFILE__AVATAR_INVALID_FORMAT: "Invalid avatar file format",
      SETTINGS_PROFILE__CONTACTS_DESCRIPTION:
        "Contact details are needed to receive notification from the service, and interaction with customer support.",
      SETTINGS_PROFILE__CONTACTS_TITLE: "Contacts",
      SETTINGS_PROFILE__GET_PROFILE_FAILED:
        "Could not get user profile right now. Please try again later.",
      SETTINGS_PROFILE__PASSWORD_DESCRIPTION:
        "You can easily change your  password at any time if you know your existing password. If you have forgotten your password, <1><0>learn how to reset it.</0></1>",
      SETTINGS_PROFILE__PASSWORD_DESCRIPTION_RESET: "learn how to reset it.",
      SETTINGS_PROFILE__PASSWORD_DESCRIPTION_TEXT:
        "You can easily change your password at any time if you know your existing password. If you have forgotten your password,",
      SETTINGS_PROFILE__PASSWORD_TITLE: "Password",
      SETTINGS_PROFILE_VISIT_WEBSITE_TO_CHANGE:
        "To change profile settings visit website application",
      SETTINGS_SCREEN__APP_VERSION: "App version",
      SETTINGS_SCREEN__APPLICATION_VERSION_NUMBER: "Application version number",
      SETTINGS_SCREEN__APPLICATION_VERSION_NUMBER_BACKEND:
        "Application version (backend)",
      SETTINGS_SCREEN__APPLICATION_VERSION_NUMBER_FRONTEND:
        "Application version (frontend)",
      SETTINGS_SCREEN__DEVICE_IP: "Device IP",
      SETTINGS_SCREEN__DEVICE_MAC: "Device MAC",
      SETTINGS_SCREEN__DEVICE_MODEL: "Device model",
      SETTINGS_SCREEN__FIRMWARE_VERSION: "Firmware version",
      SETTINGS_SCREEN__LANGUAGE: "Language",
      SETTINGS_SCREEN__LOGIN_EMAIL: "Sign in with e-mail",
      SETTINGS_SCREEN__LOGO: "Logo",
      SETTINGS_SCREEN__OPTION_ABOUT_APPLICATION: "About app",
      SETTINGS_SCREEN__OPTION_ABOUT_APPLICATION_TITLE: "About app",
      SETTINGS_SCREEN__OPTION_ABOUT_TITLE: "About",
      SETTINGS_SCREEN__OPTION_LANGUAGE_TITLE: "Language",
      SETTINGS_SCREEN__OPTION_MY_CONSENTS_TITLE: "My consents",
      SETTINGS_SCREEN__OPTION_PRIVACY_POLICY_TITLE: "Privacy policy",
      SETTINGS_SCREEN__PLATFORM: "Platform",
      SETTINGS_SCREEN__PLAYER_VERSION: "Player version",
      SETTINGS_SCREEN__SCREEN_RESOLUTION: "Screen resolution",
      SETTINGS_SCREEN__TIME_AND_DATE: "Time and date",
      SETTINGS_SCREEN__TITLE: "Settings",
      SETTINGS_SCREEN__VERSION_BACKEND: "Backend version",
      SETTINGS_SCREEN__VERSION_FRONTEND: "Frontend version",
      SETTINGS_SCREEN__WEBSITE: "Website",
      SETTINGS_TITLE: "Settings",
      SHOW_ALL: "Show all",
      SHOW_LESS: "Show less",
      SIGN_IN: "Sign In",
      SIGN_IN_CODE: "Sign in with code",
      SIGN_IN_TO_CONTINUE: "Sign in to continue",
      SMS_CONFIRMATION: "Confirm your phone number in application.",
      SMS_WARNING_PHONE_NUMBER_EXISTS:
        "Somebody tried to register on this phone number. If you cannot log in try to use 'Forgot password'.",
      SOMETHING_WENT_WRONG: "Something went wrong",
      STREAM_CHANNEL_NOT_FOUND: "Stream channel not found",
      STREAM_CHANNEL_PROVIDER_NOT_FOUND: "Stream channel provider not found",
      SUBSCRIPTION: "Subscription",
      SUBSCRIPTION_PURCHASE_NOTIFICATION: "Payment confirmation",
      SUBSCRIPTION_PURCHASE_NOTIFICATION_CONTENT: "Subscription until ",
      SUBSCRIPTION_STATUS_ACTIVE: "Active",
      SUBSCRIPTION_STATUS_INACTIVE: "Inactive",
      SUNDAY: "Sunday",
      TAKE_CONTROL_FIRST: "Take control first",
      TEST_KEY: "TEST KEY ENG",
      THIS_USER_SUBSCRIPTION_IS_NOT_ACTIVE:
        "This user subscription is not active",
      THURSDAY: "Thursday",
      TIMEOUT__MAX_ATTEMPTS:
        "Roku has reached max attempts for search phrase. Try again. ",
      TOKEN_EXPIRED: "Token has expired",
      TOKEN_PAYMENT__ADDED_TO_WALLET_INFO: "Tokens added to wallet",
      TOKEN_PAYMENT__HOW_IT_WORKS: "How it works?",
      TOKEN_PAYMENT__REGISTER_TO_WATCH:
        "To start watching, just <0>register</0> and follow these simple steps:",
      TOKEN_PAYMENT__TOKEN_NOT_EXPIRE_INFO:
        "Tokens never expire top-up anytime",
      TOKEN_PAYMENT__TOKEN_RENEVAL_INFO: "Tokens added each month upon reneval",
      TOKEN_PAYMENT__TOKENS_EARNING_INFO:
        "Subscribers can earn while watching and share tokens with friends & family",
      TOKEN_PAYMENT__USE_TOKENS_INFO:
        "Use tokens to watch films / special offers",
      TOKEN_VALID_INFO: "Token is valid for",
      TOMORROW: "Tomorrow",
      TRACK_PLAYER_OFFLINE: "You lose your Internet connection",
      TRACK_PLAYER_OFFLINE_MESSAGE:
        "Download the file to listen to in offline mode ",
      TRANSCODER_MANAGER_DRM_PROVIDER_NOT_FOUND: "DRM provider not found",
      TRANSCODER_MANAGER_TRANSCODING_PROVIDER_NOT_FOUND:
        "Transcoding provider not found",
      TRANSLACJA_LISTA_KOMENTARZE_TYGODNIA: "Comments of the week..",
      TUESDAY: "Tuesday",
      TYPE_DESCRIPTION: "",
      UNAUTHORIZED: "Unauthorized",
      UNKNOWN_ERROR: "Unknown error",
      UPLOAD_ASSET_CONTENT_INVALID_TYPE: "Invalid type of file.",
      UPLOAD_FILE_DESCRIPTION__COVER:
        "It’s recommended to use a picture that’s at least 360 x 480 pixels (Ratio 3x4) and 4MB or less. Use a JPG or PNG file.",
      UPLOAD_FILE_DESCRIPTION__DEFAULT:
        "It’s recommended to use a picture that’s at least 00 X 00 pixels (Ratio 0x0) and 0MB or less. Use a JPG or PNG file.",
      UPLOAD_FILE_DESCRIPTION__FRAME:
        "It’s recommended to use a picture that’s at least 720 x 405 pixels (Ratio 16x9) and 4MB or less. Use a JPG or PNG file.",
      UPLOAD_FILE_DESCRIPTION__HIGHLIGHTS:
        "It’s recommended to use a picture that’s at least 1280 x 720 pixels (Ratio 16x9) and 4MB or less. Use a JPG or PNG file.",
      UPLOAD_FILE_DESCRIPTION__VIDEO_INTRO:
        "It’s recommended that you use a video with at least HD resolution and a size of 200 MB or less. Use MP4 or another file (to be specified).",
      UPLOAD_FILE_DESCRIPTION__VIDEO_UPLOAD:
        "It’s recommended that you use a video with at least Full HD or 4K resolution and a size of 2000 MB or less. Use MP4 or another file (to be specified).",
      UPLOAD_IMAGE_LABEL: "Upload image or drag and drop",
      UPLOAD_VIDEO_LABEL: "Upload video or drag and drop",
      USER_BROWSE__NO_SELECTED_USER_PLACEHOLDER: "",
      USER_BROWSE__USER_PLACEHOLDER: "Select user",
      USER_BROWSE_TITLE: "Select user",
      USER_BUY_ON_MOBILE_OR_WEB:
        "Please login on mobile or web to purchase a product",
      USER_CAN_CREATE_ONLY_FREE: "User can create only free",
      USER_DOES_NOT_HAVE_PURCHASED_THIS_SUBSCRIPTION:
        "User does not have purchased this subscription",
      USER_HAS_NO_PHONE_NUMBER: "User has no phone number",
      USER_HAVE_TO_PURCHASE_A_SUBSCRIPTION_FIRST:
        "User have to purchase a subscription first",
      USER_IN_ASSET_EVENT_NAME: "Name",
      USER_IN_ASSET_EVENT_ROLE_ATTENDEE: "Attendee",
      USER_IN_ASSET_EVENT_ROLE_CREATOR: "Creator",
      USER_IN_ASSET_EVENT_ROLE_MODERATOR: "Moderator",
      USER_IN_ASSET_ROLE_ATTENDEE: "Attendee",
      USER_IN_ASSET_ROLE_CREATOR: "Creator",
      USER_IN_ASSET_ROLE_MODERATOR: "Moderator",
      USER_IS_LOCKED_OUT:
        "User is currently locked out, your account will be activated again in 5 minutes",
      USER_IS_NOT_ANONYMIZED: "User is not anonymized",
      USER_IS_NOT_ON_WHITELIST: "User is not on whitelist",
      USER_MUST_HAVE_ADMIN_PROFILE_ASSIGNED:
        "User must have 'Administrator' profile assigned",
      USER_NAME_VALIDATION_ERROR_EMPTY: "The username cannot be empty",
      USER_NAME_VALIDATION_ERROR_TO_LONG:
        "The username can contain a maximum of 150 characters",
      USER_NAME_VALIDATION_ERROR_TO_SHORT:
        "The username must contain at least 3 characters",
      USER_NOT_FOUND_BY_TOKEN: "User not found by token",
      USER_SUBSCRIPTION_ACTIVE_EXISTS:
        "User already has an active subscription",
      USER_SUBSCRIPTION_CAN_NOT_REACTIVATE:
        "User's current subscription cannot be reactivated",
      USER_SUBSCRIPTION_HAS_EXPIRED:
        "User's subscription has expired. Buy a new subscription after the end of the current period",
      USER_SUBSCRIPTION_IS_NOT_ACTIVE: "User subscription is not active",
      USER_SUBSCRIPTION_IS_STILL_ACTIVE: "User subscription is still active",
      USER_SUBSCRIPTION_NOT_FOUND_AT_PROVIDER:
        "Subscription not found at provider",
      USER_SUBSCRIPTION_REACTIVATED_SUCCESSFULLY:
        "User's subscription has been reactivated successfully",
      VALIDATION__AVAILABILITY_ENDS_BEFORE_END_DATE: "",
      VALIDATION__AVAILABILITY_STARTS_AFTER_START_DATE: "",
      VALIDATION__AVAILABILITY_STARTS_AT_START_DATE:
        "Availablity date must be set before start date",
      VALIDATION__CATCHUP_DATE_SET_BEFORE_EVENT_ENDS:
        "Start time should be set at least 2 hours after event ends",
      VALIDATION__CATCHUP_DATE_SET_IN_THE_PAST:
        "Event availability cannot be set in the past",
      VALIDATION__DATE_SET_IN_THE_PAST: "The event cannot be held in the past",
      VALIDATION__DUPLICATED_PRICES_FOR_THE_SAME_CURRENCY: "",
      VALIDATION__PROFIT_SHARING_NOT_100:
        "Profits of creators must sum up to 100%",
      VALIDATION__START_DATE_AFTER_END_DATE:
        "The event cannot be held after end date",
      VALIDATION__START_DATE_IS_EQUAL_TO_END_DATE:
        "The event start date cannot be the same as end date",
      VIDEO__QUALITY: "Quality",
      VIDEO__SPEED: "Speed",
      VIDEO__SUBTITLES: "Subtitles",
      VISIT_WEB_VERSION: "Visit web version",
      VISIT_WEB_VERSION_MESSAGE:
        "Please, go to web version of the application to purchase this content.",
      VISIT_WEBSITE: "Visit website",
      WARNING_ERROR_NETWORK:
        "The connection was lost, please confirm you are connected to the internet.",
      WATCH__CONTINUE_WATCHING: "Continue watching",
      WATCH__NO_RESOURCE: "No resource to load",
      WATCH__START_FROM_BEGINNING: "Start from the beginning",
      WEDNESDAY: "Wednesday",
      WELCOME__CONTINUE: "Continue without account",
      WELCOME__HINT: "<0>You already have account?  </0><1><0>Login</0></1>",
      WELCOME__SIGN_IN:
        "<0>You already have an account? </0><1><0>Sign in</0></1>",
      WELCOME__TITLE: "Watch movies and TV shows",
      WRONG_REACTION_CODE: "Wrong reaction",
      YOU_HAVE_TO_SIGN_IN_TO_PURCHASE:
        "You have to sign in to purchase this content.",
    },
    ar: {
      APPLICATION_MENU_CATALOG: "الكتالوج",
      APPLICATION_MENU_EPG: "البرنامج الإلكتروني",
      APPLICATION_MENU_FOOTBALL: "كرة القدم",
      APPLICATION_MENU_HOME: "الصفحة الرئيسية",
      APPLICATION_MENU_MOVIES: "الأفلام",
      APPLICATION_MENU_MY_EPISODES: "حلقاتي",
      APPLICATION_MENU_MY_LIST: "قائمتي",
      APPLICATION_MENU_MYWEB: "موقعي على الويب",
      APPLICATION_MENU_PODCAST: "البودكاست",
      APPLICATION_MENU_SPORT: "الرياضة",
      APPLICATION_MENU_VOD: "فيديو حسب الطلب",
      COMMON__ABOUT: "عن",
      COMMON__BUTTON_PLAY: "العب الآن",
      COMMON__BUTTON_REMOVE_FROM_MY_LIST: "إزالة من قائمتي",
      COMMON__HOME: "الصفحة الرئيسية",
      COMMON__LOGOUT: "تسجيل خروج",
      COMMON__MORE_LIKE_THIS: "المزيد من مثل هذا",
      COMMON__MY_ORDERS: "طلباتي",
      COMMON__PREFERENCES: "تفضيلاتي",
      COMMON__PROFILE: "حساب تعريفي",
      COMMON__TERMS_AND_CONDITIONS: "الأحكام والشروط",
      MEDIA__CAST: "الممثلين: {{castList}}",
      MEDIA__DIRECTOR: "المدير: {{directorsList}}",
      MEDIA__WRITER: "كاتب: {{writersList}}",
      MENU_HOME: "الصفحة الرئيسية",
      MENU_SEARCH: "البحث",
      NAME_LENGTH_VALIDATION_MESSAGE:
        "أدخل اسمك (الحد الأدنى 3 أحرف والحد الأقصى 150 حرفًا)",
      PERSON_IN_ASSET_ROLE_CAST: "الممثلين",
      PERSON_IN_ASSET_ROLE_DIRECTOR: "المدير",
      PERSON_IN_ASSET_ROLE_WRITER: "كاتب",
    },
  },
};

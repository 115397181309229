/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { DataProvider } from "@bms/common";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
    detection: {
      lookupLocalStorage: "language",
    },
  });

const defaultTranslations = DataProvider.getDefaultTranslations();

if (defaultTranslations) {
  Object.entries(defaultTranslations).forEach(([language, translations]) => {
    i18n.addResourceBundle(language, "translation", translations);
  });
}

export default i18n;

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ROUTES, ThemeContext, useIsUserLicensed } from "@bms/common";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

import "./HeaderLogo.scss";

const DefaultLogo = React.lazy(
  () => import(/* webpackChunkName: "default-logo" */ "components/DefaultLogo"),
);

interface IHeaderLogoProps {
  className?: string;
}

export const HeaderLogo = ({ className }: IHeaderLogoProps) => {
  const { themeProvider } = useContext(ThemeContext);
  const isUserLicensed = useIsUserLicensed();
  const logo = themeProvider.getAppLogoUrl();
  const route = isUserLicensed ? ROUTES.HOME : ROUTES.BASE;

  return (
    <div className="HeaderLogo">
      <Link to={route}>
        {logo ? (
          <img
            alt="Logo"
            className={`HeaderLogo__logo ${className}`}
            src={logo}
          />
        ) : (
          <DefaultLogo />
        )}
      </Link>
    </div>
  );
};

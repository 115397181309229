/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";

import { IUserRatingCategory, IUserRatingModel } from "models";

import { HTTP_METHOD } from "../../../../../constants";
import { AxiosSubscriber } from "../../../../../services";

export class UserPlatformRatingService {
  get url(): string {
    return "/UserPlatformRating";
  }

  public selectRatingCategories = () =>
    new Observable(
      (observer: Observer<IUserRatingCategory[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/SelectRatingCategories`,
        }),
    );

  public insert = (data: IUserRatingModel) =>
    new Observable(
      (observer: Observer<void>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.POST,
          data: data,
          url: `${this.url}/Insert`,
        }),
    );
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum MediaDayTimeFilter {
  AllDay = "ALL_DAY",
  FromNow = "FROM_NOW",
  Morning = "MORNING",
  Afternoon = "AFTERNOON",
  Evening = "EVENING",
}

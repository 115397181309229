/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ActionType } from "@bms/common";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { RouteHelper } from "helpers";

import { Dialog, MediaButton } from "../../components";

import "./LogOutModal.scss";

export interface ILogOutModalProps {
  children: React.ReactNode | LogOutModalChildren;
  isGeoBlocked?: boolean;
}

type LogOutModalChildren = (props: {
  openModal: () => void;
  closeModal: () => void;
  toggleModal: () => void;
}) => React.ReactNode;

export const LogOutModal = ({ children }: ILogOutModalProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  const openModal = () => {
    setIsVisible(true);
  };

  const closeModal = () => {
    setIsVisible(false);
  };

  const toggleModal = () => {
    setIsVisible((oldVisibility) => !oldVisibility);
  };

  const logoutAction = async () => {
    await RouteHelper.performAction({
      ActionType: ActionType.SignInOut,
    });
    closeModal();
  };
  return (
    <>
      {typeof children === "function" ? (
        (children as LogOutModalChildren)({
          openModal,
          closeModal,
          toggleModal,
        })
      ) : (
        <div onClick={isVisible ? undefined : openModal}>{children}</div>
      )}
      <Dialog
        wrapClassName="LogOutModal"
        destroyOnClose
        footer={null}
        visible={isVisible}
        onCancel={closeModal}
      >
        <span>{t("LOGOUT__QUESTION")}</span>
        <div className="LogOutModal__Actions">
          <MediaButton type="button" variant="primary" onClick={logoutAction}>
            {t("COMMON__YES")}
          </MediaButton>
          <MediaButton type="button" variant="secondary" onClick={closeModal}>
            {t("COMMON__NO")}
          </MediaButton>
        </div>
      </Dialog>
    </>
  );
};

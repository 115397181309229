/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import cx from "classnames";
import * as React from "react";
import isEqual from "react-fast-compare";
import { InView } from "react-intersection-observer";

import { LoaderSpinner } from "../LoaderSpinner";

import "./ImageWithRatio.scss";

export enum ImageRatio {
  "1:1",
  "16:9",
  "16:7",
  "16:6",
  "16:5",
  "4:3",
  "3:2",
  "8:5",
}

export interface Props {
  imageSrc?: string; // source for the image which will be rendered once it is loaded
  fallbackImgSrc?: string;
  isSpinner?: boolean;
  ratio?: ImageRatio;
  className?: string;
  contentClassName?: string;
  width?: number | string | null;
  height?: number | string | null;
  onClick?: () => void;
  onResize?: (width: number, height: number) => void;
  hasGradient?: boolean;
  disableAutoWH?: boolean;
  contentStyle?: React.CSSProperties;
  children?: React.ReactNode;
}

export interface State {
  hasBeenLoaded?: boolean; // flag that indicates whether or not image has been loaded
  width: number | string;
  height: number | string;
  src?: string;
}

/*
 * Component which will display given image based od imageSrc. Until image is not loaded (ready to be rendered)
 * a placeholder image will be rendered
 */
export class ImageWithRatio extends React.Component<Props, State> {
  private _imageLoader: HTMLImageElement = new Image();
  private _mounted = false;

  public state = {
    hasBeenLoaded: false,
    width: "100%",
    height: "100%",
    src: this.props.imageSrc || undefined,
  };

  private getContainerStyle = () => {
    const style: React.CSSProperties = {};

    let width: string | number = -1;
    let height: string | number = -1;

    if (this.props.disableAutoWH) {
      return undefined;
    }

    if (this.props.width === null) {
      width = -1;
    } else if (this.props.width) {
      width = this.props.width;
    } else {
      width = this.state.width;
    }

    if (this.props.height === null) {
      height = -1;
    } else if (this.props.height) {
      height = this.props.height;
    } else {
      height = this.state.height;
    }

    if (width !== -1) {
      style.width = width;
    }

    if (height !== -1) {
      style.height = height;
    }
    return style;
  };

  private getRatioStyle = () => {
    const { src } = this.state;
    const ratioStyle: React.CSSProperties = {};

    const { hasGradient = false } = this.props;
    if (hasGradient) {
      ratioStyle.backgroundImage = `linear-gradient(to bottom, transparent, black 90%)`;

      if (src) {
        ratioStyle.backgroundImage += `, url(${src})`;
      }
    } else if (src) {
      ratioStyle.backgroundImage = `url(${src})`;
    }

    return ratioStyle;
  };

  private getRatioClassName = () => {
    const { ratio = ImageRatio["1:1"] } = this.props;

    let className = "ImageWithRatio ";

    switch (ratio) {
      case ImageRatio["1:1"]:
        className += "ImageWithRatio--ratio11";
        break;
      case ImageRatio["16:9"]:
        className += "ImageWithRatio--ratio169";
        break;
      case ImageRatio["16:7"]:
        className += "ImageWithRatio--ratio167";
        break;
      case ImageRatio["16:6"]:
        className += "ImageWithRatio--ratio166";
        break;
      case ImageRatio["16:5"]:
        className += "ImageWithRatio--ratio165";
        break;
      case ImageRatio["4:3"]:
        className += "ImageWithRatio--ratio43";
        break;
      case ImageRatio["3:2"]:
        className += "ImageWithRatio--ratio32";
        break;
      case ImageRatio["8:5"]:
        className += "ImageWithRatio--ratio85";
        break;
      default:
        break;
    }

    return className;
  };

  public componentDidMount() {
    const { imageSrc, fallbackImgSrc } = this.props;
    this._mounted = true;
    this._loadImage(imageSrc, fallbackImgSrc);
  }

  public componentWillUnmount() {
    this._imageLoader.src = "";
    this._mounted = false;
  }

  public shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  private onClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  public render() {
    const { children, className, contentClassName, contentStyle } = this.props;
    const { hasBeenLoaded } = this.state;

    return (
      <div
        onClick={this.onClick}
        style={this.getContainerStyle()}
        className={cx("ImageWithRatio", className)}
      >
        <InView root={document.body} rootMargin="25% 0px" triggerOnce={true}>
          {({ inView, ref }) =>
            inView ? (
              <div
                ref={ref}
                style={this.getRatioStyle()}
                className={this.getRatioClassName()}
              >
                {!hasBeenLoaded && this.props.isSpinner !== false && (
                  <div className="ImageWithRatio__Spinner">
                    <LoaderSpinner />
                  </div>
                )}
                <div
                  className={
                    contentClassName
                      ? `${contentClassName} ImageWithRatio__Content`
                      : "ImageWithRatio__Content"
                  }
                  style={contentStyle}
                >
                  {hasBeenLoaded && children}
                </div>
              </div>
            ) : (
              <div
                ref={ref}
                style={this.getRatioStyle()}
                className={this.getRatioClassName()}
              />
            )
          }
        </InView>
      </div>
    );
  }

  private _loadImage(imageSrc = "", fallbackImgSrc?: string) {
    if (!this._mounted) {
      return;
    }

    if (!imageSrc) {
      return;
    }
    let currentSrc = imageSrc;

    this._imageLoader.src = currentSrc;
    this._imageLoader.onload = () => {
      if (!this._mounted) {
        return;
      }
      if (this.props.onResize) {
        this.props.onResize(this._imageLoader.width, this._imageLoader.height);
      }
      this.setState({
        width: this._imageLoader.width,
        height: this._imageLoader.height,
        hasBeenLoaded: true,
        src: currentSrc,
      });
    };
    this._imageLoader.onerror = () => {
      if (!fallbackImgSrc || fallbackImgSrc === currentSrc) return;
      currentSrc = fallbackImgSrc;
      this._imageLoader.src = currentSrc;
    };
  }
}

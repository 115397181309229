/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const useImageLoader = () => {
  const loadImage = (
    imageSrc?: string,
    fallbackImageSrc?: string,
  ): Promise<string | null> => {
    if (!imageSrc) {
      return Promise.resolve(null);
    }
    const imageLoader = new Image();
    let currentSrc = imageSrc;
    imageLoader.src = currentSrc;
    return new Promise((resolve) => {
      imageLoader.onload = () => {
        resolve(currentSrc);
      };
      imageLoader.onerror = () => {
        if (!fallbackImageSrc || currentSrc === fallbackImageSrc)
          return Promise.resolve(null);
        currentSrc = fallbackImageSrc;
        imageLoader.src = currentSrc;
      };
    });
  };
  return { loadImage };
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { useConfigurationSelector } from "@bms/common";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";

interface IGoogleAuthProviderProps {
  children?: React.ReactElement;
}

export const GoogleAuthProvider = ({ children }: IGoogleAuthProviderProps) => {
  const configuration = useConfigurationSelector();
  const googleClientId = configuration?.System?.Authorization?.Google?.ClientId;

  return googleClientId ? (
    <GoogleOAuthProvider clientId={googleClientId}>
      {children}
    </GoogleOAuthProvider>
  ) : (
    children
  );
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { StringHelper } from "../helpers/stringHelper";

declare let tizen: any;

export class AppConfigTizen {
  static get ApiUrl(): string {
    return StringHelper.toString(process.env.REACT_APP_API_URL);
  }

  static get DataProvider(): string {
    return StringHelper.toString(process.env.REACT_APP_DATA_PROVIDER);
  }

  static get DataPageSize(): number {
    return process.env.REACT_APP_DATA_PAGE_SIZE
      ? +process.env.REACT_APP_DATA_PAGE_SIZE
      : 12;
  }

  static get PaymentProvider(): string {
    return StringHelper.toString(process.env.REACT_APP_PAYMENT_PROVIDER);
  }

  static get AppName(): string {
    return StringHelper.toString(process.env.REACT_APP_NAME);
  }

  static get AppUrl(): string {
    return StringHelper.toString(process.env.REACT_APP_APP_URL);
  }

  static get AppConfigUrl(): string {
    return StringHelper.toString(process.env.REACT_APP_CONFIG_URL);
  }

  static get DeviceOrientation(): string {
    return StringHelper.toString(process.env.REACT_APP_FORCE_ORIENTATION);
  }

  static get Environment(): string {
    return StringHelper.toString(process.env.REACT_APP_ENV);
  }

  static get PlayerType(): string {
    return StringHelper.toString(process.env.REACT_APP_PLAYER_TYPE);
  }

  static get PlayerNativeSupportDisabled(): boolean {
    return process.env.REACT_APP_PLAYER_NATIVE_SUPPORT_DISABLED == "true";
  }

  static get TenantOrigin(): string {
    return StringHelper.toString(process.env.REACT_APP_TENANT_ORIGIN_URL);
  }

  static get PlatformVersion(): string {
    return `${process.env.REACT_APP_VERSION}`;
  }

  static get AppLocalTimezone() {
    if (typeof tizen != "undefined") {
      return tizen.time.getLocalTimezone();
    }
  }

  static getDeviceLocale = (callback: CallableFunction) => {
    if (typeof tizen != "undefined") {
      const onSuccess = (data: any) => {
        callback(`${data.language}`, `${data.country}`);
      };
      const onError = (error: any) => {
        console.error("Get Device Locale Error: ", JSON.stringify(error));
        callback("", "");
      };
      tizen.systeminfo.getPropertyValue("LOCALE", onSuccess, onError);
    } else {
      callback("", "");
    }
  };

  static get AppDevicePlatformVersion(): string {
    if (typeof tizen != "undefined") {
      try {
        const platform = tizen.systeminfo.getCapability(
          "http://tizen.org/feature/platform.version",
        );
        if (platform) {
          return `${platform}`;
        }
      } catch {}
    }
    return "";
  }
}

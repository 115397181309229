/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

export const SCREEN_VARIANTS = {
  INTRO: {
    DEFAULT: "DEFAULT",
    VIDEO: "VIDEO",
  },
  REGISTER_SUCCESS: {
    DEFAULT: "DEFAULT",
    CUSTOM: "CUSTOM",
  },
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ThemeContext } from "@bms/common";
import * as React from "react";
import { useContext } from "react";

import "./ProgressBar.scss";

export interface ProgressBarProps {
  progress: number;
}

export const ProgressBar = ({ progress }: ProgressBarProps) => {
  const { themeProvider } = useContext(ThemeContext);

  const progressBarContainerStyle: React.CSSProperties = {
    backgroundColor: themeProvider.getColor("AppPrimaryTextColor"),
  };
  const progressBarStyle: React.CSSProperties = {
    backgroundColor: themeProvider.getColor("AppPrimaryColor"),
    width: `${progress}%`,
  };

  return (
    <div className="ProgressBar-container" style={progressBarContainerStyle}>
      <div className="ProgressBar-bar" style={progressBarStyle} />
    </div>
  );
};

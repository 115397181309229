/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Identifier, IDownloadSource } from "../models";

import { StorageHelperBase } from "./storageHelperBase";

export class StorageHelper extends StorageHelperBase {
  static async getPodcasts(): Promise<IDownloadSource[]> {
    return Promise.resolve([]);
  }

  static async updatePodcast(_: IDownloadSource): Promise<IDownloadSource[]> {
    return Promise.resolve([]);
  }

  static async deletePodcast(_: Identifier): Promise<IDownloadSource[]> {
    return Promise.resolve([]);
  }

  static async getMediaOfflineProgress(_: Identifier): Promise<number> {
    return Promise.resolve(0);
  }

  static async ratingAssetPlayed(): Promise<void> {
    return Promise.resolve();
  }
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";

import { AppConfig } from "../../../../app";
import { HTTP_METHOD } from "../../../../constants";
import { PlatformHelper } from "../../../../helpers";
import {
  IConfigurationInfoModel,
  IConfigurationModel,
} from "../../../../models";
import { AxiosSubscriber } from "../../../../services";

export class ConfigurationService {
  public getConfiguration = (url: string): Observable<IConfigurationModel> => {
    return new Observable(
      (observer: Observer<IConfigurationModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: url.replace("{platform}", PlatformHelper.Platform),
        }),
    );
  };

  public getConfigurationInfo = (): Observable<IConfigurationInfoModel> =>
    new Observable((observer: Observer<IConfigurationInfoModel>) => {
      return new AxiosSubscriber(observer, {
        method: HTTP_METHOD.GET,
        url: AppConfig.AppConfigUrl,
      });
    });
}

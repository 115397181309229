/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ActionType, IApplicationMenuItemComponentModel } from "@bms/common";
import { push } from "connected-react-router";
import { MenuItemProps } from "rc-menu";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { MenuClickEventHandler, MenuItem } from "components/Menu/DesktopMenu";

import "./ApplicationMenuItem.scss";

export interface IApplicationMenuItemProps extends MenuItemProps {
  component: IApplicationMenuItemComponentModel;
}

export const ApplicationMenuItem: React.FC<IApplicationMenuItemProps> = ({
  component,
  ...props
}: IApplicationMenuItemProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const title = t(component?.TitleTranslationKey ?? "", component?.Title ?? "");

  const onClick: MenuClickEventHandler = ({ key }) => {
    if (component?.Action?.ActionType) {
      switch (component.Action.ActionType) {
        case ActionType.OpenScreen:
          if (key) {
            dispatch(push(`/${key}`));
          }
          break;
        case ActionType.OpenUrl:
          if (component.Action.Url) {
            window.open(component.Action.Url, "_blank");
          }
          break;
      }
    }
  };

  return (
    <MenuItem className="Menu-item__container" {...props} onClick={onClick}>
      {title}
    </MenuItem>
  );
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum MediaType {
  Series = "SERIES",
  Video = "VOD",
  Episode = "EPISODE",
  Season = "SEASON",
  Live = "LIVE",
  Event = "EVENT",
  Intro = "INTRO",
  Channel = "CHANNEL",
  Program = "PROGRAM",
  Package = "PACKAGE",
  Premiere = "VIDEO_PREMIERE",
  Category = "CATEGORY",
  Article = "ARTICLE",
  Album = "ALBUM",
  Podcast = "PODCAST",
  Advertisement = "ADVERTISEMENT",
  FootballEvent = "FOOTBALL_EVENT",
  FootballSeason = "FOOTBALL_SEASON",
  Club = "CLUB",
  Competitions = "COMPETITIONS",
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import cx from "classnames";
import React from "react";

import { LoaderSpinner } from "../LoaderSpinner";

import "./MediaButton.scss";

export type IMediaButtonVariant =
  | "plain"
  | "primary"
  | "secondary"
  | "transparent"
  | "link"
  | "disabled"
  | "unfilled";

export interface IMediaButtonProps {
  className?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  iconElevated?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  variant: IMediaButtonVariant;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode | string;
  loading?: boolean;
  id?: string;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
  ariaLabel?: string;
}

export const MediaButton = React.forwardRef<
  HTMLButtonElement,
  IMediaButtonProps
>(
  (
    {
      className,
      children,
      disabled,
      icon,
      iconElevated,
      loading,
      type,
      variant,
      onClick,
      onMouseEnter,
      onMouseLeave,
      ariaLabel,
      ...props
    }: IMediaButtonProps,
    ref,
  ) => {
    return (
      <button
        className={cx("MediaButton", `MediaButton--${variant}`, className, {
          "MediaButton--loading": loading,
          "MediaButton--disabled": disabled,
        })}
        disabled={disabled || loading}
        type={type}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref}
        {...props}
      >
        {loading && (
          <LoaderSpinner
            className="MediaButton__LoaderSpinner"
            width={25}
            height={25}
          />
        )}
        {icon && (
          <i
            className={cx("MediaButton__icon", {
              "MediaButton__icon--elevated": iconElevated,
            })}
            aria-label={ariaLabel}
          >
            {icon}
          </i>
        )}
        {children && <span className="MediaButton__text">{children}</span>}
      </button>
    );
  },
);

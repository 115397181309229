/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export class BooleanHelper {
  public static toBool(value: any, defaulValue = false): boolean {
    if (value === null || value === undefined) {
      return defaulValue;
    }

    if (value === "true" || value === "1" || value === "yes" || value === "on")
      return true;

    if (value === "false" || value === "0" || value === "no" || value === "off")
      return false;

    return !!value;
  }
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IMediaModel, IMediaPlayInfoModel, MediaStreamType } from "@bms/common";
import React from "react";

import { BaseAudioPlayer } from "./types";

interface IAudioContext {
  player?: BaseAudioPlayer;
  playing?: boolean;
  ended?: boolean;
  currentTime?: number;
  duration?: number;
  playbackRate?: number;
  volume?: number;
  media?: IMediaModel;
  streamType?: MediaStreamType;
  mediaPlayInfo?: IMediaPlayInfoModel;
  setPlaybackRate?: (rate: number) => void;
  playAudio?: () => void;
  pauseAudio?: () => void;
  seekTo?: (val: number) => void;
  setVolume?: (vol: number) => void;
  coverSrc?: string;
  setPlayer?: (player?: BaseAudioPlayer) => void;
  handlers: { [key: string]: <T>(e?: T) => void };
  isLoading?: boolean;
  isFromAutoPlay?: boolean;
}

export const AudioContext = React.createContext<IAudioContext>({
  handlers: {},
  isFromAutoPlay: false,
});

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { ComponentType } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { IAppState } from "store";

export const useRTL = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const languages = useSelector(
    (state: IAppState) => state.configuration.configuration?.Languages,
  );

  const currentLang = languages?.find((lang) => lang.Code === language);

  return !!currentLang?.RTL;
};

type WithRTLProps = {
  RTL?: boolean;
};

export const withRTL = <P extends object>(
  WrappedComponent: ComponentType<P & WithRTLProps>,
) => {
  const WithRTLComponent: React.FC<Omit<P, "RTL">> = (props) => {
    const isRTL = useRTL();

    return <WrappedComponent {...(props as P)} isRTL={isRTL} />;
  };

  return WithRTLComponent;
};

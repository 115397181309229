/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
const PREFIX = "CONFIRMATION_DIALOG";
const SHOW_CONFIRMATION_DIALOG = `${PREFIX}_SHOW_CONFIRMATION_DIALOG`;
const HIDE_CONFIRMATION_DIALOG = `${PREFIX}_HIDE_CONFIRMATION_DIALOG`;

export const ActionTypes = {
  SHOW_CONFIRMATION_DIALOG,
  HIDE_CONFIRMATION_DIALOG,
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IAppState } from "../types";

const audioSourceSelector = (state: IAppState) => state.audio?.audioSource;

const audioMediaSelector = (state: IAppState) => state.audio?.media;

const audioStreamTypeSelector = (state: IAppState) => state.audio?.streamType;

const isBottomMenuVisibleSelector = (state: IAppState) =>
  state.audio?.isBottomMenuVisible;

const isAudioLoadingSelector = (state: IAppState) => state.audio?.isLoading;

const isAudioFromAutoPlay = (state: IAppState) => state.audio?.isFromAutoPlay;

export const Selectors = {
  audioSourceSelector,
  audioMediaSelector,
  audioStreamTypeSelector,
  isBottomMenuVisibleSelector,
  isAudioLoadingSelector,
  isAudioFromAutoPlay,
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum UserInAssetRoles {
  Creator = "Creator",
  User = "User",
  Moderator = "Moderator",
  Attendee = "Attendee",
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IContentDecryptionModuleModel } from "@bms/common";

declare global {
  interface Window {
    WebKitMediaKeys: unknown;
  }
}

const genericConfig = [
  {
    initDataTypes: ["cenc"],
    audioCapabilities: [
      {
        contentType: 'audio/mp4;codecs="mp4a.40.2"',
      },
    ],
    videoCapabilities: [
      {
        contentType: 'video/mp4;codecs="avc1.42E01E"',
      },
    ],
  },
];

const keySystems = [
  // Fairplay
  // Requires different config than other CDMs
  {
    keySystem: "com.apple.fps",
    supportedConfig: [
      {
        initDataTypes: ["sinf"],
        videoCapabilities: [
          {
            contentType: "video/mp4",
          },
        ],
      },
    ],
  },
  // Playready
  {
    keySystem: "com.microsoft.playready.recommendation",
    supportedConfig: genericConfig,
  },
  // Widevine
  {
    keySystem: "com.widevine.alpha",
    supportedConfig: genericConfig,
  },
  // Clear
  {
    keySystem: "org.w3.clearkey",
    supportedConfig: genericConfig,
  },
];

export class CdmHelper {
  static detectSupportedCDMs(): Promise<IContentDecryptionModuleModel> {
    const result: IContentDecryptionModuleModel = {
      FairPlay: Boolean(window.WebKitMediaKeys),
      PlayReady: false,
      Widevine: false,
      ClearKey: false,
    };

    if (!window.MediaKeys || !window.navigator.requestMediaKeySystemAccess) {
      return Promise.resolve(result);
    }

    return Promise.all(
      keySystems.map(({ keySystem, supportedConfig }) => {
        return window.navigator
          .requestMediaKeySystemAccess(keySystem, supportedConfig)
          .catch((err) => {
            console.warn(err);
          });
      }),
    ).then(([fairplay, playready, widevine, clearkey]) => {
      result.FairPlay = Boolean(fairplay);
      result.PlayReady = Boolean(playready);
      result.Widevine = Boolean(widevine);
      result.ClearKey = Boolean(clearkey);

      return result;
    });
  }
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useSelector } from "react-redux";

import { Identifier } from "../models/Common";
import { IAppState } from "../store/types";

export const useMediaListByIdSelector = (id: Identifier) => {
  const mediaList = useSelector(
    (state: IAppState) => state.media.mediaList[id] ?? {},
  );

  return mediaList;
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { IMediaImageModel, IMediaListModel, IMediaModel } from "models";

import {
  MediaImageType,
  MediaType,
  PlatformType,
  SourceType,
} from "../../../../../../common/src/enums";
import { TimeHelper } from "../../../../helpers";
import { IBannerCollectionModel } from "../models/IBannerModel";
import { IEntityModel } from "../models/IEntityModel";
import { IEventModel } from "../models/IEventModel";

import { MottoSourceName } from "./listQueryParamsMapper";

export class ModelsMapperHelper {
  mapListToMediaList(
    list: IBannerCollectionModel | IEventModel | IEntityModel,
    sourceName: MottoSourceName,
  ): IMediaListModel | null {
    if ("bannerCollection" in list) {
      return this.mapBanner(list);
    }

    if ("entities" in list) {
      return this.mapEntities(list, sourceName);
    }

    if ("events" in list) {
      return this.mapEvents(list);
    }
    return null;
  }

  mapBanner(banner: IBannerCollectionModel): IMediaListModel {
    const entities: IMediaModel[] = banner.bannerCollection.banners.map(
      (banner) => ({
        Id: banner.id,
        Description: banner.texts[0].text,
        IsFree: true,
        Images: this.generateImageArray(banner.backgroundAssets[0].assetUrl),
        MediaTypeCode: MediaType.Competitions,
      }),
    );

    return {
      Entities: entities,
      TotalCount: banner.bannerCollection.banners.length,
      SourceType: SourceType.Standard,
      CacheDataValidTo: TimeHelper.getDateWithOffset(
        TimeHelper.getCurrentDateTime(),
        60,
        "seconds",
      ).toString(),
    };
  }

  mapEntities(
    entities: IEntityModel,
    sourceName: MottoSourceName,
  ): IMediaListModel {
    const entitiesMapped: IMediaModel[] = entities.entities.map((entity) => ({
      Id: entity.id,
      IsFree: true,
      Title: entity.name,
      Images: this.generateImageArray(entity.thumbnailUrl),
      MediaTypeCode:
        sourceName === MottoSourceName.CLUBS
          ? MediaType.Club
          : MediaType.Season,
    }));

    return {
      Entities: entitiesMapped,
      TotalCount: entities.entities.length,
      SourceType: SourceType.Standard,
      CacheDataValidTo: TimeHelper.getDateWithOffset(
        TimeHelper.getCurrentDateTime(),
        60,
        "seconds",
      ).toString(),
    };
  }

  mapEvents(events: IEventModel): IMediaListModel {
    const entities: IMediaModel[] = events.events.map((event) => ({
      Id: event.id,
      IsFree: true,
      Title: event.title,
      Images: this.generateImageArray(
        event.fields.away_club.item.thumbnail_url,
      ),
      MediaTypeCode: MediaType.FootballEvent,
    }));

    return {
      Entities: entities,
      TotalCount: events.events.length,
      SourceType: SourceType.Standard,
      CacheDataValidTo: TimeHelper.getDateWithOffset(
        TimeHelper.getCurrentDateTime(),
        60,
        "seconds",
      ).toString(),
    };
  }

  generateImageArray(url: string): IMediaImageModel[] {
    const imageTypes = [
      {
        ImageTypeCode: MediaImageType.Highlights,
        width: 1920,
      },
      {
        ImageTypeCode: MediaImageType.Frame,
        width: 720,
      },
      {
        ImageTypeCode: MediaImageType.Cover,
        width: 1280,
      },
      {
        ImageTypeCode: MediaImageType.Square,
        width: 480,
      },
      {
        ImageTypeCode: MediaImageType.Background,
        width: 1920,
      },
      {
        ImageTypeCode: MediaImageType.Round,
        width: 480,
      },
      {
        ImageTypeCode: MediaImageType.Landscape,
        width: 1280,
      },
    ];

    return imageTypes.map(({ ImageTypeCode, width }) => ({
      Id: Math.random(),
      ImageTypeCode,
      PlatformCode: PlatformType.Any,

      Url: url,
      Width: width,
    }));
  }
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";

import { HTTP_METHOD } from "../../../../constants";
import { IJwpConfigurationModel } from "../models";

import { AxiosSubscriber } from "./AxiosSubscriber";

export class ConfigService {
  get url(): string {
    return `/apps/configs/${process.env.REACT_APP_JWP_CONFIG_ID}.json`;
  }

  public get = (): Observable<IJwpConfigurationModel> => {
    return new Observable(
      (observer: Observer<IJwpConfigurationModel>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}`,
        }),
    );
  };
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, MediaButton } from "../../components";

import "./GeoBlockedModal.scss";

export interface IGeoBlockedModalProps {
  children: React.ReactNode | GeoBlockedModalChildren;
  isGeoBlocked?: boolean;
}

type GeoBlockedModalChildren = (props: {
  openModal: () => void;
  closeModal: () => void;
  toggleModal: () => void;
}) => React.ReactNode;

export const GeoBlockedModal: React.FC<IGeoBlockedModalProps> = ({
  children,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  const openModal = () => {
    setIsVisible(true);
  };

  const closeModal = () => {
    setIsVisible(false);
  };

  const toggleModal = () => {
    setIsVisible((oldVisibility) => !oldVisibility);
  };

  return (
    <>
      {typeof children === "function" ? (
        (children as GeoBlockedModalChildren)({
          openModal,
          closeModal,
          toggleModal,
        })
      ) : (
        <div onClick={isVisible ? undefined : openModal}>{children}</div>
      )}
      <Dialog
        wrapClassName="GeoBlockedModal"
        destroyOnClose={true}
        footer={null}
        visible={isVisible}
        onCancel={closeModal}
        closable={false}
      >
        <span>{t("GEOBLOCKING__BLOCKED_ASSET")}</span>
        <MediaButton type="button" variant="primary" onClick={closeModal}>
          {t("GEOBLOCKING__POPUP_CANCEL")}
        </MediaButton>
      </Dialog>
    </>
  );
};

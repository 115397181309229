/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IStorageManager } from "./IStorageManager";
import { StorageValue } from "./StorageManager.types";

export class StorageManagerCommon implements IStorageManager {
  public setValue = async <K extends keyof StorageValue>(
    key: K,
    value: StorageValue[K],
  ) => {
    if (!localStorage) {
      console.warn("StorageManager: localStorage is not defined");
      return;
    }

    return localStorage.setItem(key, JSON.stringify(value));
  };

  public getValue = async <K extends keyof StorageValue>(
    key: K,
  ): Promise<StorageValue[K] | null> => {
    if (!localStorage) {
      console.warn("StorageManager: localStorage is not defined");
      return null;
    }

    const item = localStorage.getItem(key);

    if (item == null) {
      return null;
    }

    try {
      return JSON.parse(item);
    } catch (error) {
      return null;
    }
  };

  public deleteValue = async (name: keyof StorageValue) => {
    if (!localStorage) {
      console.warn("StorageManager: localStorage is not defined");
      return;
    }

    localStorage.removeItem(name);
  };
}

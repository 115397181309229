/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";

import { ICoinsPaymentRequestModel } from "models/CryptoCoins/ICoinsPaymentRequestModel";
import { ICoinsPaymentResponseModel } from "models/CryptoCoins/ICoinsPaymentResponseModel";

import { HTTP_METHOD } from "../../../../constants";
import { AxiosSubscriber, PromisifiableBase } from "../../../../services";

export class CryptoCoinsService extends PromisifiableBase {
  get url(): string {
    return "/CryptoCoins";
  }

  public buyCryptoCoins = (
    payload: ICoinsPaymentRequestModel,
  ): Observable<ICoinsPaymentResponseModel> =>
    new Observable((observer: Observer<ICoinsPaymentResponseModel>) => {
      const data = {
        ChangePaymentMethod: false,
        ...payload,
      };
      return new AxiosSubscriber(observer, {
        data,
        method: HTTP_METHOD.POST,
        url: `${this.url}/Buy`,
      });
    });
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { useScript } from "@bms/common";

import { PlayerControllerType } from "components/VideoPlayer/types";

const createStylesheet = (src: string) => {
  let link = document.querySelector(`link[href="${src}"]`) as HTMLLinkElement;

  if (!link) {
    link = document.createElement("link");
    link.rel = "stylesheet";
    link.as = "style";
    link.href = src;
    document.head.appendChild(link);
  }
};

export const usePlayerLoader = () => {
  let playerSrc = "";

  switch (process.env.REACT_APP_PLAYER_TYPE) {
    case PlayerControllerType.JWPlayer:
      playerSrc = "https://cdn.jwplayer.com/libraries/6cWCXvWu.js";
      break;
    case PlayerControllerType.THEOPlayer:
      createStylesheet(
        "https://cdn.myth.theoplayer.com/b9fe9045-ed93-4842-8e5a-13b6c34d69ef/ui.css",
      );

      playerSrc =
        "https://cdn.myth.theoplayer.com/b9fe9045-ed93-4842-8e5a-13b6c34d69ef/THEOplayer.js";
      break;
    default:
      playerSrc;
  }
  return useScript(playerSrc);
};

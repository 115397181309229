/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AssetEventType,
  AssetType,
  IMediaModel,
  TimeHelper,
} from "@bms/common";
import React from "react";

import "./MediaStartInfo.scss";

export interface IMediaStartInfoProps {
  media: IMediaModel;
}

export const MediaStartInfo = (props: IMediaStartInfoProps) => {
  const { media } = props;

  if (
    (media.MediaTypeCode === AssetType.Live ||
      media.MediaTypeCode === AssetType.Program ||
      media.MediaTypeCode === AssetEventType.Live ||
      media.MediaTypeCode === AssetEventType.MovieNight ||
      media.MediaTypeCode === AssetEventType.VideoPremiere) &&
    media.StartDateTime
  ) {
    return (
      <div className="MediaStartInfo">
        {TimeHelper.formatAvailabilityDate(media.StartDateTime)}
      </div>
    );
  }

  return null;
};

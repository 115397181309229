/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { DataProvider } from "@bms/common";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import "./MaintenanceScreen.scss";

export const MaintenanceScreen = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const getHealth = async () => {
      try {
        await DataProvider.health();
        window.location.replace(window.location.origin);
      } catch (error) {
        console.error(error);
      }
    };
    const intervalCall = setInterval(getHealth, 30000);
    return () => {
      clearInterval(intervalCall);
    };
  }, []);

  return (
    <div className="MaintenanceScreen">
      <div className="MaintenanceScreen__icon" />
      <p className="MaintenanceScreen__text text-center text-large text-bold">
        {t("MAINTENANCE_SCREEN__MAINTENANCE")}
      </p>
      <p className="MaintenanceScreen__text text-center text-small">
        {t("MAINTENANCE_SCREEN__BACK_SOON")}
      </p>
    </div>
  );
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import dayjs from "dayjs";

import { ITokenModel } from "../../models/Auth";
import { Identifier } from "../../models/Common";
import { IRatingData } from "../../models/Common/IRatingData";
import { IConfigurationModel } from "../../models/Configuration";
import { IDownloadIcon, IDownloadSource } from "../../models/Download";
import { IMediaListModel } from "../../models/Media";
import { IUserStorageModel } from "../../models/User";

export type UsersStorage = { [userId: Identifier]: IUserStorageModel };

export type SourceStorage = { [sourceId: Identifier]: IMediaListModel };

export type Month = dayjs.Dayjs | string;

export enum StorageKey {
  configuration = "configuration",
  session = "session",
  backendVersion = "backendVersion",
  userId = "userId",
  users = "users",
  osid = "osid",
  language = "language",
  source = "source",
  version = "version",
  eventsMonth = "eventsMonth",
  podcasts = "podcasts",
  svgIcons = "svgIcons",
  ratingData = "ratingData",
}

export type StorageValue = {
  [StorageKey.configuration]: IConfigurationModel;
  [StorageKey.session]: ITokenModel;
  [StorageKey.backendVersion]: string;
  [StorageKey.userId]: number | string;
  [StorageKey.users]: UsersStorage;
  [StorageKey.osid]: string;
  [StorageKey.language]: string;
  [StorageKey.source]: SourceStorage;
  [StorageKey.version]: string;
  [StorageKey.eventsMonth]: Month;
  [StorageKey.podcasts]: IDownloadSource[];
  [StorageKey.svgIcons]: IDownloadIcon[];
  [StorageKey.ratingData]: IRatingData;
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum FieldAccessType {
  Editable = 1,
  Readonly = 2,
  Hidden = 3,
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ROUTES, useConfigurationSelector } from "@bms/common";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

export const RegisterLink: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { System } = useConfigurationSelector() || {};

  if (!!System?.RegistrationUrl) {
    return (
      <a href={System?.RegistrationUrl} rel="noreferrer noopener">
        {children}
      </a>
    );
  }

  return <Link to={ROUTES.REGISTER}>{children}</Link>;
};

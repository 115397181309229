/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import InPlayer, {
  AccountData,
  Env,
  RegisterField,
  UpdateAccountData,
} from "@inplayer-org/inplayer.js";
import axios from "axios";

import { StorageHelper } from "../../../../helpers";
import {
  IAuthRequestModel,
  IAuthResponseModel,
  IChangePasswordModel,
  Identifier,
  IErrorModel,
  IRegisterRequestEmailModel,
  IUserAssetPropertiesModel,
  IUserConsentModel,
  IUserDeleteAccountRequestModel,
  IUserModel,
  IUserSettingsModel,
} from "../../../../models";
import { AuthStore, dispatch } from "../../../../store";
import { IJWPMetadata, ModelsMapperHelper } from "../helpers";

const DEFAULT_ERROR_MESSAGE = "Something went wrong";

export class InPlayerService {
  private _inPlayerDataProvider = InPlayer;

  private _inPlayerClientId = process.env.REACT_APP_JWP_CLIENT_ID || "";
  private _inPlayerReffer = window?.location?.origin || "";
  private _inPlayerUserAccount?: AccountData = undefined;

  private _isTokenRefreshing = false;
  private _requestAccountDataQueue: {
    resolve: (data?: AccountData) => void;
    reject: (error?: unknown) => void;
  }[] = [];

  private _processRequestAccountDataQueue = (
    error?: unknown,
    data?: AccountData,
  ) => {
    this._requestAccountDataQueue.forEach((promise) => {
      if (error) {
        promise.reject(error);
      } else {
        promise.resolve(data);
      }
    });

    this._requestAccountDataQueue = [];
  };

  constructor() {
    this._inPlayerDataProvider.setConfig("development" as Env);
  }

  public async handleError<T, Y>(
    err: unknown,
    callback: (param: Y) => T,
    callbackParams: Y,
  ): Promise<T> {
    if (axios.isAxiosError(err)) {
      try {
        if (err.response?.status === 401) {
          const newToken =
            await this._inPlayerDataProvider.Account.refreshToken(
              this._inPlayerClientId,
            );
          this._inPlayerDataProvider.Account.setToken(
            newToken.data.access_token,
            newToken.data.refresh_token,
            newToken.data.expires,
          );
          const accountInfo =
            await this._inPlayerDataProvider.Account.getAccountInfo();
          this._inPlayerUserAccount = accountInfo?.data;
          return callback(callbackParams);
        }
        return Promise.reject({
          Message: err.response?.data?.message || DEFAULT_ERROR_MESSAGE,
          MessageKey: err.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 403) {
            this._inPlayerDataProvider.Account.removeToken();
            dispatch(AuthStore.Actions.signOut());
          }
          return Promise.reject({
            Message: error.response?.data?.message || DEFAULT_ERROR_MESSAGE,
            MessageKey: error.response?.data?.message || DEFAULT_ERROR_MESSAGE,
          });
        }
      }
    }

    return Promise.reject(err as IErrorModel);
  }

  async getInplayerUserAccount(): Promise<AccountData | undefined> {
    if (!this._inPlayerDataProvider.Account.isAuthenticated()) {
      if (this._isTokenRefreshing) {
        return new Promise((resolve, reject) => {
          this._requestAccountDataQueue.push({ resolve, reject });
        })
          .then((data?: unknown) => {
            return Promise.resolve(data as AccountData);
          })
          .catch((err: unknown) => {
            return Promise.reject(err);
          });
      }

      const credentials =
        this._inPlayerDataProvider.Account.getToken().toObject();

      if (credentials.refreshToken) {
        this._isTokenRefreshing = true;

        try {
          const newToken =
            await this._inPlayerDataProvider.Account.refreshToken(
              this._inPlayerClientId,
            );
          this._inPlayerDataProvider.Account.setToken(
            newToken.data.access_token,
            newToken.data.refresh_token,
            newToken.data.expires,
          );
          const accountInfo =
            await this._inPlayerDataProvider.Account.getAccountInfo();
          this._inPlayerUserAccount = accountInfo?.data;

          this._processRequestAccountDataQueue(undefined, accountInfo?.data);

          this._isTokenRefreshing = false;
        } catch (error) {
          this._isTokenRefreshing = false;
          this._inPlayerDataProvider.Account.removeToken();
          this._processRequestAccountDataQueue(error, undefined);
          dispatch(AuthStore.Actions.signOut());
          return Promise.reject(null);
        }
      }
    }

    if (this._inPlayerUserAccount) {
      return this._inPlayerUserAccount;
    }

    try {
      const accountInfo =
        await this._inPlayerDataProvider.Account.getAccountInfo();
      this._inPlayerUserAccount = accountInfo?.data;
    } catch (err) {
      return this.handleError(
        err,
        this.getInplayerUserAccount.bind(this),
        undefined,
      );
    }

    return this._inPlayerUserAccount;
  }

  async getWatchHistoryForItem(mediaId: Identifier) {
    const watchHistoryPercent =
      await this._inPlayerDataProvider.Account.getWatchHistoryForItem(
        `${mediaId}`,
      )
        .then((response) => response.data.progress)
        .catch(() => undefined);
    return watchHistoryPercent;
  }

  async getUserAssetsProperties(): Promise<IUserAssetPropertiesModel[]> {
    try {
      const result: IUserAssetPropertiesModel[] = [];
      const watchAssets =
        await this._inPlayerDataProvider.Account.getWatchHistory({});

      for (const watchAsset of watchAssets?.data?.collection) {
        result.push(
          ModelsMapperHelper.toUserAssetWatvhPropertyModel(watchAsset),
        );
      }

      const favoritesAssets =
        await this._inPlayerDataProvider.Account.getFavorites();

      for (const favoriteAsset of favoritesAssets?.data?.collection) {
        const userAssetProperty =
          ModelsMapperHelper.toUserAssetFavoritePropertyModel(favoriteAsset);
        const index = result.findIndex(
          (row) => row.AssetId === userAssetProperty.AssetId,
        );

        if (index > 0) {
          result[index].IsFavorite = true;
        } else {
          result.push(userAssetProperty);
        }
      }

      return Promise.resolve(result);
    } catch (err: unknown) {
      return this.handleError(
        err,
        this.getUserAssetsProperties.bind(this),
        undefined,
      );
    }
  }

  async addToMyList(mediaId: Identifier): Promise<void> {
    try {
      await this._inPlayerDataProvider.Account.addToFavorites(
        mediaId as string,
      );
      await StorageHelper.addToMyList(mediaId);
    } catch (err: unknown) {
      return this.handleError(err, this.addToMyList.bind(this), mediaId);
    }
  }

  async removeFromMyList(mediaId: Identifier): Promise<void> {
    try {
      const removeFromMyListResponse =
        await this._inPlayerDataProvider.Account.deleteFromFavorites(
          mediaId as string,
        );
      if (removeFromMyListResponse.data.code === 200) {
        await StorageHelper.removeFromMyList(mediaId);
      }
    } catch (err: unknown) {
      return this.handleError(err, this.removeFromMyList.bind(this), mediaId);
    }
  }

  async getFavoritesIds(): Promise<string[]> {
    try {
      const favoritesMedia =
        await this._inPlayerDataProvider.Account.getFavorites();

      return favoritesMedia.data.collection.map((media) => media.media_id);
    } catch (err: unknown) {
      return this.handleError(err, this.getFavoritesIds.bind(this), undefined);
    }
  }

  async getWatchHistoryIds(): Promise<string[]> {
    try {
      const watchMedia =
        await this._inPlayerDataProvider.Account.getWatchHistory({});

      return watchMedia.data.collection.map((media) => media.media_id);
    } catch (err: unknown) {
      return this.handleError(
        err,
        this.getWatchHistoryIds.bind(this),
        undefined,
      );
    }
  }

  async signIn(data: IAuthRequestModel): Promise<IAuthResponseModel> {
    let result: IAuthResponseModel | undefined = undefined;

    try {
      if (!data.Username && !data.Password) {
        // Anonymous login
        result = {
          User: {
            ClientRoles: ["MEDIA_VIEW"],
            FullName: "Anonymous user",
            Id: -999,
            UserName: "Anonymous",
          },
        };
      } else {
        const sighinAccount = await this._inPlayerDataProvider.Account.signIn({
          email: data.Username || "",
          password: data.Password,
          clientId: this._inPlayerClientId,
          referrer: this._inPlayerReffer,
        });
        result = ModelsMapperHelper.toAuthResponseModel(sighinAccount.data);

        if (result?.User) {
          result.User.Products = [{ Id: -1 }];
        }
      }
      return result;
    } catch (err: unknown) {
      return this.handleError(err, this.signIn.bind(this), data);
    }
  }

  async signOut(): Promise<void> {
    try {
      if (!this._inPlayerDataProvider.Account.isAuthenticated()) {
        return Promise.resolve();
      }
      await this._inPlayerDataProvider.Account.signOut();
      return Promise.resolve();
    } catch (err) {
      return this.handleError(err, this.signOut.bind(this), undefined);
    }
  }

  async registerEmail(data: IRegisterRequestEmailModel): Promise<boolean> {
    try {
      const metadata: {
        [key: string]: string;
      } = {
        email_confirmation: data.Email,
      };
      data.Consents?.forEach((consent) => {
        if (consent.ConsentCode && consent.Accepted !== undefined) {
          metadata[consent.ConsentCode] = consent.Accepted.toString();
        }
      });
      const createdAccount = await this._inPlayerDataProvider.Account.signUp({
        fullName: data.FullName,
        email: data.Email,
        password: data.Password,
        passwordConfirmation: data.Password,
        clientId: this._inPlayerClientId,
        type: "consumer",
        referrer: this._inPlayerReffer,
        metadata,
      });

      return Promise.resolve(
        createdAccount.status >= 200 && createdAccount.status < 300,
      );
    } catch (err: unknown) {
      return this.handleError(err, this.registerEmail.bind(this), data);
    }
  }

  async refreshToken(): Promise<IAuthResponseModel> {
    try {
      const refreshedAccount =
        await this._inPlayerDataProvider.Account.refreshToken(
          this._inPlayerClientId,
        );
      return ModelsMapperHelper.toAuthResponseModel(refreshedAccount.data);
    } catch (err: unknown) {
      return this.handleError(err, this.refreshToken.bind(this), undefined);
    }
  }

  async changePassword(
    data: IChangePasswordModel,
  ): Promise<IAuthResponseModel> {
    try {
      await this._inPlayerDataProvider.Account.changePassword({
        oldPassword: data.OldPassword,
        password: data.NewPassword,
        passwordConfirmation: data.ConfirmPassword,
      });
      const refreshedAccount =
        await this._inPlayerDataProvider.Account.refreshToken(
          this._inPlayerClientId,
        );
      return ModelsMapperHelper.toAuthResponseModel(refreshedAccount.data);
    } catch (err: unknown) {
      return this.handleError(err, this.changePassword.bind(this), data);
    }
  }

  // USER
  async getProfile(): Promise<IUserModel> {
    try {
      const profile = await this.getInplayerUserAccount();

      if (!profile) {
        return Promise.reject({
          Message: "Unauthorized",
          MessageKey: "UNAUTHORIZED",
        });
      }

      return ModelsMapperHelper.toUserModel(profile);
    } catch (err: unknown) {
      return this.handleError(err, this.getProfile.bind(this), undefined);
    }
  }

  async updateProfile(data: IUserModel): Promise<IUserModel> {
    try {
      const updatedAccount =
        await this._inPlayerDataProvider.Account.updateAccount({
          fullName: data.FullName || "",
        });
      return ModelsMapperHelper.toUserModel(updatedAccount.data);
    } catch (err: unknown) {
      return this.handleError(err, this.updateProfile.bind(this), data);
    }
  }

  async deleteAccount(data: IUserDeleteAccountRequestModel): Promise<void> {
    try {
      await this._inPlayerDataProvider.Account.deleteAccount({
        password: data.Password,
        brandingId: 0,
      });
    } catch (err: unknown) {
      return this.handleError(err, this.deleteAccount.bind(this), data);
    }
  }

  async getUserSettings(): Promise<IUserSettingsModel> {
    try {
      const profile = await this.getInplayerUserAccount();

      if (!profile) {
        return Promise.reject({
          Message: "Unauthorized",
          MessageKey: "UNAUTHORIZED",
        });
      }

      const settings: IUserSettingsModel = {
        UserId: profile.id,
      };

      if (profile.metadata.language_id) {
        settings.LanguageId = `${profile.metadata.language_id}`;
      }

      return Promise.resolve(settings);
    } catch (err: unknown) {
      return this.handleError(err, this.getUserSettings.bind(this), undefined);
    }
  }

  //updateUserSettings = async (
  //  data: IUserSettingsModel,
  //): Promise<IUserSettingsModel> => {
  async updateUserSettings(
    data: IUserSettingsModel,
  ): Promise<IUserSettingsModel> {
    try {
      const profile = await this.getInplayerUserAccount();

      if (!profile) {
        return Promise.reject({
          Message: "Unauthorized",
          MessageKey: "UNAUTHORIZED",
        });
      }

      const payload: UpdateAccountData = {
        fullName: profile.full_name,
        metadata: {
          ...profile.metadata,
          language_id: `${data.LanguageId}`,
        },
      };

      const result =
        await this._inPlayerDataProvider.Account.updateAccount(payload);

      const settings: IUserSettingsModel = {
        UserId: result.data.id,
      };

      if (result.data.metadata.language_id) {
        settings.LanguageId = `${result.data.metadata.language_id}`;
      }

      return Promise.resolve(settings);
    } catch (err: any) {
      return this.handleError(err, this.updateUserSettings.bind(this), data);
    }
  }

  // CONSENTS
  async selectUserConsents(): Promise<IUserConsentModel[]> {
    try {
      //To prevent errors on register screen
      const userInfo = await this.getInplayerUserAccount().catch(
        () => undefined,
      );
      const metadata = userInfo?.metadata;
      const additionalFields =
        await this._inPlayerDataProvider.Account.getRegisterFields(
          this._inPlayerClientId,
        );

      return ModelsMapperHelper.toConsentsModel(
        additionalFields.data.collection,
        metadata as IJWPMetadata,
      );
    } catch (err: unknown) {
      return this.handleError(
        err,
        this.selectUserConsents.bind(this),
        undefined,
      );
    }
  }

  async updateUserConsent(data: IUserConsentModel): Promise<IUserConsentModel> {
    try {
      const profile = await this.getInplayerUserAccount();

      if (!profile) {
        return Promise.reject({
          Message: "Unauthorized",
          MessageKey: "UNAUTHORIZED",
        });
      }

      const { metadata, full_name } = profile;

      if (data.ConsentCode && data.Accepted !== undefined) {
        metadata[data.ConsentCode] = data.Accepted.toString() as unknown;
      }
      const editedField =
        await this._inPlayerDataProvider.Account.getRegisterFields(
          this._inPlayerClientId,
        ).then((res) =>
          res.data.collection.find((field) => field.name === data.ConsentCode),
        );

      const updatedAccount =
        await this._inPlayerDataProvider.Account.updateAccount({
          metadata: { ...(metadata as IJWPMetadata) },
          fullName: full_name,
        }).then((res) => res.data);
      return ModelsMapperHelper.toConsentModel(
        editedField as RegisterField,
        updatedAccount.metadata as IJWPMetadata,
      );
    } catch (err: unknown) {
      return this.handleError(err, this.updateUserConsent.bind(this), data);
    }
  }

  async setProgress({
    mediaId,
    progressInPercent,
  }: {
    mediaId: Identifier;
    progressInPercent: number;
  }): Promise<void> {
    try {
      await this._inPlayerDataProvider.Account.updateWatchHistory(
        mediaId as string,
        progressInPercent,
      );
    } catch (err: unknown) {
      return this.handleError(err, this.setProgress.bind(this), {
        mediaId,
        progressInPercent,
      });
    }
  }
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AssetType,
  AudioStore,
  IAppState,
  IMediaModel,
  MediaHelper,
  MediaType,
  RouteHelper,
  TimeHelper,
  useDispatch,
  useIsLoggedIn,
  useIsLoginToPlayRequired,
} from "@bms/common";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { LoginRequiredModal, MediaIcon } from "../../components";
import PlayFilledIcon from "../../resources/icons/play-filled.svg";
import CartIcon from "../../resources/icons/shopping-cart.svg";

export interface IPlayIconButtonProps {
  media?: IMediaModel;
  style?: React.CSSProperties;
  className?: string;
}

export const PlayIconButton = ({
  media,
  style,
  className,
}: IPlayIconButtonProps) => {
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedIn();
  const { t } = useTranslation();
  const isProductOwnedByUser = useSelector<IAppState, boolean>((state) =>
    MediaHelper.isUserOwnMedia(state.auth.user, media),
  );
  const isLoginToPlayRequired = useIsLoginToPlayRequired();

  const onPlayClick = useCallback(() => {
    if (media) {
      switch (media?.MediaTypeCode) {
        case AssetType.Podcast:
          dispatch(
            AudioStore.Actions.setAudioMedia({ media, isFromAutoPlay: true }),
          );
          break;
        default:
          RouteHelper.goToPlayer(media);
      }
    }
  }, [dispatch, media]);

  const onBuyClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      if (media) {
        RouteHelper.goToBuyAsset(media?.Id);
      }
    },
    [media],
  );

  if (
    !media ||
    media?.MediaTypeCode === MediaType.Article ||
    media?.MediaTypeCode === AssetType.Package ||
    media?.MediaTypeCode === MediaType.Advertisement
  ) {
    return null;
  }

  const currentDate = TimeHelper.getCurrentDate();
  const { StartDateTime, EndDateTime } = media;
  const isBeforeOrAfterAvailableDate =
    (StartDateTime && currentDate.isBefore(StartDateTime)) ||
    (EndDateTime && currentDate.isAfter(EndDateTime));
  const isAvailable = !isBeforeOrAfterAvailableDate;

  const playButton =
    media?.MediaTypeCode !== AssetType.Album ? (
      <LoginRequiredModal>
        {({ toggleModal }) => (
          <MediaIcon
            style={style}
            className={className}
            ariaLabel={t(
              isLoginToPlayRequired
                ? "LOGIN_REQUIRED_ACTION"
                : "COMMON__BUTTON_PLAY",
            )}
            onIconClick={isLoginToPlayRequired ? toggleModal : onPlayClick}
            onBackgroundClick={() => RouteHelper.goToDetails(media)}
          >
            <PlayFilledIcon />
          </MediaIcon>
        )}
      </LoginRequiredModal>
    ) : null;

  if (isProductOwnedByUser) {
    if (isAvailable && media.IsPlayable) {
      return playButton;
    }

    return null;
  } else {
    return (
      <LoginRequiredModal>
        {({ toggleModal }) => (
          <MediaIcon
            style={style}
            className={className}
            ariaLabel={t("COMMON__BUTTON_BUY_ASSET")}
            onIconClick={isLoggedIn ? onBuyClick : toggleModal}
            onBackgroundClick={() => RouteHelper.goToDetails(media)}
          >
            <CartIcon />
          </MediaIcon>
        )}
      </LoginRequiredModal>
    );
  }
};

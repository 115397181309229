/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum AuthResult {
  INVALID = "Invalid",
  OK = "Ok",
  LOCKED = "Locked",
  PASSWORD_EXPIRED = "PasswordExpired",
}

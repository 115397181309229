/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AccountData,
  CreateAccount,
  RegisterField,
  WatchHistory,
  FavoritesData,
} from "@inplayer-org/inplayer.js";

import {
  AuthResult,
  MediaImageType,
  MediaType,
  PlatformType,
  SourceType,
} from "../../../../enums";
import { BooleanHelper, TimeHelper } from "../../../../helpers";
import {
  IAuthResponseModel,
  Identifier,
  IMediaImageModel,
  IMediaListModel,
  IMediaModel,
  IMediaPlayInfoModel,
  IMediaSourcesModel,
  IUserAssetPropertiesModel,
  IUserConsentModel,
  IUserInfoModel,
  IUserModel,
} from "../../../../models";
import {
  IJwpAssetModel,
  IJwpEpisodeModel,
  IJwpMediaModel,
  IJwpPlaylistModel,
  IJwpSeasonModel,
  IJwpSourceModel,
} from "../models";

export interface IJWPMetadata {
  [key: string]: string;
}

export class ModelsMapperHelper {
  static generateImageArray(mediaId: Identifier): IMediaImageModel[] {
    const imageTypes = [
      {
        ImageTypeCode: MediaImageType.Highlights,
        width: 1920,
      },
      {
        ImageTypeCode: MediaImageType.Frame,
        width: 720,
      },
      {
        ImageTypeCode: MediaImageType.Cover,
        width: 1280,
      },
      {
        ImageTypeCode: MediaImageType.Square,
        width: 480,
      },
      {
        ImageTypeCode: MediaImageType.Background,
        width: 1920,
      },
      {
        ImageTypeCode: MediaImageType.Round,
        width: 480,
      },
      {
        ImageTypeCode: MediaImageType.Landscape,
        width: 1280,
      },
    ];

    const generateImageUrl = (
      mediaId: Identifier,
      imageType: MediaImageType,
      width: number,
    ) => {
      switch (imageType) {
        case MediaImageType.Frame:
        case MediaImageType.Highlights:
        case MediaImageType.Background:
          return `https://cdn.jwplayer.com/v2/media/${mediaId}/poster.jpg?width=${width}`;
        default:
          return `https://img.jwplayer.com/v1/media/${mediaId}/images/${imageType.toLocaleLowerCase()}.webp?width=${width}`;
      }
    };

    return imageTypes.map(({ ImageTypeCode, width }) => ({
      Id: Math.random(),
      ImageTypeCode,
      PlatformCode: PlatformType.Any,
      MediaId: mediaId,
      Url: generateImageUrl(mediaId, ImageTypeCode, width),
      Width: width,
    }));
  }

  private static _getMediaType(contentType?: string): MediaType {
    switch (contentType) {
      case "event":
        return MediaType.Event;
      case "channel":
        return MediaType.Live;
      case "series":
        return MediaType.Series;
      case "season":
        return MediaType.Season;
      case "episode":
        return MediaType.Episode;
      case "vod":
      default:
        return MediaType.Video;
    }
  }
  static toMediaModel(asset: IJwpAssetModel): IMediaModel {
    const media: IMediaModel = {
      Id: asset.mediaid,
      Description: asset.description,
      LongDescription: asset.description,
      Duration: asset.duration * 1000,
      AvailableFrom: asset.pubdate
        ? TimeHelper.getDateTime(asset.pubdate)
        : TimeHelper.getCurrentDateTime(),
      ExternalSource: "JWPlayer",
      Images: this.generateImageArray(asset.mediaid),
      IsFree:
        BooleanHelper.toBool(asset?.free) &&
        !BooleanHelper.toBool(asset?.requiresSubscription),
      IsTrialPlayable: !!asset.trailerId,
      TrialMediaId: asset.trailerId,
      MediaTypeCode: this._getMediaType(asset.contentType),
      MediaTypeDisplayName: asset.contentType || "VOD",
      Title: asset.title,
      Year: new Date(asset.pubdate * 1e3).getFullYear(),
      Products: [{ Id: -1 }],
    };

    media.IsPlayable =
      asset.sources?.findIndex((source) => source.file !== "None") >= 0;

    if (asset.genre) {
      media.Categories = [
        {
          CategoryId: -1,
          CategoryCode: asset.genre,
          CategoryName: asset.genre,
        },
      ];
    }

    return media;
  }

  static toSeasonModel(asset: IJwpSeasonModel): IMediaModel {
    const media: IMediaModel = {
      Id: asset.season_id,
      Description: asset.season_description,
      LongDescription: asset.season_description,
      Duration: asset.total_duration * 1000,
      AvailableFrom: TimeHelper.getCurrentDateTime(),
      ExternalSource: "JWPlayer",
      Images: this.generateImageArray(asset.season_id),
      IsPlayable: false,
      IsTrialPlayable: false,
      MediaTypeCode: MediaType.Season,
      MediaTypeDisplayName: "Season",
      Title: asset.season_title,
      Products: [{ Id: -1 }],
    };

    return media;
  }

  static toEpisodeModel(asset: IJwpEpisodeModel): IMediaModel | null {
    if (!asset.media_item) {
      return null;
    }

    const media: IMediaModel = this.toMediaModel(asset.media_item);
    media.IsPlayable = true;

    return media;
  }

  static toMediaListModel(entities: IJwpPlaylistModel): IMediaListModel {
    const { playlist } = entities;

    const modifiedPlaylist: IMediaModel[] = playlist.map((asset) =>
      this.toMediaModel(asset),
    );

    return {
      Entities: modifiedPlaylist,
      TotalCount: modifiedPlaylist.length,
      SourceType: SourceType.Standard,
      CacheDataValidTo: TimeHelper.getDateWithOffset(
        TimeHelper.getCurrentDateTime(),
        60,
        "seconds",
      ).toString(),
    };
  }

  static toMediaSourcesModel(sources: IJwpSourceModel[]): IMediaSourcesModel[] {
    const result = sources.map((source) => ({
      Url: source.file,
      ContentType: source.type,
      Height: source.height,
      Width: source.width,
      Label: source.label,
      Bitrate: source.bitrate,
      FileSize: source.bitrate,
      Framerate: source.framerate,
    }));

    return result;
  }

  static toMediaPlayInfoModel = (
    entities: IJwpMediaModel,
    progressInPercent?: number,
  ): IMediaPlayInfoModel => {
    const asset = entities.playlist[0];
    const sources = ModelsMapperHelper.toMediaSourcesModel(asset.sources);
    let timestamp;
    if (progressInPercent) {
      const progressInSeconds = asset.duration * progressInPercent;
      timestamp = {
        Hour: Math.floor(progressInSeconds / 3600),
        Minute: Math.floor((progressInSeconds % 3600) / 60),
        Second: Math.floor(progressInSeconds % 60),
      };
    }

    return {
      MediaId: asset.mediaid,
      Title: asset.title,
      Description: asset.description,
      MediaTypeCode: MediaType.Video,
      MediaTypeDisplayName: asset.contentType || "VOD",
      Provider: "JWPlayer",
      ContentType: "video/mp4",
      ContentUrl: asset.sources[0].file,
      Sources: sources,
      Timestamp: timestamp,
      CustomMetadata: {
        playlist: entities.playlist,
      },
    };
  };

  static toUserModel = (account: AccountData): IUserModel => {
    return {
      Id: account.id,
      UserName: account.email,
      FullName: account.full_name,
      Email: account.email,
      ClientRoles: account.roles,
    };
  };

  static toUserInfoModel = (account: AccountData): IUserInfoModel => {
    return {
      Id: account.id,
      UserName: account.email,
      FullName: account.full_name,
      Email: account.email,
      ClientRoles: account.roles,
    };
  };

  static toAuthResponseModel = (
    signInResponse: CreateAccount,
  ): IAuthResponseModel => {
    return {
      User: this.toUserInfoModel(signInResponse.account),
      AuthorizationToken: {
        AuthResult: AuthResult.OK,
        RefreshToken: signInResponse.refresh_token,
        Token: signInResponse.access_token,
        TokenExpires: signInResponse.expires.toString(),
      },
    };
  };

  static toConsentsModel = (
    collection: RegisterField[],
    userMetadata: IJWPMetadata = {},
  ): IUserConsentModel[] => {
    return collection
      .filter((field) => field.type === "checkbox")
      .map((field) => this.toConsentModel(field, userMetadata));
  };

  static toConsentModel = (
    registerFields: RegisterField,
    userMetadata: IJWPMetadata,
  ): IUserConsentModel => {
    return {
      UserId: -999,
      ConsentId: registerFields.id,
      ConsentCode: registerFields.name,
      ConsentRequired: registerFields.required,
      ConsentName: registerFields.label,
      ConsentUpToDate: false,
      ConsentContentUrl: "",
      ConsentContent: "",
      NeedConsent: registerFields.required,
      Accepted: userMetadata[registerFields.name] === "true",
    };
  };

  static toUserAssetWatvhPropertyModel = (
    data: WatchHistory,
  ): IUserAssetPropertiesModel => {
    return {
      AssetId: data.media_id,
      ProgressPercent: data.progress ? data.progress * 100 : undefined,
    };
  };

  static toUserAssetFavoritePropertyModel = (
    data: FavoritesData,
  ): IUserAssetPropertiesModel => {
    return {
      AssetId: data.media_id,
      IsFavorite: true,
    };
  };
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { GuidHelper } from "@bms/common";
import React, { memo, useContext, useEffect, useState } from "react";
import { ReactPlayerProps } from "react-player";

import { AudioContext, BaseAudioPlayer } from "context";

interface IWrappedReactPlayerProps extends ReactPlayerProps {
  setWatchProgress: () => void;
  stopWatchProgressMonitoring: () => void;
  playerRef: React.MutableRefObject<BaseAudioPlayer | null>;
}

const ReactPlayer = React.lazy(
  () => import(/* webpackChunkName: "react-player" */ "react-player"),
);

export const WrappedReactPlayer = memo(
  ({
    playerRef,
    setWatchProgress,
    stopWatchProgressMonitoring,
    ...props
  }: IWrappedReactPlayerProps) => {
    const [key, setKey] = useState<undefined | string>(undefined);
    const { handlers } = useContext(AudioContext);

    useEffect(() => {
      return () => {
        handlers.onPause();
        setWatchProgress();
        stopWatchProgressMonitoring();
        setKey(GuidHelper.newGuid());
      };
    }, [setWatchProgress, stopWatchProgressMonitoring]);

    return (
      <ReactPlayer
        key={key}
        ref={(node) => {
          if (node) {
            playerRef.current = node;
          }
        }}
        {...props}
      />
    );
  },
);

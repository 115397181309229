/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum ContentStatus {
  Ready = "READY",
  Queued = "QUEUED",
  Processing = "PROCESSING",
  Error = "ERROR",
}

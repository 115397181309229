/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IAuthCodeRequestModel } from "models/Auth/IAuthCodeRequestModel";
import { ICoinsPaymentRequestModel } from "models/CryptoCoins/ICoinsPaymentRequestModel";
import { ICoinsPaymentResponseModel } from "models/CryptoCoins/ICoinsPaymentResponseModel";
import { IConfirmPaymentRequestModel } from "providers/PaymentProvider/models";

import { ScreenType } from "../../../enums";
import { StorageHelper } from "../../../helpers";
import {
  EpgDay,
  IAssetAgeRestrictionModel,
  IAssetContentModel,
  IAssetContentTranscodeRequest,
  IAssetImageModel,
  IAssetInAssetModel,
  IAssetInAssetSearchResponseModel,
  IAssetListModel,
  IAssetModel,
  IAssetPriceListModel,
  IAssetPriceModel,
  IAssetPriceSearchFilterModel,
  IAssetPurchasePeriodTypeModel,
  IAssetSearchFilterModel,
  IAssetsInAssetSearchFilterModel,
  IAuthRequestModel,
  IAuthResponseModel,
  IAuthVerifyLoginModel,
  ICatchupInsertModel,
  ICatchupInsertResponseModel,
  IChangePasswordModel,
  IConfigurationBrandingModel,
  IConfigurationModel,
  IConfigurationTranslationsModel,
  IConfirmAccountWithPasswordModel,
  ICryptoCoinPriceListModel,
  ICurrencyModel,
  Identifier,
  IErrorModel,
  IForgotPasswordModel,
  IInsertAssetRequestModel,
  IInsertAssetResponseModel,
  IInviteManyUsersModel,
  ILoginCodeModel,
  IMediaCategoryListModel,
  IMediaListModel,
  IMediaListOptionsModel,
  IMediaModel,
  IMediaOptionsModel,
  IMediaPaymentRequestModel,
  IMediaPaymentResponseModel,
  IMediaPlaybackProgressModel,
  IMediaPlayInfoModel,
  IMediaPlayInfoOptionsModel,
  IMediaPurchaseOfferModel,
  IMediaSearchFilterModel,
  IMediaSearchMediaInMediaFilterModel,
  IMediaSearchStateModel,
  IMediaStatisticsOptionsModel,
  IPaymentListModel,
  IPaymentModel,
  IPaymentOptionsModel,
  IPaymentSearchFilterModel,
  IPaymentStatusModel,
  IPaymentTypeMappingAndOptionsModel,
  IRegisterConfirmEmailModel,
  IRegisterRequestEmailModel,
  IRemoveManyUsersModel,
  IResendConfirmationByUserModel,
  IResetForgotPasswordModel,
  IResetPasswordModel,
  IScreenModel,
  IUserAssetPropertiesModel,
  IUserAssetPurchasesListModel,
  IUserAssetPurchasesSearchFilterModel,
  IUserConsentModel,
  IUserDeleteAccountRequestModel,
  IUserDeviceModel,
  IUserInAssetModel,
  IUserInAssetRoleModel,
  IUserInfoModel,
  IUserModel,
  IUserProductModel,
  IUserPurchasesAggregatedModel,
  IUserRatingCategory,
  IUserRatingModel,
  IUserRequestOptionsModel,
  IUserSettingsModel,
  IUsersInAssetListModel,
  IUsersInAssetSearchFilterModel,
  IUsersListModel,
  IUsersSearchFilterModel,
  IUserWalletModel,
} from "../../../models";
import { StorageKey, StorageManager } from "../../../services";
import { AuthStore, dispatch } from "../../../store";
import { ConfigurationService } from "../Common";
import { IDataProvider } from "../IDataProvider";
import { InternalDataProvider } from "../Internal";

import {
  GET_MEDIA_PLAY_INFO_QUERY,
  SEARCH_COLLECTION_QUERY,
  SEARCH_MEDIA_QUERY,
} from "./gql";
import { ModelsMapperHelper } from "./helpers";
import {
  IAxinomAssetModel,
  ICollectionModel,
  IIdpConfigurationResponseModel,
  IMovieInfoModel,
  ISignInWithCredentialsResponseModel,
  ITokenResponseModel,
  SignInResponseCode,
} from "./models";
import { AxinomService } from "./services";

export class AxinomDataProvider implements IDataProvider {
  configurationService = new ConfigurationService();
  axinomService = new AxinomService();

  private _internalDataProvider = new InternalDataProvider();

  async initSession() {
    const session = await StorageManager.getValue(StorageKey.session);

    if (!session) {
      dispatch(AuthStore.Actions.signInAnonymous());
    }
  }

  async signIn(data: IAuthRequestModel): Promise<IAuthResponseModel> {
    if (data.Username && data.Password) {
      return this.axinomService
        .getAxinomIdpConfig()
        .toPromise()
        .then(async (idpConfigRes: IIdpConfigurationResponseModel) => {
          return this.axinomService
            .axinomSignIn({
              connectionId:
                idpConfigRes.availableIdentityProviders?.[0].idpConnectionId ||
                "",
              email: data.Username || "",
              password: data.Password || "",
            })
            .toPromise()
            .then(async (response: ISignInWithCredentialsResponseModel) => {
              if (response.code === SignInResponseCode.SUCCESS) {
                return this.axinomService
                  .axinomToken()
                  .toPromise()
                  .then((res: ITokenResponseModel) => {
                    return ModelsMapperHelper.toSignInModel(res);
                  });
              } else {
                return Promise.reject(response.code);
              }
            });
        });
    }
    return Promise.reject();
  }

  getDefaultBranding(): IConfigurationBrandingModel | undefined {
    return undefined;
  }

  getDefaultTranslations(): IConfigurationTranslationsModel | undefined {
    return this._internalDataProvider._defaultTranslations;
  }

  getResource(_resourceKey: string): any {
    return null;
  }

  async getConfiguration(): Promise<IConfigurationModel | undefined> {
    let config = await StorageManager.getValue(StorageKey.configuration);
    const configInfo = await this.configurationService
      .getConfigurationInfo()
      .toPromise();

    if (!config || (config && configInfo.Version !== config.VersionNumber)) {
      config = await this.configurationService
        .getConfiguration(configInfo.Url)
        .toPromise();
    }

    return config;
  }

  async getConfigurationOffline(): Promise<IConfigurationModel | undefined> {
    const configurationFromCache = await StorageManager.getValue(
      StorageKey.configuration,
    );
    if (configurationFromCache) {
      return configurationFromCache;
    }
    return undefined;
  }

  async getScreenConfiguration(
    _screenType: ScreenType,
    _screenId?: number,
  ): Promise<IScreenModel | undefined> {
    return Promise.resolve(undefined);
  }

  async health(): Promise<any> {
    return Promise.resolve(true);
  }

  async getMedia(options: IMediaOptionsModel): Promise<IMediaModel> {
    try {
      const variables = {
        movieId: options.MediaId,
        episodeId: options.MediaId,
        seasonId: options.MediaId,
        tvshowId: options.MediaId,
      };

      return await this.axinomService
        .search<IAxinomAssetModel>(SEARCH_MEDIA_QUERY, variables)
        .then((result) => {
          if (result && result.data) {
            const response = ModelsMapperHelper.toMediaModel(result.data);
            return Promise.resolve(response);
          } else {
            return Promise.reject("Missing data");
          }
        });
    } catch (error) {
      return Promise.reject(error as IErrorModel);
    }
  }

  async getMediaPlayInfo(
    options: IMediaPlayInfoOptionsModel,
  ): Promise<IMediaPlayInfoModel> {
    try {
      const variables = {
        movieId: options.MediaId,
        episodeId: options.MediaId,
        seasonId: options.MediaId,
        tvshowId: options.MediaId,
      };

      return await this.axinomService
        .search<IMovieInfoModel>(GET_MEDIA_PLAY_INFO_QUERY, variables)
        .then((result) => {
          if (result && result.data) {
            return Promise.resolve(
              ModelsMapperHelper.toMediaPlayInfoModel(
                result.data,
                options.StreamType,
              ),
            );
          } else {
            return Promise.reject("Missing data");
          }
        });
    } catch (error) {
      return Promise.reject(error as IErrorModel);
    }
  }

  async searchMedia(
    _: IMediaSearchFilterModel,
  ): Promise<IMediaSearchStateModel> {
    return Promise.reject("Not implemented");
  }

  async getMediaList(
    options: IMediaListOptionsModel,
  ): Promise<IMediaListModel> {
    try {
      const variables = {
        id: `collection-${options.MediaListId}`,
      };

      return await this.axinomService
        .search<ICollectionModel>(SEARCH_COLLECTION_QUERY, variables)
        .then((result) => {
          if (result && result.data) {
            const response = ModelsMapperHelper.toMediaListModel(
              result.data.collection.items,
            );

            return Promise.resolve(response);
          } else {
            return Promise.reject("Missing data");
          }
        });
    } catch (error) {
      return Promise.reject(error as IErrorModel);
    }
  }

  async getMediaCategories(): Promise<IMediaCategoryListModel> {
    return Promise.reject("Not implemented");
  }

  // TODO Check if needed
  async getMediaStatistics(_: IMediaStatisticsOptionsModel): Promise<any> {
    return Promise.reject("Not implemented");
  }

  async getEpgDays(): Promise<EpgDay[]> {
    return [];
  }

  async selectMediaPurchaseOffers(
    _: Identifier,
  ): Promise<IMediaPurchaseOfferModel[]> {
    return Promise.reject("Not implemented");
  }

  async setProgress(_: IMediaPlaybackProgressModel): Promise<void> {
    return Promise.reject("Not implemented");
  }

  async getUserAssetsProperties(): Promise<IUserAssetPropertiesModel[]> {
    return Promise.reject("Not implemented");
  }

  isOnMyList(_: Identifier): Promise<boolean> {
    return Promise.reject("Not implemented");
  }

  addToMyList(_: Identifier): Promise<void> {
    return Promise.reject("Not implemented");
  }

  removeFromMyList(_: Identifier): Promise<void> {
    return Promise.reject("Not implemented");
  }

  getMyListIds(): Promise<Identifier[]> {
    return StorageHelper.getMyListIds();
  }

  async buyMedia(
    _: IMediaPaymentRequestModel,
  ): Promise<IMediaPaymentResponseModel> {
    return Promise.reject("Not implemented");
  }

  async getProducts(): Promise<IUserProductModel[]> {
    return Promise.reject("Not implemented");
  }

  async searchMediaInMedia(
    _: IMediaSearchMediaInMediaFilterModel,
  ): Promise<IMediaListModel> {
    return Promise.reject("Not implemented");
  }

  async signOut(_: IUserDeviceModel): Promise<void> {
    return this.axinomService.axinomSignOut().toPromise();
  }

  async forgotPassword(_: IForgotPasswordModel): Promise<boolean> {
    return Promise.reject("Not implemented");
  }

  async resetForgotPassword(_: IResetForgotPasswordModel): Promise<boolean> {
    return Promise.reject("Not implemented");
  }

  async registerEmail(_: IRegisterRequestEmailModel): Promise<boolean> {
    return Promise.reject("Not implemented");
  }

  async registerConfirmEmail(
    _: IRegisterConfirmEmailModel,
  ): Promise<IAuthResponseModel> {
    return Promise.reject("Not implemented");
  }

  async registerConfirmAccount(
    _: IConfirmAccountWithPasswordModel,
  ): Promise<IAuthResponseModel> {
    return Promise.reject("Not implemented");
  }

  async resendConfirmationEmailByUser(
    _: IResendConfirmationByUserModel,
  ): Promise<void> {
    return Promise.reject("Not implemented");
  }

  async refreshToken(
    _refreshToken: string,
    _device: IUserDeviceModel,
  ): Promise<IAuthResponseModel> {
    return Promise.reject("Not implemented");
  }

  async changePassword(_: IChangePasswordModel): Promise<IAuthResponseModel> {
    return Promise.reject("Not implemented");
  }

  async resetPassword(_: IResetPasswordModel): Promise<boolean> {
    return Promise.reject("Not implemented");
  }

  async validateConfirmationToken(_: string): Promise<void> {
    return Promise.reject("Not implemented");
  }
  // LOGIN CODE
  async linkLoginCode(_: ILoginCodeModel): Promise<ILoginCodeModel> {
    return Promise.reject("Not implemented");
  }

  async getLoginCode(): Promise<IAuthCodeRequestModel> {
    return Promise.reject("Not implemented");
  }

  async verifyLogin(_: IAuthVerifyLoginModel): Promise<IAuthResponseModel> {
    return Promise.reject("Not implemented");
  }

  // USER
  async getProfile(_: IUserRequestOptionsModel): Promise<IUserModel> {
    return Promise.reject("Not implemented");
  }

  async syncUser(): Promise<IUserInfoModel> {
    return Promise.reject("Not implemented");
  }

  async updateProfile(_: IUserModel): Promise<IUserModel> {
    return Promise.reject("Not implemented");
  }

  async deleteAccount(_: IUserDeleteAccountRequestModel): Promise<void> {
    return Promise.reject("Not implemented");
  }

  async browseUsers(_: IUsersSearchFilterModel): Promise<IUsersListModel> {
    return Promise.reject("Not implemented");
  }

  async getUserWallet(): Promise<IUserWalletModel[]> {
    return Promise.reject("Not implemented");
  }
  async getUserSettings(): Promise<IUserSettingsModel> {
    return Promise.reject("Not implemented");
  }

  async updateUserSettings(_: IUserSettingsModel): Promise<IUserSettingsModel> {
    return Promise.reject("Not implemented");
  }

  // USER IN ASSET
  async getUserInAssetRoles(): Promise<IUserInAssetRoleModel[]> {
    return Promise.reject("Not implemented");
  }

  async inviteManyUsers(_: IInviteManyUsersModel): Promise<void> {
    return Promise.reject("Not implemented");
  }

  async searchUsersInAsset(
    _: IUsersInAssetSearchFilterModel,
  ): Promise<IUsersInAssetListModel> {
    return Promise.reject("Not implemented");
  }

  async removeUserFromAsset(
    _: IUserInAssetModel & { AssetId: number },
  ): Promise<void> {
    return Promise.reject("Not implemented");
  }

  async removeManyUsersFromAsset(_: IRemoveManyUsersModel): Promise<void> {
    return Promise.reject("Not implemented");
  }

  // CONSENTS
  async selectUserConsents(): Promise<IUserConsentModel[]> {
    return Promise.reject("Not implemented");
  }

  async getUserConsent(_: string): Promise<string> {
    return Promise.reject("Not implemented");
  }

  async updateUserConsent(_: IUserConsentModel): Promise<IUserConsentModel> {
    return Promise.reject("Not implemented");
  }

  async updateUserConsents(
    _: IUserConsentModel[],
  ): Promise<IUserConsentModel[]> {
    return Promise.reject("Not implemented");
  }

  // PAYMENT
  async getPayment(_: number): Promise<IPaymentModel> {
    return Promise.reject("Not implemented");
  }

  async searchPayment(
    _: IPaymentSearchFilterModel,
  ): Promise<IPaymentListModel> {
    return Promise.reject("Not implemented");
  }

  async getPaymentOptions(): Promise<IPaymentOptionsModel> {
    return Promise.reject("Not implemented");
  }

  async getPaymentTypeMappingAndOptions(): Promise<IPaymentTypeMappingAndOptionsModel> {
    return Promise.reject("Not implemented");
  }

  async getByKey(_: string): Promise<IPaymentModel> {
    return Promise.reject("Not implemented");
  }

  async checkStatusByKey(_: string): Promise<IPaymentStatusModel> {
    return Promise.reject("Not implemented");
  }

  async confirmPayment(
    _: IConfirmPaymentRequestModel,
  ): Promise<IPaymentStatusModel> {
    return Promise.reject("Not implemented");
  }

  // SUBSCRIPTIONS
  async cancelSubscription(_: number): Promise<IUserAssetPurchasesListModel> {
    return Promise.reject("Not implemented");
  }

  async reactivateSubscription(
    _: number,
  ): Promise<IUserAssetPurchasesListModel> {
    return Promise.reject("Not implemented");
  }

  async changeSubscriptionPaymentMethod(
    _: number,
  ): Promise<IMediaPaymentResponseModel> {
    return Promise.reject("Not implemented");
  }

  async searchUserAssetPurchases(
    _: IUserAssetPurchasesSearchFilterModel,
  ): Promise<IUserAssetPurchasesListModel> {
    return Promise.reject("Not implemented");
  }

  //ASSETS
  async searchAsset(_: IAssetSearchFilterModel): Promise<IAssetListModel> {
    return Promise.reject("Not implemented");
  }

  async getCryptoCoinPriceList(): Promise<ICryptoCoinPriceListModel[]> {
    return Promise.reject("Not implemented");
  }

  async buyCryptoCoins(
    _: ICoinsPaymentRequestModel,
  ): Promise<ICoinsPaymentResponseModel> {
    return Promise.reject("Not implemented");
  }

  async getUserPurchasesAggregated(): Promise<IUserPurchasesAggregatedModel> {
    return Promise.reject("Not implemented");
  }

  async createAsset(
    _: IInsertAssetRequestModel,
  ): Promise<IInsertAssetResponseModel> {
    return Promise.reject("Not implemented");
  }

  async requestAssetContentTranscode(
    _: IAssetContentTranscodeRequest,
  ): Promise<IAssetContentModel> {
    return Promise.reject("Not implemented");
  }

  async getAsset(_: number): Promise<IAssetModel> {
    return Promise.reject("Not implemented");
  }

  async updateAsset(_: IAssetModel): Promise<any> {
    return Promise.reject("Not implemented");
  }

  async insertAssetImage(_: IAssetImageModel): Promise<IAssetModel> {
    return Promise.reject("Not implemented");
  }

  async updateAssetImage(_: IAssetImageModel): Promise<IAssetModel> {
    return Promise.reject("Not implemented");
  }

  async addAssetContent(_: IAssetContentModel): Promise<IAssetContentModel> {
    return Promise.reject("Not implemented");
  }

  async updateAssetContent(_: IAssetContentModel): Promise<IAssetContentModel> {
    return Promise.reject("Not implemented");
  }

  async selectAgeRestriction(): Promise<IAssetAgeRestrictionModel[]> {
    return Promise.reject("Not implemented");
  }

  async selectCurrency(): Promise<ICurrencyModel[]> {
    return Promise.reject("Not implemented");
  }

  async selectPurchasePeriodType(): Promise<IAssetPurchasePeriodTypeModel[]> {
    return Promise.reject("Not implemented");
  }

  async saveAssetInAssetCollection(
    _: IAssetInAssetModel[],
  ): Promise<IAssetInAssetModel[]> {
    return Promise.reject("Not implemented");
  }

  async saveAssetPriceCollection(
    _: IAssetPriceModel[],
  ): Promise<IAssetPriceModel[]> {
    return Promise.reject("Not implemented");
  }

  async getAssetPriceCollection(_: number): Promise<IAssetPriceModel> {
    return Promise.reject("Not implemented");
  }

  async searchAssetPriceCollection(
    _: IAssetPriceSearchFilterModel,
  ): Promise<IAssetPriceListModel> {
    return Promise.reject("Not implemented");
  }

  async insertCatchup(
    _: ICatchupInsertModel,
  ): Promise<ICatchupInsertResponseModel> {
    return Promise.reject("Not implemented");
  }

  async searchAssetsInAssets(
    _: IAssetsInAssetSearchFilterModel,
  ): Promise<IAssetInAssetSearchResponseModel> {
    return Promise.reject("Not implemented");
  }

  async selectRatingCategories(): Promise<IUserRatingCategory[]> {
    return Promise.resolve([]);
  }

  async insertUserRating(_: IUserRatingModel): Promise<void> {
    return Promise.reject("Not implemented");
  }
}

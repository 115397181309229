/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IApplicationHeaderScreensModel,
  IApplicationMenuScreenModel,
  IAppState,
  ScreenType,
} from "@bms/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  AppHeaderVisibility,
  ApplicationHeader,
  ApplicationMenu,
} from "components";
import RightArrowIcon from "resources/icons/right-arrow.svg";

import { HeaderLogo, HeaderSignIn, HeaderUserInfo } from "..";

import "./DesktopHeader.scss";

interface IDesktopHeader {
  configurationScreens?: IApplicationHeaderScreensModel;
  visibility?: AppHeaderVisibility;
  showBackButton?: boolean;
}

export const DesktopHeader = ({
  configurationScreens,
  visibility = AppHeaderVisibility.Default,
  showBackButton = false,
}: IDesktopHeader) => {
  const auth = useSelector((state: IAppState) => state.auth);
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <header className="DesktopHeader">
      {showBackButton && (
        <button
          className="DesktopHeader__BackButton"
          onClick={history.goBack}
          aria-label={t("COMMON__BUTTON_GO_BACK")}
          title={t("COMMON__BUTTON_GO_BACK")}
        >
          <RightArrowIcon />
        </button>
      )}
      <div className="DesktopHeader__Left">
        <HeaderLogo />
        {!!(visibility & AppHeaderVisibility.Menu) && (
          <ApplicationMenu
            screen={
              configurationScreens?.[
                ScreenType.ApplicationMenu
              ] as IApplicationMenuScreenModel
            }
          />
        )}
      </div>
      <div className="DesktopHeader__Right">
        {!!(visibility & AppHeaderVisibility.Header) && (
          <ApplicationHeader
            items={
              configurationScreens?.[ScreenType.ApplicationHeader]?.Components
            }
          />
        )}
        {!!(visibility & AppHeaderVisibility.UserInfo) && (
          <HeaderUserInfo user={auth.user} />
        )}
        {!!(visibility & AppHeaderVisibility.SignIn) && <HeaderSignIn />}
      </div>
    </header>
  );
};

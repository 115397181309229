/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum SourceType {
  Standard = "STANDARD",
  Favorites = "MY_FAVORITES",
  RecentlyWatched = "RECENTLY_WATCHED",
  Purchased = "PURCHASED",
  Epg = "EPG",
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Observable, Observer } from "rxjs";

import { ICryptoCoinPriceListModel } from "models";

import { HTTP_METHOD } from "../../../../constants";
import { AxiosSubscriber, PromisifiableBase } from "../../../../services";

export class CryptoCoinPriceListsService extends PromisifiableBase {
  get url(): string {
    return "/CryptoCoinPriceLists";
  }

  public getCryptoCoinPriceList = (): Observable<ICryptoCoinPriceListModel[]> =>
    new Observable(
      (observer: Observer<ICryptoCoinPriceListModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${this.url}/Select`,
        }),
    );
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum PlatformType {
  Any = "ANY",
  AndroidPhone = "ANDROID_PHONE",
  AndroidTablet = "ANDROID_TABLET",
  AndroidTV = "ANDROID_TV",
  AppleTV = "APPLE_TV",
  iOSPhone = "IOS_PHONE",
  iPad = "IPAD",
  Tizen = "TIZEN",
  Web = "WEB",
  WebOS = "WEB_OS",
  Roku = "ROKU",
  Vizio = "VIZIO",
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ANONYMOUS_ID, USER_ROLES } from "../../constants";
import { useSelector } from "../../store";

export const useIsUserLicensed = () =>
  useSelector((state) => {
    // Check if user is authenticated
    if (!state.auth.user?.Id) {
      return false;
    }
    const isAnonymous = state.auth.user?.Id
      ? state.auth.user.Id === ANONYMOUS_ID
      : false;
    // Check if anonymous user has right to view media
    if (isAnonymous) {
      return (
        state.auth.user?.ClientRoles?.includes(USER_ROLES.MEDIA_VIEW) ?? false
      );
    }

    return true;
  });

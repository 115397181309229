/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
const PREFIX = "SEARCH";

const SET_FILTER = `${PREFIX}_SET_FILTER`;
const RESET = `${PREFIX}_RESET`;

export const ActionTypes = {
  SET_FILTER,
  RESET,
};

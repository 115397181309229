/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum MediaStreamType {
  Main = "MAIN",
  Trial = "TRIAL",
  File = "FILE",
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IAssetTimestampModel,
  IMediaPlayInfoModel,
  IMediaStateModel,
  IStateModel,
  MediaStreamType,
} from "@bms/common";

export enum PlayerControllerType {
  THEOPlayer = "THEOPLAYER",
  VideoJsPlayer = "VIDEOJSPLAYER",
  Mycujoo = "MYCUJOO",
  JWPlayer = "JWPLAYER",
}

export enum PlayerUIType {
  Standalone = "STANDALONE",
  Custom = "CUSTOM",
}

export interface VideoPlayerProps {
  autoplay?: boolean;
  media: IMediaStateModel;
  streamType?: MediaStreamType;
  mediaPlayInfo: IStateModel<IMediaPlayInfoModel>;
  continueWatchingTimestamp?: IAssetTimestampModel;
}

export interface PlayerRouteState {
  skipContinueWatching?: boolean;
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum AssetEventType {
  MovieNight = "MOVIE_NIGHT",
  Live = "LIVE",
  VideoPremiere = "VIDEO_PREMIERE",
}

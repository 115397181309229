/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AppConfig } from "../../app";

import { AxinomDataProvider } from "./Axinom";
import { IDataProvider } from "./IDataProvider";
import { InternalDataProvider } from "./Internal";
import { JWPlayerDataProvider } from "./JWPlayer";
import { MotoDataProvider } from "./Moto/MotoDataProvider";

let dataProvider: IDataProvider;

// Add providers for projects here base on env value
switch (AppConfig.DataProvider) {
  case "JWPLAYER":
    dataProvider = new JWPlayerDataProvider();
    break;
  case "AXINOM":
    dataProvider = new AxinomDataProvider();
    break;
  case "MOTO":
    dataProvider = new MotoDataProvider();
    break;
  default:
    dataProvider = new InternalDataProvider();
    break;
}

export const DataProvider: IDataProvider = dataProvider;

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { gql } from "@apollo/client";

export const ACCOUNT_TOKEN_MUTATION = gql`
  mutation GenerateServiceAccountToken(
    $input: AuthenticateServiceAccountInput!
  ) {
    authenticateServiceAccount(input: $input) {
      accessToken
      expiresInSeconds
      __typename
    }
  }
`;

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

export enum DownloadMediaStatus {
  DOWNLOADED,
  IN_PROGRESS,
  QUEUED,
  FAILED,
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AssetType,
  AudioStore,
  IMediaModel,
  MediaStreamType,
  ROUTES,
  UrlHelper,
  useDispatch,
  useIsLoginToPlayRequired,
} from "@bms/common";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { LoginRequiredModal } from "components";

import FilmIcn from "../../resources/icons/film.svg";
import { MediaButton } from "../MediaButton";

export interface ITrailerButtonProps {
  media?: IMediaModel;
  loading?: boolean;
}

export const TrailerButton = ({ media, loading }: ITrailerButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoginToPlayRequired = useIsLoginToPlayRequired();
  const isGeoBlocked = useMemo(() => media?.IsGeoBlocked, [media]);
  const isAudio = useMemo(
    () =>
      [AssetType.Podcast, AssetType.Album].indexOf(
        media?.MediaTypeCode as AssetType,
      ) > -1,
    [media],
  );
  const urlToVideoTrial = useMemo(
    () =>
      UrlHelper.parametrize(
        `${ROUTES.PLAYER_SCREEN}/${media?.TrialMediaId || media?.Id}`,
        {
          streamType: MediaStreamType.Trial,
        },
      ),
    [media],
  );

  const setTrialAudio = useCallback(
    () =>
      isAudio &&
      dispatch(
        AudioStore.Actions.setAudioMedia({
          media,
          streamType: MediaStreamType.Trial,
        }),
      ),
    [dispatch, media, isAudio],
  );

  if (!media) {
    return null;
  }

  if (!media.IsTrialPlayable) {
    if (loading) {
      return <MediaButton loading={loading} variant="transparent" />;
    }
    return null;
  }

  if (isAudio) {
    return (
      <LoginRequiredModal>
        {({ openModal }) => (
          <MediaButton
            icon={<FilmIcn />}
            iconElevated
            variant="transparent"
            ariaLabel={t("COMMON__BUTTON_LISTEN_TRAILER")}
            onClick={isLoginToPlayRequired ? openModal : setTrialAudio}
          >
            {t("COMMON__BUTTON_LISTEN_TRAILER")}
          </MediaButton>
        )}
      </LoginRequiredModal>
    );
  }

  if (isGeoBlocked) {
    return (
      <MediaButton
        icon={<FilmIcn />}
        iconElevated
        variant="transparent"
        ariaLabel={t("GEOBLOCKING__TRAILER_UNAVAILABLE")}
        disabled
      >
        {t("GEOBLOCKING__TRAILER_UNAVAILABLE")}
      </MediaButton>
    );
  } else {
    return (
      <Link to={urlToVideoTrial}>
        <MediaButton icon={<FilmIcn />} iconElevated variant="transparent">
          {t("COMMON__BUTTON_WATCH_TRAILER")}
        </MediaButton>
      </Link>
    );
  }
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { Observable, Observer } from "rxjs";

import { HTTP_METHOD } from "../../../../constants";
import { IBannerCollectionModel } from "../models/IBannerModel";
import { IEntityModel } from "../models/IEntityModel";
import { IEventModel } from "../models/IEventModel";

import { AxiosSubscriber } from "./AxiosSubscriber";

export class MediaService {
  public getMediaList = (
    url: string,
  ): Observable<IBannerCollectionModel | IEventModel | IEntityModel> =>
    new Observable(
      (
        observer: Observer<IBannerCollectionModel | IEventModel | IEntityModel>,
      ) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${process.env.REACT_APP_API_URL}.cms${url}`,
        }),
    );

  public getMedia = <T>(url: string): Observable<T> =>
    new Observable(
      (observer: Observer<T>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${process.env.REACT_APP_API_URL}.cms${url}`,
        }),
    );

  public getMediaPlayInfo = <T>(url: string): Observable<T> =>
    new Observable(
      (observer: Observer<T>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.GET,
          url: `${process.env.REACT_APP_API_URL}.streaming${url}`,
        }),
    );
}

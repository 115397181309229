/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import i18next from "i18next";

export enum MottoSourceName {
  BANNER_HOMEPAGE = "banner_homepage",
  BANNER_HOMEPAGE_MIDDLE = "banner_homepage_middle",
  EVENT_UPCOMING = "event_live_upcoming",
  CLUBS = "clubs",
  COMPETITIONS = "competitions",
  EVENT_RECENT = "event_recent_matches",
  EVENT_RECENT_INTERNATIONAL = "event_recent_international_matches",
}

const bannerHomepageParams = {
  bannerCollectionId: "homepage",
};

const bannerHomepageMiddleParams = {
  bannerCollectionId: "homepage-middle",
};

const eventUpcomingParams = {
  pageSize: 25,
  filter:
    "type_id:(match+OR+national_match)+AND+status:(EVENT_STATUS_STARTED+or+EVENT_STATUS_SCHEDULED+or+EVENT_STATUS_PRESCHEDULED)+and+time>=now-6h",
  orderBy: "start_time+asc",
};

const recentMatchesParams = {
  pageSize: 25,
  orderBy: "start_time+desc",
  filter: "status:EVENT_STATUS_FINISHED and type_id:match",
};

const recentInternationalMatchesParams = {
  pageSize: 25,
  orderBy: "start_time+desc",
  filter: "status:EVENT_STATUS_FINISHED and type_id:national_match",
};

const competitionsParams = {
  pageSize: 25,
  orderBy: "name+asc",
  filter: "type_id:season+AND+fields.is_latest:true",
};

const clubsParams = {
  pageSize: 25,
  orderBy: "name+asc",
  filter: "type_id:club",
};

const getQueryParams = (sourceName: MottoSourceName) => {
  switch (sourceName) {
    case MottoSourceName.BANNER_HOMEPAGE:
      return bannerHomepageParams;
    case MottoSourceName.BANNER_HOMEPAGE_MIDDLE:
      return bannerHomepageMiddleParams;
    case MottoSourceName.EVENT_UPCOMING:
      return eventUpcomingParams;
    case MottoSourceName.EVENT_RECENT:
      return recentMatchesParams;
    case MottoSourceName.EVENT_RECENT_INTERNATIONAL:
      return recentInternationalMatchesParams;
    case MottoSourceName.CLUBS:
      return clubsParams;
    case MottoSourceName.COMPETITIONS:
      return competitionsParams;
  }
};

const parseParams = (params: object) =>
  encodeURIComponent(JSON.stringify(params)).replace(/%2B/g, "+");

export const getURLWithQueryParams = (
  sourceName: MottoSourceName,
  search?: string,
) => {
  const params = parseParams({
    ...getQueryParams(sourceName),
    locale: i18next.language,
    ...(search && { search }),
  });
  let baseUrl = "";

  switch (sourceName) {
    case MottoSourceName.BANNER_HOMEPAGE:
    case MottoSourceName.BANNER_HOMEPAGE_MIDDLE:
      baseUrl = ".banner.v1.BannerCollectionService/GetBannerCollection?";
      break;
    case MottoSourceName.EVENT_UPCOMING:
    case MottoSourceName.EVENT_RECENT:
    case MottoSourceName.EVENT_RECENT_INTERNATIONAL:
      baseUrl = ".event.v1.EventService/ListEvents?";
      break;
    case MottoSourceName.CLUBS:
    case MottoSourceName.COMPETITIONS:
      baseUrl = ".entity.v1.EntityService/ListEntities?";
      break;
  }

  return `${baseUrl}encoding=json&message=${params}`;
};

export const getMediaURLWithQueryParams = (mediaId?: string) => {
  const params = parseParams({ eventId: mediaId, locale: i18next.language });
  const baseUrl = ".event.v1.EventService/GetEvent?";

  return `${baseUrl}encoding=json&message=${params}`;
};

export const getSeasonURLWithQueryParams = (seasonId: string) => {
  const params = parseParams({
    bannerCollectionId: `season_${seasonId}`,
    locale: i18next.language,
  });
  const baseUrl = ".banner.v1.BannerCollectionService/GetBannerCollection?";

  return `${baseUrl}encoding=json&message=${params}`;
};

export const getClubURLWithQueryParams = () => {
  const params = parseParams({
    bannerCollectionId: "club_default",
    locale: i18next.language,
  });
  const baseUrl = ".banner.v1.BannerCollectionService/GetBannerCollection?";

  return `${baseUrl}encoding=json&message=${params}`;
};

export const getVideoURLWithQueryParams = (videoId?: string) => {
  const params = parseParams({
    videoId,
  });
  const baseUrl = ".video.v1.VideoService/GetVideo?";

  return `${baseUrl}encoding=json&message=${params}`;
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const GET_CONFIGURATION = "GET_CONFIGURATION";
export const GET_CONFIGURATION_SUCCESS = "GET_CONFIGURATION_SUCCESS";
export const GET_CONFIGURATION_FAILURE = "GET_CONFIGURATION_FAILURE";

export const GET_CONFIGURATION_OFFLINE = "GET_CONFIGURATION_OFFLINE";
export const GET_CONFIGURATION_OFFLINE_SUCCESS =
  "GET_CONFIGURATION_OFFLINE_SUCCESS";
export const GET_CONFIGURATION_OFFLINE_FAILURE =
  "GET_CONFIGURATION_OFFLINE_FAILURE";

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { StorageHelper } from "@bms/common";
import React, { Suspense, useEffect, useState } from "react";

import { LoaderSpinner } from "../../components";

import "./Splash.scss";

export const Splash = () => {
  const [isLogoImagePainted, setIsLogoImagePainted] = useState(false);
  const [brandingLogo, setBrandingLogo] = useState("");

  useEffect(() => {
    (async () => {
      const brandingLogo = await StorageHelper.getBrandingLogo();
      if (brandingLogo) {
        setBrandingLogo(brandingLogo);
      }
    })();
  }, []);

  const DefaultLoader = () => (
    <div className="Splash__container">
      <LoaderSpinner width={75} height={75} />
    </div>
  );

  const BrandingLoader = () => {
    if (!brandingLogo) {
      return <DefaultLoader />;
    }

    return (
      <div className="Splash__container">
        <div className="SplashBranding">
          <img
            className="Splash__logo"
            src={brandingLogo}
            alt="app logo"
            onLoad={() => setIsLogoImagePainted(true)}
          />
          <div className="Splash__spinner">
            {isLogoImagePainted && <LoaderSpinner width={75} height={75} />}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="Splash">
      <Suspense fallback={<DefaultLoader />}>
        <BrandingLoader />
      </Suspense>
    </div>
  );
};

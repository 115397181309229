/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AppConfig,
  AuthStore,
  ConfigurationStore,
  DataProvider,
  DeviceHelper,
  IAppState,
  IContentDecryptionModuleModel,
  IUserDeviceModel,
  MediaStore,
  PlatformHelper,
  PlatformType,
  ROUTES,
  ThemeContext,
  ThemeProvider,
  TimeHelper,
  useDispatch,
  useSelector,
} from "@bms/common";
import Color from "color";
import { ConnectedRouter } from "connected-react-router";
import cssVars from "css-vars-ponyfill";
import { History } from "history";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactReduxContext } from "react-redux";

import {
  AudioPlayer,
  AudioPlayerController,
  CookieConsentBar,
} from "components";
import { AudioProvider } from "context/audio/AudioProvider";

import { AppRouter } from "../AppRouter";
import { CdmHelper } from "../helpers";

import App from "./App";
import { MaintenanceScreen } from "./MaintenanceScreen";
import { Splash } from "./Splash";

const themeProvider: ThemeProvider = new ThemeProvider();

export interface IRootProps {
  history: History;
}

export const RootScreen = (props: IRootProps) => {
  const { history } = props;

  const dispatch = useDispatch();
  const configuration = useSelector(
    (state: IAppState) => state.configuration.configuration,
  );
  const isConfigurationLoading = useSelector(
    (state: IAppState) => state.configuration.isLoading,
  );
  const { i18n } = useTranslation();
  const [device, setDevice] = useState<IUserDeviceModel>();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const isWebMobileSize =
    device?.PlatformCode === PlatformType.Web && windowWidth < 500;

  const initializeTranslations = () => {
    if (configuration?.Translations) {
      Object.entries(configuration.Translations).forEach(
        ([language, translations]) => {
          i18n.addResourceBundle(language, "translation", translations);
        },
      );

      const currentLanguage = i18n.language.split("-")[0];
      const supportedLanguages = Object.keys(configuration?.Translations);

      // Change language to current browser language if supported, otherwise
      // set default english language
      i18n.changeLanguage(
        supportedLanguages.includes(currentLanguage) ? currentLanguage : "en",
      );
    } else {
      // To always fulfill condition in render
      i18n.addResourceBundle("en", "translation", {});
      i18n.changeLanguage("en");
    }
  };

  const handleSiteResize = () => {
    document.documentElement.style.fontSize = `${themeProvider.getFontSize()}px`;
    setWindowWidth(window.innerWidth);
  };
  const onLanguageChanged = async (lng: string) => {
    await TimeHelper.setLanguage(lng);
  };

  useEffect(() => {
    window.addEventListener("resize", handleSiteResize);
    i18n.on("languageChanged", onLanguageChanged);

    DataProvider.initSession();

    dispatch(AuthStore.Actions.syncUser());
    dispatch(
      ConfigurationStore.Actions.getConfiguration(PlatformHelper.Platform),
    );

    DeviceHelper.getDeviceInfo().then((device) => setDevice(device));

    CdmHelper.detectSupportedCDMs()
      .then((data: IContentDecryptionModuleModel) => {
        if (__DEV__) {
          console.log("Supported CDMs");
          console.log(data);
        }
        dispatch(MediaStore.Actions.setCdm(data));
      })
      .catch((err) => {
        if (__DEV__) {
          console.error("Detecting Supported CDMs error");
          console.error(err);
        }
      });

    return () => {
      window.removeEventListener("resize", handleSiteResize);
      i18n.off("languageChanged", onLanguageChanged);
    };
  }, []);

  useEffect(() => {
    if (!configuration) {
      return;
    }

    themeProvider.setBrandings(configuration, isWebMobileSize);
    document.documentElement.style.fontSize = `${themeProvider.getFontSize()}px`;

    initializeTranslations();

    const branding = themeProvider.getBranding();

    const cssVariables = {
      // design system (available in studio)
      "--bg-color": themeProvider.getColor("AppBackgroundColor"),
      "--primary-color": themeProvider.getColor("AppPrimaryColor"),
      "--secondary-color": themeProvider.getColor("AppSecondaryColor"),
      "--primary-text-color": themeProvider.getColor("AppPrimaryTextColor"),
      "--secondary-text-color": themeProvider.getColor("AppSecondaryTextColor"),
      "--header-bg-color": themeProvider.getColor("HeaderBackgroundColor"),
      "--header-link-color": themeProvider.getColor("HeaderLinkColor"),
      "--header-link-hover-color": themeProvider.getColor(
        "HeaderLinkHoverColor",
      ),
      "--footer-bg-color": themeProvider.getColor("FooterBackgroundColor"),
      "--footer-link-color": themeProvider.getColor("FooterLinkColor"),
      "--footer-link-hover-color": themeProvider.getColor(
        "FooterLinkHoverColor",
      ),
      "--focus-bg-color": themeProvider.getColor(
        "AppFocusBackgroundColor",
        "#FFFFFF",
      ),
      "--focus-text-color": themeProvider.getColor(
        "AppFocusTextColor",
        "#000000",
      ),
      "--tertiary-bg-color": themeProvider.getColor(
        "AppTertiaryBackgroundColor",
      ),
      "--tertiary-text-color": themeProvider.getColor("AppTertiaryTextColor"),

      // design system (not available in studio)
      "--focus-color": Color(themeProvider.getColor("AppPrimaryColor"))
        .darken(0.3)
        .hex(),
      "--bg-secondary-color": Color(
        themeProvider.getColor("AppBackgroundColor"),
      )
        .negate()
        .darken(0.921)
        .hex(),
      "--bg-tertiary-color": Color(themeProvider.getColor("AppBackgroundColor"))
        .negate()
        .darken(0.878)
        .hex(),

      // custom (available in studio - should be removed?)
      "--cell-background-color": themeProvider.getColor(
        "AppCellsBackgroundColor",
      ),

      // custom (not available in studio - should be removed?)
      "--primary-color-d-50": Color(themeProvider.getColor("AppPrimaryColor"))
        .darken(0.5)
        .hex(),
      "--primary-text-color-d-30": Color(
        themeProvider.getColor("AppPrimaryTextColor"),
      )
        .darken(0.3)
        .hex(),
      "--bg-color-o-30": Color(themeProvider.getColor("AppBackgroundColor"))
        .fade(0.3)
        .hex(),

      // others
      "--font-family": `${themeProvider.getFontFamily()}, Helvetica, Arial, sans-serif`,
      "--app-padding-top": `${branding.AppPaddingTop ?? 70}px`,
      "--app-padding-left": `${branding.AppPaddingLeft ?? 55}px`,
      "--app-padding-right": `${branding.AppPaddingRight ?? 63}px`,
      "--app-padding-bottom": `${branding.AppPaddingBottom ?? 64}px`,
    };

    cssVars({
      rootElement: document,
      variables: cssVariables,
    });

    // App favicon
    const faviconUrl =
      themeProvider.WebFaviconUrl() || `${AppConfig.PublicUrl}/favicon.ico`;

    let favicon = document.getElementById("favicon") as HTMLLinkElement;

    if (!favicon) {
      favicon = document.createElement("link");
      favicon.id = "favicon";
      favicon.rel = "shortcut icon";
      favicon.type = "image/x-icon";
    }

    favicon.href = faviconUrl;
    document.head.appendChild(favicon);
    // App title
    document.title = branding.AppTitle || AppConfig.AppName;
    // App description
    if (branding.AppDescription) {
      let metaDescription = document.querySelector(
        'meta[name="description"]',
      ) as HTMLMetaElement;

      if (!metaDescription) {
        metaDescription = document.createElement("meta");
        metaDescription.id = "description";
        metaDescription.name = "description";
      }

      metaDescription.content = branding.AppDescription;

      document.head.appendChild(metaDescription);
    }
  }, [configuration, isWebMobileSize]);

  useEffect(() => {
    if (!i18n.language || !configuration) return;

    const language = configuration.Languages?.find(
      (lang) => lang.Code === i18n.language,
    );
    const rtl = language && language.RTL;

    const documentHtml = document.getElementsByTagName("html")[0];

    if (rtl) {
      documentHtml.classList.add("rtl");
      documentHtml.dir = "rtl";
    } else {
      documentHtml.classList.remove("rtl");
      documentHtml.dir = "ltr";
    }
  }, [i18n.language, configuration]);

  if (
    !configuration ||
    isConfigurationLoading ||
    !i18n.hasResourceBundle("en", "translation")
  ) {
    if (window.location.pathname === ROUTES.MAINTENANCE_SCREEN) {
      return <MaintenanceScreen />;
    }

    return <Splash />;
  }

  return (
    <ThemeContext.Provider value={{ themeProvider: themeProvider }}>
      <AudioProvider>
        <App>
          <ConnectedRouter
            history={history}
            context={ReactReduxContext}
            noInitialPop
          >
            <AppRouter />
            {configuration && <CookieConsentBar />}
            <AudioPlayer />
            <AudioPlayerController mode="standalone" />
          </ConnectedRouter>
        </App>
      </AudioProvider>
    </ThemeContext.Provider>
  );
};

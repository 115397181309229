/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import TagManager from "@sooro-io/react-gtm-module";

const gtmId = process.env.REACT_APP_GTM_ID;

export const InitializeTagManager = () => {
  if (gtmId) {
    TagManager.initialize({
      gtmId,
    });
  }
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export const returnToMainThread = () => {
  return new Promise((resolve) => {
    setTimeout(resolve, 0);
  });
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Analytics, AnalyticsCallOptions } from "firebase/analytics";
import React, { useContext } from "react";

export interface LogEventParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  method?: string | undefined;
}

interface IAnalyticsContext {
  firebaseAnalytics?: Analytics;
  isCookiesAccepted: boolean;
  enableAnalytics?: () => void;
  trackEvent: (category: string, action: string, label: string) => void;
  trackPageView: () => void;
  logEvent: (
    eventName: string,
    eventParams?: LogEventParams | undefined,
    options?: AnalyticsCallOptions | undefined,
  ) => void;
}

export const AnalyticsContext = React.createContext<IAnalyticsContext>(
  {} as IAnalyticsContext,
);

export const useAnalytics = (): IAnalyticsContext => {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error("Component beyond AnalyticsContext");
  }

  return context;
};

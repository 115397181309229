/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum AssetImageType {
  Cover = "COVER",
  Frame = "FRAME",
  Highlights = "HIGHLIGHTS",
  Square = "SQUARE",
  Background = "BACKGROUND",
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

type ScreenOrientation = "portrait" | "landscape";

type ScreenOrientationContextValue = {
  orientation: ScreenOrientation;
};

type ScreenOrientationProviderProps = {
  children: ReactNode;
};

const ScreenOrientationContext = createContext<ScreenOrientationContextValue>(
  {} as ScreenOrientationContextValue,
);

// Cross-platform method of checking device orientation. On desktop browser it
// will change to portrait mode when window width < height, on mobile it will
// respect device orientation. In future browser orientation API can be
// used, but for now Safari does not support it yet
const mediaQuery = window.matchMedia("screen and (orientation: portrait)");

export const ScreenOrientationProvider = (
  props: ScreenOrientationProviderProps,
) => {
  const { children } = props;

  const [orientation, setOrientation] = useState<ScreenOrientation>(
    mediaQuery.matches ? "portrait" : "landscape",
  );

  useEffect(() => {
    const callback = (event: MediaQueryListEvent) => {
      setOrientation(event.matches ? "portrait" : "landscape");
    };

    mediaQuery.addEventListener("change", callback);

    return () => {
      mediaQuery.removeEventListener("change", callback);
    };
  }, []);

  return (
    <ScreenOrientationContext.Provider value={{ orientation }}>
      {children}
    </ScreenOrientationContext.Provider>
  );
};

export const useScreenOrientation = (): ScreenOrientationContextValue => {
  const context = useContext(ScreenOrientationContext);

  if (!context) {
    throw new Error("Component beyond ScreenOrientationContext");
  }

  return context;
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ActionType,
  ConfigurationHelper,
  dispatch,
  IApplicationFooterItemComponentModel,
  IApplicationFooterScreenModel,
  ItemsAlignmentType,
  ScreenType,
  ThemeContext,
  useScreenConfiguration,
} from "@bms/common";
import cx from "classnames";
import Color from "color";
import { push } from "connected-react-router";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/DesignSystem/Button";
import { LanguageSelect } from "components/LanguageSelect";

import "./AppFooter.scss";

type FooterTable = {
  [columnIndex: number]: IApplicationFooterItemComponentModel[];
};

const splitIntoColumns = (screen: IApplicationFooterScreenModel) => {
  const { Columns = 4, Components = [] } = screen;

  const itemsTable: FooterTable = {};

  Components.forEach((item, index) => {
    const row = Math.floor(index / Columns);
    if (!itemsTable[row]) {
      itemsTable[row] = [];
    }
    itemsTable[row].push(item);
  });

  return itemsTable;
};

const RenderColumn = (itemModel: IApplicationFooterItemComponentModel) => {
  const { t } = useTranslation();
  const { IconUrl, TitleTranslationKey = "", Title = "" } = itemModel;
  if (!(IconUrl || TitleTranslationKey || Title)) return;

  const title = (TitleTranslationKey || Title) && t(TitleTranslationKey, Title);

  const createLinkButton = (onClick?: () => void) => {
    return (
      <Button
        variant="link"
        tooltip={IconUrl ? title : ""}
        tooltipOffset={-45}
        onClick={onClick}
      >
        {IconUrl && <img src={IconUrl} className="Icon" alt={title || ""} />}
        {!IconUrl && title}
      </Button>
    );
  };

  let itemView = null;

  switch (itemModel.Action?.ActionType) {
    case ActionType.OpenScreen:
      const screenKey =
        ConfigurationHelper.getApplicationMenuItemScreenKey(itemModel);
      itemView = createLinkButton(() => {
        dispatch(push(`/${screenKey}`));
      });
      break;
    case ActionType.OpenUrl:
      itemView = createLinkButton(() => {
        window.open(itemModel.Action?.Url, "_blank", "noreferrer");
      });
      break;
    case ActionType.CallTo:
      itemView = createLinkButton(() => {
        window.location.href = `tel:${itemModel.Action?.PhoneNo}`;
      });
      break;
    case ActionType.EmailTo:
      itemView = createLinkButton(() => {
        window.location.href = `mailto:${itemModel.Action?.Email}`;
      });
      break;
    case ActionType.LanguageSelect:
      itemView = <LanguageSelect />;
      break;
    default:
      itemView = createLinkButton(() => {
        console.log("LINK WITHOUT ACTION!");
      });
      break;
  }

  return (
    <div key={itemModel.Id} className="Column">
      {itemView}
    </div>
  );
};

const RenderRow = (
  rowIndex: string,
  items: IApplicationFooterItemComponentModel[],
  itemsAlignment: ItemsAlignmentType,
) => {
  const renderedColumns = items
    .filter((item) => item.IsVisible)
    .map(RenderColumn);

  const className = cx("Row", {
    Row__Justify: itemsAlignment === ItemsAlignmentType.JustifyCenter,
    Row__FitToContent: itemsAlignment === ItemsAlignmentType.FitToContent,
  });

  return (
    <div className={className} key={rowIndex}>
      {renderedColumns}
    </div>
  );
};

const renderFooterColumns = (screen: IApplicationFooterScreenModel) => {
  const { ItemsAlignment = ItemsAlignmentType.FitToContent } = screen;
  const footerTable = splitIntoColumns(screen);
  const rows = [];

  for (const rowIndex in footerTable) {
    const row = RenderRow(rowIndex, footerTable[rowIndex], ItemsAlignment);
    rows.push(row);
  }
  return rows;
};

export interface IAppFooterProps {
  hideLine?: boolean;
}

export const AppFooter = ({ hideLine = false }: IAppFooterProps) => {
  const screen = useScreenConfiguration<IApplicationFooterScreenModel>(
    ScreenType.ApplicationFooter,
  );
  const footerColumns = renderFooterColumns(screen);
  const { themeProvider } = useContext(ThemeContext);
  const { t } = useTranslation();

  const linearGradientColor = Color(
    themeProvider.getColor("FooterBackgroundColor"),
  );

  const footerStyle: React.CSSProperties = {};

  if (screen.BackgroundUrl) {
    footerStyle.backgroundImage = `url(${screen.BackgroundUrl})`;
  } else {
    footerStyle.background = `linear-gradient(0deg, ${linearGradientColor
      .alpha(1)
      .string()} 0%, ${linearGradientColor
      .alpha(0.85)
      .string()} 50%, ${linearGradientColor.alpha(0).string()} 100%)`;
  }

  return (
    <footer className="AppFooter" style={footerStyle}>
      {!screen.BackgroundUrl && !hideLine && (
        <div className="AppFooter__Line" />
      )}
      <div className="AppFooter__Content">{footerColumns}</div>
      <div className="AppFooter__PoweredBy">
        <span>{t("APP_POWERED_BY", "Powered by")}&nbsp;</span>
        <span>
          <a
            href="https://bettermedia.tv/"
            target="_blank"
            rel="noreferrer noopener"
          >
            BETTER MEDIA SOLUTIONS sp z o.o.
          </a>
        </span>
      </div>
    </footer>
  );
};

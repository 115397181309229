/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum SignInResponseCode {
  SUCCESS = "SUCCESS",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  AUTH_FLOW_ERROR = "AUTH_FLOW_ERROR",
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  SERVICE_CONFIGURATION_ERROR = "SERVICE_CONFIGURATION_ERROR",
  INVALID_IDP_CONNECTION = "INVALID_IDP_CONNECTION",
  APPLICATION_NOT_ACTIVE = "APPLICATION_NOT_ACTIVE",
}

export interface ISignInWithCredentialsResponseModel {
  code: SignInResponseCode;
  message?: string;
  details?: Record<string, unknown>;
  accessToken?: string;
  name?: string;
}

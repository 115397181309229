/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  FieldAccessType,
  MediaType,
  ScreenType,
  SourceType,
} from "../../../enums";
import { StorageHelper, TimeHelper } from "../../../helpers";
import {
  EpgDay,
  IAssetAgeRestrictionModel,
  IAssetContentModel,
  IAssetContentTranscodeRequest,
  IAssetImageModel,
  IAssetInAssetModel,
  IAssetInAssetSearchResponseModel,
  IAssetListModel,
  IAssetModel,
  IAssetPriceListModel,
  IAssetPriceModel,
  IAssetPriceSearchFilterModel,
  IAssetPurchasePeriodTypeModel,
  IAssetSearchFilterModel,
  IAssetsInAssetSearchFilterModel,
  IAuthCodeRequestModel,
  IAuthRequestModel,
  IAuthResponseModel,
  IAuthVerifyLoginModel,
  ICatchupInsertModel,
  ICatchupInsertResponseModel,
  IChangePasswordModel,
  ICoinsPaymentRequestModel,
  ICoinsPaymentResponseModel,
  IConfigurationBrandingModel,
  IConfigurationModel,
  IConfigurationTranslationsModel,
  IConfirmAccountWithPasswordModel,
  ICryptoCoinPriceListModel,
  ICurrencyModel,
  Identifier,
  IDownloadSource,
  IForgotPasswordModel,
  IInsertAssetRequestModel,
  IInsertAssetResponseModel,
  IInviteManyUsersModel,
  ILoginCodeModel,
  IMediaCategoryListModel,
  IMediaListModel,
  IMediaListOptionsModel,
  IMediaModel,
  IMediaOptionsModel,
  IMediaPaymentRequestModel,
  IMediaPaymentResponseModel,
  IMediaPlaybackProgressModel,
  IMediaPlayInfoModel,
  IMediaPlayInfoOptionsModel,
  IMediaPurchaseOfferModel,
  IMediaSearchFilterModel,
  IMediaSearchMediaInMediaFilterModel,
  IMediaSearchStateModel,
  IMediaStatisticsOptionsModel,
  IPaymentListModel,
  IPaymentModel,
  IPaymentOptionsModel,
  IPaymentSearchFilterModel,
  IPaymentStatusModel,
  IPaymentTypeMappingAndOptionsModel,
  IRegisterConfirmEmailModel,
  IRegisterRequestEmailModel,
  IRemoveManyUsersModel,
  IResendConfirmationByUserModel,
  IResetForgotPasswordModel,
  IResetPasswordModel,
  IScreenModel,
  IUserAssetPropertiesModel,
  IUserAssetPurchasesListModel,
  IUserAssetPurchasesSearchFilterModel,
  IUserConsentModel,
  IUserDeleteAccountRequestModel,
  IUserDeviceModel,
  IUserInAssetModel,
  IUserInAssetRoleModel,
  IUserInfoModel,
  IUserModel,
  IUserProductModel,
  IUserPurchasesAggregatedModel,
  IUserRatingCategory,
  IUserRatingModel,
  IUserRequestOptionsModel,
  IUserSettingsModel,
  IUsersInAssetListModel,
  IUsersInAssetSearchFilterModel,
  IUsersListModel,
  IUsersSearchFilterModel,
  IUserWalletModel,
} from "../../../models";
import { IConfirmPaymentRequestModel } from "../../../providers/PaymentProvider/models";
import { HttpFactory, StorageKey, StorageManager } from "../../../services";
import { dispatch, OfflineStore } from "../../../store";
import { ConfigurationService } from "../Common";
import { IDataProvider } from "../IDataProvider";
import { InternalDataProvider } from "../Internal";

import { ModelsMapperHelper } from "./helpers";
import { IJwpConfigurationModel } from "./models";
import {
  ConfigService,
  HttpRequestFulfilledInterceptor,
  HttpRequestRejectedInterceptor,
  HttpResponseFulfilledInterceptor,
  HttpResponseRejectedInterceptor,
  MediaService,
} from "./services";
import { InPlayerService } from "./services/InPlayerService";

export class JWPlayerDataProvider implements IDataProvider {
  configService = new ConfigService();
  mediaService = new MediaService();
  configurationService = new ConfigurationService();

  private _internalDataProvider = new InternalDataProvider();
  private _httpFactory = HttpFactory.getInstance("JWPlayer");

  inplayerService = new InPlayerService();

  private _settings = {
    continueWatchingList: "",
    favoritesList: "",
    recommendationsPlaylist: "",
    searchPlaylist: "",
    player: "",
  };

  _initConfig = (config: IJwpConfigurationModel) => {
    const {
      features: {
        continueWatchingList,
        favoritesList,
        recommendationsPlaylist,
        searchPlaylist,
      },
      player,
    } = config;

    this._settings = {
      continueWatchingList,
      favoritesList,
      recommendationsPlaylist,
      searchPlaylist,
      player,
    };
  };

  initHttpFactory() {
    this._internalDataProvider.initHttpFactory();
    this._httpFactory.baseUrl = `${process.env.REACT_APP_JWP_API_URL}`;
    this._httpFactory.addRequestInterceptor(
      "HttpRequestInterceptor",
      HttpRequestFulfilledInterceptor,
      HttpRequestRejectedInterceptor,
    );
    this._httpFactory.addResponseInterceptor(
      "HttpResponseInterceptor",
      HttpResponseFulfilledInterceptor,
      HttpResponseRejectedInterceptor,
    );
  }

  async initSession() {
    Promise.resolve();
  }

  getDefaultBranding(): IConfigurationBrandingModel | undefined {
    return undefined;
  }

  getDefaultTranslations(): IConfigurationTranslationsModel | undefined {
    return this._internalDataProvider.getDefaultTranslations();
  }

  getResource(_resourceKey: string): any {
    return null;
  }

  getConfiguration = async (): Promise<IConfigurationModel | undefined> => {
    const configuration = await this.configService.get().toPromise();
    this._initConfig(configuration);

    let config = await StorageManager.getValue(StorageKey.configuration);
    const configInfo = await this.configurationService
      .getConfigurationInfo()
      .toPromise();

    if (!config || (config && configInfo.Version !== config.VersionNumber)) {
      config = await this.configurationService
        .getConfiguration(configInfo.Url)
        .toPromise();
    }

    if (config) {
      config.Application = {
        Account: {
          AvatarField: FieldAccessType.Readonly,
          PhoneField: FieldAccessType.Hidden,
          CurrencyField: FieldAccessType.Hidden,
          RegisterSuccessPath: "/login",
        },
      };
    }

    return config;
  };

  async getConfigurationOffline(): Promise<IConfigurationModel | undefined> {
    const configurationFromCache = await StorageManager.getValue(
      StorageKey.configuration,
    );
    if (configurationFromCache) {
      return configurationFromCache;
    }
    return undefined;
  }

  async getScreenConfiguration(
    _screenType: ScreenType,
    _screenId?: number,
  ): Promise<IScreenModel | undefined> {
    return Promise.resolve(undefined);
  }

  async getMedia(options: IMediaOptionsModel): Promise<IMediaModel> {
    const response = await this.mediaService
      .getMedia(options.MediaId)
      .toPromise();

    const asset = response.playlist?.[0];

    const media = ModelsMapperHelper.toMediaModel(asset);

    const similarMediaResponse = await this.mediaService
      .getSimilarMedia(this._settings.recommendationsPlaylist, options.MediaId)
      .toPromise();

    if (similarMediaResponse && similarMediaResponse.playlist) {
      media.SimilarMedia = similarMediaResponse.playlist.map((row) =>
        ModelsMapperHelper.toMediaModel(row),
      );
    }

    if (media.MediaTypeCode === MediaType.Series) {
      const series = await this.mediaService
        .getSeries(options.MediaId)
        .toPromise();

      if (series.season_count > 0) {
        media.Media = [];
        const seriesEpisodes = await this.mediaService
          .getEpisodes(options.MediaId)
          .toPromise();

        for (const season of series.seasons) {
          const mediaSeason = ModelsMapperHelper.toSeasonModel(season);
          mediaSeason.ParentMediaId = media.Id;
          mediaSeason.ParentMediaTitle = media.Title;
          mediaSeason.OrderInParent = season.season_number;
          mediaSeason.Media = [];

          const seasonEpisodes = seriesEpisodes.episodes.filter(
            (episode) => episode.season_number === season.season_number,
          );

          for (const episode of seasonEpisodes) {
            const mediaEpisode = ModelsMapperHelper.toEpisodeModel(episode);

            if (mediaEpisode) {
              mediaEpisode.ParentMediaId = mediaSeason.Id;
              mediaEpisode.ParentMediaTitle = mediaSeason.Title;
              mediaEpisode.ParentOrderInParent = season.season_number;
              mediaEpisode.OrderInParent = episode.episode_number;

              mediaSeason.Media.push(mediaEpisode);
            }
          }

          media.Media.push(mediaSeason);
        }
      }
    }

    if (media.MediaTypeCode === MediaType.Episode) {
      const episodesSeries = await this.mediaService
        .getEpisodeSeries(options.MediaId)
        .toPromise();
      const episodeSeries = episodesSeries[options.MediaId];

      if (episodeSeries.length > 0) {
        const series = episodeSeries[0];

        if (series) {
          media.ParentOrderInParent = series.season_number;
          media.OrderInParent = series.episode_number;
        }
      }
    }

    return Promise.resolve(media);
  }

  async getMediaPlayInfo(
    options: IMediaPlayInfoOptionsModel,
  ): Promise<IMediaPlayInfoModel> {
    const data = await this.mediaService.getMedia(options.MediaId).toPromise();
    const watchHistoryPercent =
      await this.inplayerService.getWatchHistoryForItem(options.MediaId);
    const mediaPlayInfo = ModelsMapperHelper.toMediaPlayInfoModel(
      data,
      watchHistoryPercent,
    );

    return Promise.resolve(mediaPlayInfo);
  }

  async searchMedia(
    filter: IMediaSearchFilterModel,
  ): Promise<IMediaSearchStateModel> {
    const response = await this.mediaService
      .searchMedia(this._settings.searchPlaylist, filter.FullTextSearch)
      .toPromise();
    const result = ModelsMapperHelper.toMediaListModel(response);
    return Promise.resolve(result);
  }

  getMediaList = async (
    options: IMediaListOptionsModel,
  ): Promise<IMediaListModel> => {
    const emptyList: IMediaListModel = {
      Entities: [],
      TotalCount: 0,
      SourceType: SourceType.Standard,
      CacheDataValidTo: TimeHelper.getDateWithOffset(
        TimeHelper.getCurrentDateTime(),
        60,
        "seconds",
      ).toString(),
    };

    switch (options.Type) {
      case SourceType.Favorites:
      case SourceType.RecentlyWatched:
        const userAccount = await this.inplayerService.getInplayerUserAccount();
        if (!userAccount) {
          return Promise.resolve(emptyList);
        }

        let mediaIds: string[] = [];

        if (options.Type === SourceType.Favorites) {
          mediaIds = await this.inplayerService.getFavoritesIds();
        } else {
          mediaIds = await this.inplayerService.getWatchHistoryIds();
        }

        if (!mediaIds || mediaIds.length === 0) {
          return Promise.resolve(emptyList);
        }

        const watchlistResponse = await this.mediaService
          .getWatchlist(options.MediaListId, mediaIds)
          .toPromise();
        const watchlist =
          ModelsMapperHelper.toMediaListModel(watchlistResponse);

        for (const media of watchlist.Entities) {
          media.IsPlayable = true;
        }
        return Promise.resolve(watchlist);
      default:
        const playlistResponse = await this.mediaService
          .getPlaylist(options.MediaListId)
          .toPromise();
        const playlist = ModelsMapperHelper.toMediaListModel(playlistResponse);
        return Promise.resolve(playlist);
    }
  };

  async health(): Promise<any> {
    return Promise.resolve(true);
  }

  async getMediaCategories(): Promise<IMediaCategoryListModel> {
    // JWP
    return Promise.resolve({ Entities: [], TotalCount: 0 });
  }

  async getMediaStatistics(
    _options: IMediaStatisticsOptionsModel,
  ): Promise<any> {
    return Promise.reject("Not implemented");
  }

  async getEpgDays(): Promise<EpgDay[]> {
    return [];
  }

  async selectMediaPurchaseOffers(
    _: Identifier,
  ): Promise<IMediaPurchaseOfferModel[]> {
    return Promise.resolve([]);
  }

  async setWatchProgress(_: Identifier, __: number): Promise<void> {
    return Promise.resolve();
  }

  async getUserAssetsProperties(): Promise<IUserAssetPropertiesModel[]> {
    return this.inplayerService.getUserAssetsProperties();
  }

  async isOnMyList(mediaId: Identifier): Promise<boolean> {
    return StorageHelper.isOnMyList(mediaId);
  }

  async addToMyList(mediaId: Identifier): Promise<void> {
    return this.inplayerService.addToMyList(mediaId);
  }

  async removeFromMyList(mediaId: Identifier): Promise<void> {
    return this.inplayerService.removeFromMyList(mediaId);
  }

  async getMyListIds(): Promise<Identifier[]> {
    return this.inplayerService.getFavoritesIds();
  }

  async buyMedia(
    _: IMediaPaymentRequestModel,
  ): Promise<IMediaPaymentResponseModel> {
    return Promise.reject("Not implemented");
  }

  async getProducts(): Promise<IUserProductModel[]> {
    return Promise.resolve([{ Id: -1 }]);
  }

  async searchMediaInMedia(
    _: IMediaSearchMediaInMediaFilterModel,
  ): Promise<IMediaListModel> {
    return Promise.reject("Not implemented");
  }

  async syncUser(): Promise<IUserInfoModel | null> {
    let user = await StorageHelper.getUser();

    if (user) {
      user.Products = [{ Id: -1 }];
    } else {
      user = {
        Id: -999,
        UserName: "Anonymous",
        FullName: "Anonymous user",
        ClientRoles: ["MEDIA_VIEW"],
      };
    }

    return user;
  }

  async signIn(data: IAuthRequestModel): Promise<IAuthResponseModel> {
    return this.inplayerService.signIn(data);
  }

  async signOut(_: IUserDeviceModel): Promise<void> {
    return this.inplayerService.signOut();
  }

  async forgotPassword(data: IForgotPasswordModel): Promise<boolean> {
    return this._internalDataProvider.forgotPassword(data);
  }

  async resetForgotPassword(data: IResetForgotPasswordModel): Promise<boolean> {
    return this._internalDataProvider.resetForgotPassword(data);
  }

  async registerEmail(data: IRegisterRequestEmailModel): Promise<boolean> {
    return this.inplayerService.registerEmail(data);
  }

  async registerConfirmEmail(
    data: IRegisterConfirmEmailModel,
  ): Promise<IAuthResponseModel> {
    return this._internalDataProvider.registerConfirmEmail(data);
  }

  async registerConfirmAccount(
    data: IConfirmAccountWithPasswordModel,
  ): Promise<IAuthResponseModel> {
    return this._internalDataProvider.registerConfirmAccount(data);
  }

  async resendConfirmationEmailByUser(
    data: IResendConfirmationByUserModel,
  ): Promise<void> {
    return this._internalDataProvider.resendConfirmationEmailByUser(data);
  }

  async refreshToken(
    _: string,
    __: IUserDeviceModel,
  ): Promise<IAuthResponseModel> {
    return this.inplayerService.refreshToken();
  }

  async changePassword(
    data: IChangePasswordModel,
  ): Promise<IAuthResponseModel> {
    return this.inplayerService.changePassword(data);
  }

  async resetPassword(data: IResetPasswordModel): Promise<boolean> {
    return this._internalDataProvider.resetPassword(data);
  }

  async validateConfirmationToken(token: string): Promise<void> {
    return this._internalDataProvider.validateConfirmationToken(token);
  }
  // LOGIN CODE
  async linkLoginCode(data: ILoginCodeModel): Promise<ILoginCodeModel> {
    return this._internalDataProvider.linkLoginCode(data);
  }
  async getLoginCode(): Promise<IAuthCodeRequestModel> {
    return this._internalDataProvider.getLoginCode();
  }

  async verifyLogin(data: IAuthVerifyLoginModel): Promise<IAuthResponseModel> {
    return this._internalDataProvider.verifyLogin(data);
  }

  // USER
  async getProfile(_: IUserRequestOptionsModel): Promise<IUserModel> {
    return this.inplayerService.getProfile();
  }

  async updateProfile(data: IUserModel): Promise<IUserModel> {
    return this.inplayerService.updateProfile(data);
  }

  async deleteAccount(data: IUserDeleteAccountRequestModel): Promise<void> {
    return this.inplayerService.deleteAccount(data);
  }

  async browseUsers(_: IUsersSearchFilterModel): Promise<IUsersListModel> {
    return Promise.reject("Not implemented");
  }

  async getUserWallet(): Promise<IUserWalletModel[]> {
    return Promise.resolve([]);
  }

  async getUserSettings(): Promise<IUserSettingsModel> {
    return this.inplayerService.getUserSettings();
  }

  async updateUserSettings(
    data: IUserSettingsModel,
  ): Promise<IUserSettingsModel> {
    return this.inplayerService.updateUserSettings(data);
  }

  // USER IN ASSET
  async getUserInAssetRoles(): Promise<IUserInAssetRoleModel[]> {
    return Promise.reject("Not implemented");
  }

  async inviteManyUsers(_: IInviteManyUsersModel): Promise<void> {
    return Promise.reject("Not implemented");
  }

  async searchUsersInAsset(
    _: IUsersInAssetSearchFilterModel,
  ): Promise<IUsersInAssetListModel> {
    return Promise.reject("Not implemented");
  }

  async removeUserFromAsset(
    _: IUserInAssetModel & { AssetId: number },
  ): Promise<void> {
    return Promise.reject("Not implemented");
  }

  async removeManyUsersFromAsset(_: IRemoveManyUsersModel): Promise<void> {
    return Promise.reject("Not implemented");
  }

  // CONSENTS
  async selectUserConsents(): Promise<IUserConsentModel[]> {
    return this.inplayerService.selectUserConsents();
  }

  async getUserConsent(url: string): Promise<string> {
    return this._internalDataProvider.getUserConsent(url);
  }

  async updateUserConsent(data: IUserConsentModel): Promise<IUserConsentModel> {
    return this.inplayerService.updateUserConsent(data);
  }

  async updateUserConsents(
    data: IUserConsentModel[],
  ): Promise<IUserConsentModel[]> {
    return this._internalDataProvider.updateUserConsents(data);
  }

  // PAYMENT
  async getPayment(id: number): Promise<IPaymentModel> {
    return this._internalDataProvider.getPayment(id);
  }

  async searchPayment(
    filter: IPaymentSearchFilterModel,
  ): Promise<IPaymentListModel> {
    return this._internalDataProvider.searchPayment(filter);
  }

  async getPaymentOptions(): Promise<IPaymentOptionsModel> {
    // TODO: WITH PAYMENTS METHODS READY
    // https://jojomobile.atlassian.net/browse/MRLPD-1772
    //try {
    //  const paymentMethods =
    //    await this._inPlayerDataProvider.Payment.getPaymentMethods();
    //  console.log("paymentMethods getPaymentOptions", paymentMethods);
    //  return paymentMethods.data;
    //} catch (err: unknown) {
    //  const error = err as AxiosError;
    //  return Promise.reject({
    //    Message: error.response?.data?.message || "",
    //  });
    //}
    return this._internalDataProvider.getPaymentOptions();
  }

  async getPaymentTypeMappingAndOptions(): Promise<IPaymentTypeMappingAndOptionsModel> {
    // TODO: WITH PAYMENTS METHODS READY
    // https://jojomobile.atlassian.net/browse/MRLPD-1772
    //try {
    //  const paymentMethods =
    //    await this._inPlayerDataProvider.Payment.getPaymentMethods();
    //  console.log(
    //    "paymentMethods getPaymentTypeMappingAndOptions",
    //    paymentMethods,
    //  );
    //  return paymentMethods.data;
    //} catch (err: unknown) {
    //  const error = err as AxiosError;
    //  return Promise.reject({
    //    Message: error.response?.data?.message || "",
    //  });
    //}
    return this._internalDataProvider.getPaymentTypeMappingAndOptions();
  }

  async getByKey(key: string): Promise<IPaymentModel> {
    return this._internalDataProvider.getByKey(key);
  }

  async checkStatusByKey(key: string): Promise<IPaymentStatusModel> {
    return this._internalDataProvider.checkStatusByKey(key);
  }

  async confirmPayment(
    _: IConfirmPaymentRequestModel,
  ): Promise<IPaymentStatusModel> {
    return Promise.reject("Not implemented");
  }

  // SUBSCRIPTIONS
  async cancelSubscription(
    userSubscriptionId: number,
  ): Promise<IUserAssetPurchasesListModel> {
    return this._internalDataProvider.cancelSubscription(userSubscriptionId);
  }

  async reactivateSubscription(
    userSubscriptionId: number,
  ): Promise<IUserAssetPurchasesListModel> {
    return this._internalDataProvider.reactivateSubscription(
      userSubscriptionId,
    );
  }

  async changeSubscriptionPaymentMethod(
    userSubscriptionId: number,
  ): Promise<IMediaPaymentResponseModel> {
    return this._internalDataProvider.changeSubscriptionPaymentMethod(
      userSubscriptionId,
    );
  }

  async searchUserAssetPurchases(
    filter: IUserAssetPurchasesSearchFilterModel,
  ): Promise<IUserAssetPurchasesListModel> {
    return this._internalDataProvider.searchUserAssetPurchases(filter);
  }

  //ASSETS
  async searchAsset(_: IAssetSearchFilterModel): Promise<IAssetListModel> {
    return Promise.reject("Not implemented");
  }

  async getUserPurchasesAggregated(): Promise<IUserPurchasesAggregatedModel> {
    return this._internalDataProvider.getUserPurchasesAggregated();
  }

  async createAsset(
    _: IInsertAssetRequestModel,
  ): Promise<IInsertAssetResponseModel> {
    return Promise.reject("Not implemented");
  }

  async getAsset(_: number): Promise<IAssetModel> {
    return Promise.reject("Not implemented");
  }

  async updateAsset(_: IAssetModel): Promise<any> {
    return Promise.reject("Not implemented");
  }

  async insertAssetImage(_: IAssetImageModel): Promise<IAssetModel> {
    return Promise.reject("Not implemented");
  }

  async updateAssetImage(_: IAssetImageModel): Promise<IAssetModel> {
    return Promise.reject("Not implemented");
  }

  async addAssetContent(_: IAssetContentModel): Promise<IAssetContentModel> {
    return Promise.reject("Not implemented");
  }

  async updateAssetContent(_: IAssetContentModel): Promise<IAssetContentModel> {
    return Promise.reject("Not implemented");
  }

  async selectAgeRestriction(): Promise<IAssetAgeRestrictionModel[]> {
    return Promise.reject("Not implemented");
  }

  async selectCurrency(): Promise<ICurrencyModel[]> {
    return Promise.resolve([]);
  }

  async selectPurchasePeriodType(): Promise<IAssetPurchasePeriodTypeModel[]> {
    return Promise.reject("Not implemented");
  }

  async saveAssetInAssetCollection(
    _: IAssetInAssetModel[],
  ): Promise<IAssetInAssetModel[]> {
    return Promise.reject("Not implemented");
  }

  async saveAssetPriceCollection(
    _: IAssetPriceModel[],
  ): Promise<IAssetPriceModel[]> {
    return Promise.reject("Not implemented");
  }

  async getAssetPriceCollection(_: number): Promise<IAssetPriceModel> {
    return Promise.reject("Not implemented");
  }

  async searchAssetPriceCollection(
    _: IAssetPriceSearchFilterModel,
  ): Promise<IAssetPriceListModel> {
    return Promise.reject("Not implemented");
  }

  async insertCatchup(
    _: ICatchupInsertModel,
  ): Promise<ICatchupInsertResponseModel> {
    return Promise.reject("Not implemented");
  }

  async searchAssetsInAssets(
    _: IAssetsInAssetSearchFilterModel,
  ): Promise<IAssetInAssetSearchResponseModel> {
    return Promise.reject("Not implemented");
  }

  async setProgress(data: IMediaPlaybackProgressModel): Promise<void> {
    const mediaData = await this.mediaService
      .getMedia(data.MediaId)
      .toPromise();
    const asset = mediaData.playlist[0];

    if (!asset.duration) {
      return Promise.resolve();
    }

    const progressInPercent = data.ProgressInSeconds / asset.duration;

    if (!progressInPercent) {
      return Promise.resolve();
    }

    await this.inplayerService.setProgress({
      mediaId: data.MediaId,
      progressInPercent,
    });

    const newAssetProp = <IUserAssetPropertiesModel>{
      AssetId: data.MediaId,
      Timestamp: {
        Hour: Math.floor(data.ProgressInSeconds / 3600),
        Minute: Math.floor((data.ProgressInSeconds % 3600) / 60),
        Second: Math.floor(data.ProgressInSeconds % 60),
      },
    };
    const updatedPodcast: IDownloadSource = {
      media: {
        Id: data.MediaId,
      },
      mediaPlayInfo: {
        Timestamp: {
          Hour: Math.floor(data.ProgressInSeconds / 3600),
          Minute: Math.floor((data.ProgressInSeconds % 3600) / 60),
          Second: Math.floor(data.ProgressInSeconds % 60),
        },
      },
    };

    StorageHelper.updateUserAssetsProperties(newAssetProp);
    if (data.MediaType === MediaType.Podcast) {
      dispatch(OfflineStore.Actions.updatePodcast(updatedPodcast));
    }
  }

  async buyCryptoCoins(
    _: ICoinsPaymentRequestModel,
  ): Promise<ICoinsPaymentResponseModel> {
    return Promise.reject("Not implemented");
  }

  async getCryptoCoinPriceList(): Promise<ICryptoCoinPriceListModel[]> {
    return Promise.reject("Not implemented");
  }

  async selectRatingCategories(): Promise<IUserRatingCategory[]> {
    return Promise.resolve([]);
  }

  async insertUserRating(_: IUserRatingModel): Promise<void> {
    return Promise.reject("Not implemented");
  }

  async requestAssetContentTranscode(
    _: IAssetContentTranscodeRequest,
  ): Promise<IAssetContentModel> {
    return Promise.reject("Not implemented");
  }
}

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  AxiosSubscriber as AxiosCommonSubscriber,
  HttpFactory,
} from "../../../../services";

export class AxiosSubscriber extends AxiosCommonSubscriber {
  get httpFactory(): HttpFactory {
    return HttpFactory.getInstance("JWPlayer");
  }
}

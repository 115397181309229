/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ActionType,
  ANONYMOUS_ID,
  RouteHelper as CommonRouteHelper,
  IAppState,
  ISettingsMenuItemComponentModel,
  IUserInfoModel,
  ThemeContext,
  useConfigurationSelector,
  useIsLoggedIn,
  useSelector,
  useSettingsMenuConfiguration,
} from "@bms/common";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { LogOutModal } from "components";
import { Button } from "components/Button";
import { RouteHelper } from "helpers";
import avatarPlaceholder from "resources/avatar-placeholder.png";
import ChevronDown from "resources/icons/chevron-down.svg";
import { ReactComponent as WalletIcon } from "resources/money.svg";

import "./HeaderUserInfo.scss";

interface IHeaderUserInfoProps {
  user?: IUserInfoModel;
  isMobile?: boolean;
}

export const HeaderUserInfo = ({
  user,
  isMobile = false,
}: IHeaderUserInfoProps) => {
  const { t } = useTranslation();
  const settingsMenuScreen = useSettingsMenuConfiguration();
  const isLoggedIn = useIsLoggedIn();
  const [showList, setShowList] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const wallet = useSelector((state: IAppState) => state.user.wallet);
  const { themeProvider } = useContext(ThemeContext);
  const fontColor = themeProvider.getColor("AppPrimaryColor");

  const userWallet = wallet
    ? wallet.Data?.find((el) => el.CurrencyCode === "CC")?.Amount
    : 0;

  const configuration = useConfigurationSelector();

  const showBetterCoinWallet = configuration?.System?.CryptoCoin?.Enabled;

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        if (event.target instanceof SVGElement) {
          if (event.target.className.baseVal.includes("Button")) {
            return;
          }
        } else if (event.target instanceof Element) {
          if (event.target.className?.includes("Button")) {
            return;
          }
        }
        setShowList(false);
      }
    },
    [wrapperRef],
  );

  const handleToggleList = () => {
    setShowList((state) => !state);
  };

  const onMenuItemclick = async (
    component: ISettingsMenuItemComponentModel,
  ) => {
    await RouteHelper.performAction(component.Action);
    setShowList(false);
  };

  const renderProfileItems = useMemo(() => {
    return (
      <>
        {settingsMenuScreen?.Components?.filter(
          (component: ISettingsMenuItemComponentModel) =>
            component.IsVisible && component.Action,
        ).map((component: ISettingsMenuItemComponentModel) => {
          let title = t(
            `${component.TitleTranslationKey}`,
            component.Title ?? "",
          );

          if (component.Action?.ActionType === ActionType.SignInOut) {
            title = t(isLoggedIn ? "COMMON__LOGOUT" : "COMMON__SIGN_IN");
          }

          return (
            <Button
              type="button"
              buttonClassName="HeaderUserInfo__ListItem"
              key={component.Id}
              onClick={() => onMenuItemclick(component)}
            >
              {title}
            </Button>
          );
        })}
        <LogOutModal>
          {({ openModal }) => (
            <Button
              type="button"
              buttonClassName="HeaderUserInfo__ListItem"
              key="logout"
              onClick={openModal}
            >
              {t(isLoggedIn ? "COMMON__LOGOUT" : "COMMON__SIGN_IN")}
            </Button>
          )}
        </LogOutModal>
      </>
    );
  }, [settingsMenuScreen]);

  const getInitials = (fullName: string | undefined) => {
    if (!fullName) {
      return "";
    }
    const allNames = fullName.trim().split(" ");
    const initials = allNames.reduce((prevValue, currValue, index) => {
      if (index === 0 || index === allNames.length - 1) {
        prevValue = `${prevValue}${currValue.charAt(0).toUpperCase()}`;
      }
      return prevValue;
    }, "");
    return initials;
  };

  if (user?.Id === ANONYMOUS_ID || !user) {
    return (
      <div
        className="HeaderUserInfo"
        onClick={() => CommonRouteHelper.goToLogin()}
      >
        <span className="HeaderUserInfo__SignIn">{t("COMMON__SIGN_IN")}</span>
      </div>
    );
  }

  if (isMobile) {
    return (
      <div
        className="HeaderUserInfo"
        onClick={() => CommonRouteHelper.goToSettings()}
      >
        <div className="HeaderUserInfo__AvatarContainer">
          <img
            className="HeaderUserInfo__Avatar"
            alt="user-avatar"
            src={user?.AvatarUrl || avatarPlaceholder}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="HeaderUserInfo" ref={wrapperRef}>
      {showBetterCoinWallet && (
        <>
          <WalletIcon className="HeaderUserInfo__icon-wallet" />
          <div
            className="HeaderUserInfo__icon-wallet-text"
            style={{ color: fontColor }}
          >
            {userWallet}
          </div>
        </>
      )}

      <div className="HeaderUserInfo__Content" onClick={handleToggleList}>
        <div className="HeaderUserInfo__AvatarContainer">
          <img
            className="HeaderUserInfo__Avatar"
            alt="user-avatar"
            src={user?.AvatarUrl || avatarPlaceholder}
          />
        </div>
        <div className="HeaderUserInfo__Name">
          {getInitials(user?.FullName)}
        </div>
        <div
          className="HeaderUserInfo__Icon"
          style={{ transform: `rotate(${showList ? 180 : 0}deg)` }}
        >
          <ChevronDown />
        </div>
      </div>
      {showList && (
        <div className="HeaderUserInfo__List">{renderProfileItems}</div>
      )}
    </div>
  );
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
const PREFIX = "NOTIFICATION";
const PUSH_NOTIFICATION = `${PREFIX}_PUSH_NOTIFICATION`;
const SET_HUB_ERROR = `${PREFIX}_SET_HUB_ERROR`;
const SET_HUB_CONNECTION_STATE = `${PREFIX}_SET_HUB_CONNECTION_STATE`;

export const ActionTypes = {
  PUSH_NOTIFICATION,
  SET_HUB_ERROR,
  SET_HUB_CONNECTION_STATE,
};

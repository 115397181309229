/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AxiosRequestConfig, AxiosResponse } from "axios";

import { IErrorModel } from "../../../../models";

export const HttpRequestFulfilledInterceptor = (
  config: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
  if (!config.headers) {
    config.headers = {};
  }

  if (!config.headers["Content-Type"]) {
    config.headers["Content-Type"] = "application/json; charset=UTF-8";
  }

  return Promise.resolve(config);
};

export const HttpRequestRejectedInterceptor = (error: IErrorModel): any => {
  return Promise.reject(error);
};

export const HttpResponseFulfilledInterceptor = (
  response: AxiosResponse,
): AxiosResponse => response;

export const HttpResponseRejectedInterceptor = async (
  error: IErrorModel | any,
): Promise<any> => {
  return Promise.reject(error);
};

/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { shallowEqual, useSelector } from "react-redux";

import { Identifier } from "../models";
import { IAppState } from "../store";

export const useMediaByIdSelector = (id: Identifier) => {
  return useSelector(
    (state: IAppState) => state.media.media[id] ?? {},
    shallowEqual,
  );
};

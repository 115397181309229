/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum PaymentStatus {
  Created = "CREATED",
  Completed = "COMPLETED",
  Cancelled = "CANCELLED",
  Failed = "FAILED",
  NotExists = "NOT_EXISTS",
  Processing = "PROCESSING",
  Successed = "SUCCESSED",
}
